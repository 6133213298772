/**
 * Information about a backend API version, with context, null mapping, etc.
 * Allows a client side service to work with several API versions with different requirements and gradually migrate to a new version.
 */
export class DoiApiVersion
{
	/**
	 * The API version, used to suffix API contexts. First versions may use '' indicating no known version.
	 */
	readonly version: any;

	/**
	 * The JAX-RS resource context after/below the API context.
	 */
	readonly context: string;

	/**
	 * Placeholder in paths for a null object ID.
	 * The old servlet based API used '-' for null indicating a new object, while the new JAX-RS based uses '0'.
	 */
	readonly pathPaceholderID: string;

	/**
	 * Construct a new API version information object.
	 * @param version The version suffix. Often an empty string for first version, representing version 1.
	 * @param context The service context. Omit to use the symbolic name.
	 * @param pathPaceholderID Placeholder in paths for a null object ID. Defaults to '-' if the API version is '' but '0' for any other.
	 */
	constructor(version: any, context: string, pathPaceholderID?: string)
	{
		this.version = version;
		this.context = context;
		this.pathPaceholderID = pathPaceholderID || version == '' ? '-' : '0';
	}
}

