import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {DoiService, DoiSelectionCriteria, DoiObject} from '../../doi/DoiModule';

import {KlaraBrokerService} from '../../klara/KlaraModule';

import {AuditLogEntryObject} from './AuditLogEntryObject';

/**
 * Manages AuditLogEntry objects.
 */
@Injectable()
export class AuditLogEntryService extends KlaraBrokerService<AuditLogEntryObject>
{
	/**
	 * Construct a new AuditLogEntry service.
	 */
	constructor(doi: DoiService)
	{
		super(doi, 'AuditLogEntry')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return AuditLogEntryObject.iconName;
	}

	/**
	 * Create a new AuditLogEntry object.
	 */
	newObject(objectID: number): AuditLogEntryObject
	{
		return new AuditLogEntryObject(objectID);
	}

	readAuditLogEntries(object: DoiObject): Observable<AuditLogEntryObject[]>
	{
		let criteria = new DoiSelectionCriteria();

		if (this.doi.auth.hasAnyRole('User', 'Audit', 'Admin', 'System')) {
			criteria = criteria.select(
			'AuditLogEntry_LoggedByUserID',
			'User_UserName',
			'User_Name',
			'AuditLogEntry_LoggedWhen',
			'AuditLogEntry_Message',
			'AuditLogEntry_Operation',
			)
		} else {
			criteria = criteria.select(
			'AuditLogEntry_LoggedWhen',
			'AuditLogEntry_Operation',
			)
		}
		criteria = criteria.where('AuditLogEntry_ObjectType', '='+object.objectType).and('AuditLogEntry_ObjectID', '='+object.objectID);
		return this.readObjectSelection(null, criteria);
    }
}

// XLOXVuhZQ4knYsDUBIuK6/tJn2c=
