import { Component, OnInit, OnDestroy, Output, EventEmitter, Directive } from '@angular/core';
import {Router, Event, NavigationEnd, ActivatedRoute, ParamMap} from '@angular/router';
import {Observable, EMPTY, of} from 'rxjs';

import {DoiService} from '../service/DoiService';
import {DoiBrokerService} from '../service/DoiBrokerService';
import {DoiObject} from '../service/DoiObject';
import {DoiObjectView} from './DoiObjectView';
import {DoiSubView} from './DoiSubView';

@Directive()
export abstract class DoiObjectPanel<T extends DoiObject> extends DoiSubView
{
	name = 'DoiObjectPanel';

	/**
	 * The parent object view.
	 */
	get parentObjectView(): DoiObjectView<T> { return this.parentView as DoiObjectView<T> };

	/**
	 * The active.
	 */
	activePanel: DoiObjectPanel<T>;

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	ngOnInit()
	{
		super.ngOnInit();
	}

	get objectID(): number
	{
		if (this.parentObjectView)
			return this.parentObjectView.objectID;
		return undefined;
	}

	set objectID(objectID: number)
	{
		if (this.parentObjectView)
			this.parentObjectView.objectID = objectID;
	}

	get object(): T
	{
		if (this.parentObjectView)
			return this.parentObjectView.object;
		return undefined;
	}

	set object(object: T)
	{
		if (this.parentObjectView)
			this.parentObjectView.object = object;
	}

	attachParentObjectView(parentView: DoiObjectView<T>): void
	{
		this.attachParentView(parentView);

		this.log('attachParentObjectView', this.object);
	}

	/**
	 * Test if the object is currently being edited.
	 */
	editing()
	{
		if (this.parentObjectView)
			return this.parentObjectView.editing();
		else
			return false;
	}

	/**
	 * Test if the object is currently being edited and the user has any of the specified roles.
	 */
	editingWithRole(...roles: string[])
	{
		if (this.parentObjectView)
			return this.parentObjectView.editingWithRole(...roles);
		else
			return false;
	}

	/**
	 * Test if an existing object is currently being edited.
	 */
	editingExisting()
	{
		if (this.parentObjectView)
			return this.parentObjectView.editingExisting();
		else
			return false;
	}

	/**
	 * Test if a new object is currently being edited.
	 */
	editingNew()
	{
		if (this.parentObjectView)
			return this.parentObjectView.editingNew();
		else
			return false;
	}

	/**
	 * Start editing a new object.
	 */
	editNew()
	{
	}

	/**
	 * Start editing the object. The default implementation does nothing. Override to navigate to an editable panel.
	 */
	editStart()
	{
	}

	/**
	 * Cancel editing the object.
	 */
	editCancel()
	{
	}

	/**
	 * Invoked when the user saves the view, before saving, and when an edited object panel is deactivated. Override to commit any pending edits to the object.
	 */
	editSaveCommit()
	{
	}

	/**
	 * Create and return an observable that when subscribed will refresh the object part or parts that this panel shows.
	 * The default implementation returns an empty observable.
	 */
	refreshView(): Observable<any>
	{
		return EMPTY;
	}

	/**
	 * Probe the specified object part. Delegates to the parent object view.
	 */
	probeObjectPart(partName: string): Observable<T>
	{
		if (this.parentObjectView)
			return this.parentObjectView.probeObjectPart(partName);
		else
			return EMPTY;
	}

	/**
	 * Refresh the specified object part. Delegates to the parent object view.
	 */
	refreshObjectPart(partName: string): Observable<T>
	{
		if (this.parentObjectView)
			return this.parentObjectView.refreshObjectPart(partName);
		else
			return EMPTY;
	}

	service(): DoiBrokerService<T>
	{
		if (this.parentObjectView)
			return this.parentObjectView.service;
		else
			return undefined;
	}

	tabActivate(event: DoiObjectPanel<T>): void
	{
		this.activePanel = event;
		this.activePanel.attachParentObjectView(this.parentObjectView);
	}

	tabDeactivate(event: DoiObjectPanel<T>): void
	{
		this.activePanel = null;
	}
}
