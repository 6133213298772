import {Directive} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {map} from "rxjs/operators";
import {HttpParams} from '@angular/common/http';
import {tap} from "rxjs/operators";

import {environment} from '../environments/environment';

import {DoiAction, DoiAppSettings, DoiBrokerService, DoiLogLevel, DoiObjectView, DoiService, DoiObject} from './doi/DoiModule';
import {DoiSearchService} from './doi-search/DoiSearchModule';
import {DoiThemeService, DoiTheme} from './doi-theme/DoiThemeModule';
import {DoiNavigatorAppView, DoiNavNode} from './doi-navigator/DoiNavigatorModule';

import {AppService} from './AppService';
import {AppInfo} from './klara/KlaraModule';

import {KlaraDialogTopView, KlaraTopView} from './klara/KlaraModule';
import {ProcessService} from './process/ProcessModule';
import {SystemInfoObject, SystemInfoService, SystemInfoPart, PublishLevel} from './systeminfo/SystemInfoModule';
import { ArchiveOrigObject } from './archiveorig/ArchiveOrigModule';

declare let navigationEnd: Function;

export class AppSettings extends DoiAppSettings
{
}

@Directive()
export abstract class AppBaseView extends DoiNavigatorAppView
{
	name = 'AppView';

	themes: DoiThemeService;

	appInfo: AppInfo;

	titleService: Title;
	searchService: DoiSearchService;
	systemInfoService: SystemInfoService;
	systemInfo: SystemInfoObject;
	processService: ProcessService;

	private titleSuffix: string;

	/**
	 * The requested publish level. The user will only see object published with this level or higher. Fetched after login.
	 */
	publishLevel: PublishLevel = PublishLevel.External;

	/**
	 * Indicates if a logout is pending.
	 */
	logoutPending: boolean;

	constructor(
		router: Router,
		doi: DoiService,
		themes: DoiThemeService,
		titleService: Title,
		searchService: DoiSearchService,
		systemInfoService: SystemInfoService,
		processService: ProcessService,
		...brokerServices: DoiBrokerService<any>[]
	)
	{
		super(router, doi);

		if (this.isAppDialog())
			doi.storage.variant= '-dialog';

		this.doi.storage.useLocalStorage(this.localStorageChoice());

		this.themes = themes;
		this.titleService = titleService;
		this.searchService = searchService;
		this.systemInfoService = systemInfoService,
		this.processService = processService;

		this.doi.brokerServices(systemInfoService, processService);
		this.doi.brokerServices(...brokerServices);

		//	Load and apply settings.

		this.settings = this.storageGetItem('settings', this.createSettings());

		this.doi.log.level = this.settings.debugMode ? DoiLogLevel.FINE : DoiLogLevel.INFO;

		if (this.settings.navigatorDocked === undefined)
			this.settings.navigatorDocked = !this.mobileDevice();

		if (!this.settings.navigatorDocked)
			this.settings.navigator = false;

		if (this.settings.navigator === undefined)
			this.settings.navigator = window.innerWidth >= 768 && !this.isAppDialog();

		//	Configure authentication and attempt to reauthenticate. If not logged in, invoke authenticatedStateChanged
		//	anyway, on timeout, to refresh the current view.

		this.doi.auth.configure(this.urlContext(), '/apip', '/apis', ['/login']);
		this.doi.auth.authenticatedState().subscribe(loggedIn => this.authenticatedStateChanged(loggedIn));
		if (!this.doi.auth.reauthenticate())
			setTimeout(() => this.authenticatedStateChanged(false));

		systemInfoService.readAppInfo().subscribe(
			(appInfo: AppInfo) => {
				this.appInfo = appInfo;
				if (this.systemInfo)
					this.infoReceived();
			}
		);

		systemInfoService.readObjectPart(1, SystemInfoPart.General).subscribe(
			(systemInfo: SystemInfoObject) => {
				this.systemInfo = systemInfo;
				if (this.appInfo)
					this.infoReceived();
				this.processService.showProcessCodes = this.showProcessCodes();
				(this.doi as AppService).showProcessCodes = this.processService.showProcessCodes;
				if (systemInfo.cookieConsentEnabled) {
					systemInfoService.readObjectPart(1, SystemInfoPart.CookieConsentText, systemInfo).subscribe();
				} else {
					this.localStorageConsent(true);
				}
			}
		);

		//	Configure and use theme.

		this.themesInit();

		//	Create actions.

		this.actions.add(
			new DoiAction(this, 'SkipToContent', 'fas-forward', 'Hoppa', 'Hoppa till sidans innehåll')
				.mobileAvailable(false)
				.executeHandler(() => this.actionSkipToContent()),
			new DoiAction(this, 'Account', 'fas-user', 'Mitt konto')
				.enabledHandler(() => this.doi.auth.isAuthenticated())
				.executeHandler(() => this.actionAccount()),
			new DoiAction(this, 'ChangePwd', 'key', 'Byt lösenord')
				.executeHandler(() => this.actionChangePwd()),
			new DoiAction(this, 'Login', 'sign-in-alt', 'Logga in')
				.enabledHandler(() => !this.doi.auth.isAuthenticating())
				.executeHandler(() => this.actionLogin())
				.titleHandler(() => this.doi.auth.isAuthenticating() ? 'Loggar in' : 'Logga in'),
			new DoiAction(this, 'Logout', 'sign-out-alt', 'Logga ut')
				.executeHandler(() => this.actionLogout()),
			new DoiAction(this, 'Navigator', 'fas-bars', 'Meny')
				.executeHandler(() => this.actionNavigator())
				.selectedHandler(() => this.settings.navigator)
				.titleHandler(this.isAppDialog() ? null : () => this.settings.navigator ? 'Dölj navigatorn' : 'Visa navigatorn'),
			new DoiAction(this, 'NavigatorClose', 'fas-times', 'Stäng')
				.executeHandler(() => this.actionNavigatorClose()),
			new DoiAction(this, 'NavigatorDocked', 'thumbtack')
				.executeHandler(() => this.actionNavigatorDocked())
				.iconHandler(() => this.settings.navigatorDocked ? 'fas-thumbtack' : 'far-thumbtack')
				.titleHandler(() => this.settings.navigatorDocked ? 'Lossa navigatorn' : 'Fäst navigatorn')
				.selectedHandler(() => this.settings.navigatorDocked),
			new DoiAction(this, 'Search', 'fas-search', 'Sök')
				.executeHandler(() => this.actionSearch()),
			new DoiAction(this, 'Settings', 'cog', 'Inställningar')
				.executeHandler(() => this.actionSettings()),
			new DoiAction(this, 'DebugMode', 'bug', 'Utvecklingsläge')
				.executeHandler(() => this.actionDebugMode())
				.selectedHandler(() => this.settings.debugMode),
			new DoiAction(this, 'Exit', 'times-circle', 'Avsluta')
				.executeHandler(() => this.actionExit())
		)

		//	Subscribe to URL changes.

		router.events.subscribe(event =>
		{
			this.log('Router event', event);
			if (event instanceof NavigationEnd) {
				this.navigationEnd(event)
			}
		});

		this.refreshTitle();

		//	Create navigator root.

		this.navRoot = new DoiNavNode().setIconMapper(this);
	}

	/**
	 * Invoked when both appInfo and systemInfo has been received.
	 */
	infoReceived()
	{
	}

	/**
	 * Select a process structure. The default implementation does nothing.
	 */
	selectProcessStructure(procStructID?: number)
	{
	}

	/**
	 * Invoked after completed navigation.
	 * If a navigationEnd function has been set by a script, it is invoked with the URL and view title.
	 * Used for better integration with e g Google Analytics.
	 */
	navigationEnd(event: NavigationEnd)
	{
		this.lastUrl = event.url;
		try {
			if (navigationEnd) {
				console.log(event.urlAfterRedirects);
				let title = document.title;
				if (this.activeView) {
					let t = this.activeView.typeTitle();
					if (t) {
						title = t;
						if (this.activeView.activeSubView) {
							t = this.activeView.activeSubView.typeTitle();
							if (t)
								title += ' - '+t;
						}
					}
				}
				navigationEnd(event.urlAfterRedirects, title, this.localStorageChoice());
			}
		} catch (ignored) {}
	}

	/**
	 * Test if the navigator should be on the right side instead of the left.
	 * Some times overridden to return true if Dialog.
	 */
	navigatorRight(): boolean
	{
		return false;//this.isAppDialog();
	}

	/**
	 * Test if Klara Dialog is licensed.
	 */
	hasFeatureDialog(): boolean
	{
		if (!this.appInfo)
			return false;

		if (!this.appInfo.hasFeatureDialog())
			return false;

		return true;
	}

	/**
	 * Test if experimental features are available.
	 */
	hasFeatureExperimental(): boolean
	{
		if (!this.appInfo)
			return false;

		if (!this.appInfo.hasFeatureExperimental())
			return false;

		return true;
	}

	/**
	 * Test if Process is licensed.
	 */
	hasFeatureProcess(): boolean
	{
		if (!this.appInfo)
			return false;

		if (!this.appInfo.hasFeatureProcess())
			return false;

		if (!this.systemInfo)
			return false;

		if (!this.systemInfo.webProcesses)
			return false;

		return true;
	}

	/**
	 * Test if Klara WebUI is licensed.
	 */
	hasFeatureWeb(): boolean
	{
		if (!this.appInfo)
			return false;

		if (!this.appInfo.hasFeatureWeb())
			return false;

		return true;
	}

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return this.environment.dialog;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return !this.environment.dialog;
	}

	/**
	 * Test if selecting a process structure is supported. The default implementation returns false.
	 */
	isProcessStructureSelectable()
	{
		return false;
	}

	/**
	 * Test if editing the specified object is possible and allowed.
	 * Overridden to check that the object is of a supported type and that the required licensed feature is enabled.
	 */
	canEdit(object: DoiObject): boolean
	{
		if (!object)
			return false;

		if (!this.appInfo)
			return false;

		if (!this.appInfo.hasFeatureEdit())
			return false;

		switch (object.objectType) {
			case 'Process':
				break;
			case 'ProcessActType':
				break;
			case 'Unit':
				break;
			default:
				return false;
		}

		if (!this.doi.auth.hasAnyRole('User', 'Editor', 'StructAdm', 'Admin'))
			return false;

		return object.permitWrite();
	}

	/**
	 * Create a new empty settings object. Override to create an application specific subclass.
	 */
	createSettings(): AppSettings
	{
		return new AppSettings();
	}

	/**
	 * Translate an icon name to one or more FontAwesome class names separated by spaces.
	 * The default implementation translates an icon name matching "fa?-nnn" to "fa? fa-nnn" and all others to "fad fa-xxx".
	 */
	iconClass(iconName: string): string
	{
		if (iconName == null)
			return null;
		let match = this.iconClassMatch(iconName);
		if (match)
			return match[1]+' fa-'+this.iconName(match[2]+match[3]);

		return this.iconStyleClass()+' fa-'+this.iconName(iconName);
	}

	/**
	 * Return the FontAwesome style class (fas, far, fad, etc) to use if not specified by the icon name.
	 * The default implementation checks the theme property "icons". If undefined "fad" is returned.
	 */
	iconStyleClass(): string
	{
		if (this.themes.theme) {
			switch (this.themes.theme.properties.get('icons')) {
				case 'solid': return 'fas';
				case 'regular': return 'far';
				case 'light': return 'fal';
				default: return 'fad';
			}
		} else {
			return 'fad';
		}
	}

	/**
	 * Test if the user has consented to store settings in local storage.
	 * Returns true or false if the user has made a choice, otherwise null.
	 */
	localStorageChoice(): boolean
	{
		return this.doi.storage.getContextItem('remember', null);
	}

	/**
	 * Consent or reject storing settings in local storage.
	 */
	localStorageConsent(consent: boolean)
	{
		this.doi.storage.useLocalStorage(consent);
		return this.doi.storage.setContextItem('remember', consent);
	}

	logoURL(): string
	{
		return this.doi.auth.urlContextPub()+'/logo/Klara';
	}

	ngOnInit()
	{
		if (this.settings.navigatorWidth)
			this.navWidth(this.settings.navigatorWidth);
	}

	/**
	 * Invoked by the search tool search action.
	 */
	onSearch(text: string)
	{
		this.doi.router.navigate(['/search'], { queryParams: { text: text, scope: this.searchScope() } });
	}

	actionKlaraAdmin(): string
	{
		var object: DoiObject = null;

		if (this.activeView instanceof DoiObjectView)
			object = this.activeView.object;

		if (object && object.objectID) {
			object.objectType;
			object.objectID;
			return this.urlContext()+'/installer/open/'+object.objectType+'/'+object.objectID+'/run.klara';
		} else {
			return this.urlContext()+'/installer/start/run.klara';
		}
	}

	/**
	 * Test if switching to Klara Web should be available. The default implementation returns false.
	 */
	actionKlaraWebAvailable(edit = false): boolean
	{
		return false;
	}

	/**
	 * Navigate to the Login page.
	 */
	actionLogin()
	{
		let returnUrl = (this.lastUrl && !this.lastUrl.startsWith('/login') ? this.lastUrl : '/');
		this.doi.router.navigate(['/login', {returnUrl: returnUrl}]);
	}

	/**
	 * Log out and return to the start page.
	 */
	actionLogout(explicit: boolean = true)
	{
		this.log('logout');
		this.logoutPending = true;

		this.doi.router.navigate(['/start']).then(result => {
			this.logoutPending = false;
			this.doi.auth.logout(explicit);
			this.urlNavigated(this.lastUrl);
		});
	}

	actionAccount()
	{
		this.doi.router.navigate(['/account', {returnUrl: this.lastUrl}]);
	}

	actionChangePwd()
	{
		this.doi.router.navigate(['/account/changepwd', {returnUrl: this.lastUrl}]);
	}

	/**
	 * Show or hide the navigator.
	 */
	actionNavigator()
	{
		this.settings.navigator = !this.settings.navigator;
		this.saveSettings();

		let url = this.lastUrl;

		if (this.settings.navigator) {
			if (this.activeView instanceof DoiObjectView) {
				if (this.activeView.object) {
					url = null;
					this.objectVisible(this.activeView.object);
					this.objectRefreshed(this.activeView.object);
				}
			}
			if (url)
				this.urlNavigated(url);
		}
	}

	/**
	 * Hide the navigator.
	 */
	actionNavigatorClose()
	{
		this.settings.navigator = false;
		this.saveSettings();
	}

	/**
	 * Set the navigator docked or undocked.
	 */
	actionNavigatorDocked()
	{
		this.settings.navigatorDocked = !this.settings.navigatorDocked;
		this.saveSettings();
	}

	/**
	 * Return the application title.
	 */
	appTitle(): string
	{
		return this.appTitlePrefix()+' '+this.appTitleSuffix();
	}

	/**
	 * Return the application title prefix.
	 */
	appTitlePrefix(): string
	{
		return 'Klara';
	}

	/**
	 * Return the application title suffix.
	 */
	abstract appTitleSuffix(): string;

	/**
	 * Determine the search scope based on navigator path.
	 */
	searchScope(): string
	{
		let path = this.navRoot.getExpandedPath();

		let scope: string = null;

		//	Check for a node with an object ref.

		for (let node of path) {
			if (node.pathElement) {
				if (node.pathElement.objectType == 'Archive')
					scope = 'Archive/'+node.pathElement.objectID;
				else if (node.pathElement.objectType == 'ArchiveOrig')
					scope = 'ArchiveOrig/'+node.pathElement.objectID;
				else if (node.pathElement.objectType == 'Process')
					scope = 'Process/'+node.pathElement.objectID;
				else if (node.pathElement.objectType == 'ProcessStructure')
					scope = 'ProcessStructure/'+node.pathElement.objectID;
				else if (node.pathElement.objectType == 'Series')
					scope = 'Series/'+node.pathElement.objectID;
			}
		}

		if (scope)
			return scope;

		//	TODO: Check for a folder node that can provide an object type scope.

		if (path.length) {
			switch (path[path.length-1].url) {
				case '/archiveorigs':	return 'structure';
				case '/units':			return 'storage';
			}
		}
		return scope;
	}

	/**
	 * Test if process codes should be visible.
	 */
	showProcessCodes()
	{
		return true;
	}

	actionSearch()
	{
		this.doi.router.navigate(['/search', {returnUrl: this.lastUrl}], { queryParams: { scope: this.searchScope() } });
	}

	/**
	 * Show the Settings page.
	 */
	actionSettings()
	{
		this.doi.router.navigateByUrl('/settings');

		// Close an undocked navigator since that doesn't happen automatically if Settings is a button, which doesn't propagate the click.

		this.navigatorCloseUndocked();
	}

	/**
	 * Skip to content by asking the the current view to focus its first component.
	 */
	actionSkipToContent()
	{
		this.focusFirstActiveView(null);
	}

	actionExit()
	{
		this.actionLogout(false);
		window.location.href = '../index.html';
	}

	actionDebugMode(debugMode?: boolean)
	{
		if (debugMode == undefined)
			this.settings.debugMode = !this.settings.debugMode;
		else
			this.settings.debugMode = debugMode;

		this.saveSettings()

		this.doi.log.level = this.settings.debugMode ? DoiLogLevel.FINE : DoiLogLevel.INFO;
	}

	authenticatedName(): string
	{
		return this.doi.auth.session.name;
	}

	/**
	 * Invoked when a user login or logout is completed.
	 * Clears the search result, refreshes the active view, navigator and the bookmark list.
	 */
	authenticatedStateChanged(loggedIn: boolean)
	{
		this.log('Authenticated: ' + loggedIn)

		this.themeUse();
		this.searchService.invalidateResult();

		if (this.activeView)
			this.activeView.refresh();

		if (this.navRoot) {
			let pn = this.navRoot.findChildByUrl('/archiveorigs');
			if (pn && pn.expanded)
				pn.expand();
			pn = this.navRoot.findChildByUrl('/units');
			if (pn && pn.expanded)
				pn.expand();
		}

		this.doi.bookmarkList(true);

		this.systemInfoService.readPublishLevel().subscribe(
			(publishLevel) => this.publishLevel = publishLevel
		);
	}

	/**
	 * Return the action names for the application menu.
	 * @param full Indicates if the full menu should be returned, visible on a reduced screen.
	 */
	menuAppActionNames(full: boolean): string[]
	{
		let actionNames = new Array<string>();

		actionNames.push('FileShare');
		actionNames.push('ViewModeGrid');
		actionNames.push('ViewModeTable');
		actionNames.push('Navigator');
		if (!this.authenticated())
			actionNames.push('Settings');
		actionNames.push('-');
		actionNames.push('Exit');

		if (this.activeView) {
			let viewActionNames = this.activeView.menuAppActionNames(full);
			if (viewActionNames.length) {
				actionNames.push('-');
				actionNames.push.apply(actionNames, viewActionNames);
			}
		}

		return actionNames;
	}

	/**
	 * Return the action names for the user menu.
	 * @param full Indicates if the full menu should be returned, visible on a reduced screen.
	 */
	menuUserActionNames(): string[]
	{
		return ['Settings', 'Account', '-', 'Logout'];
	}

	/**
	 * Create a path (URL commands) for navigating to the specified object, and optionally pass path options.
	 * Overridden to provide a default subview name.
	 * @param objectType The symbolic object type name, e g 'Issue'.
	 * @param objectID The object ID.
	 * @param subviewName The subview name, or null, e g 'general'.
	 * @param options Path options, or null.
	 * @param outlet The outlet name for the subview, or null.
	 */
	objectPath(objectType: string, objectID: number, subviewName?: string, options?: any, outlet?: string)
	{
		switch (objectType) {
			default:
				if (subviewName === undefined)
					subviewName = 'general';
		}

		return super.objectPath(objectType, objectID, subviewName, options, outlet);
	}

	/**
	 * Test if act kind properties should be propagated to act types.
	 */
	propagateProperties()
	{
		return this.systemInfo && this.systemInfo.propagateProperties;
	}


	refreshTitle()
	{
		let title = this.appTitle();
		this.titleService.setTitle(title);
	}

	/**
	 * Configure and use theme.
	 */
	themesInit()
	{
		let appTarget = this.isAppDialog() ? 'Dialog' : 'WebUI';

		let params = new HttpParams().set('target', appTarget);

		this.themes.configure(
			this.doi.auth.urlContextPub()+'/theme/{name}/file/{file}',
			(name) => {
				return this.doi.http.get(this.doi.auth.urlContext()+'/theme/'+name+'/'+name+'.json', { params: params }).pipe(
					map((response: any) => {
						return new DoiTheme(response.name, response.label, response.description, response.properties);
					})
				)
			},
			() => this.doi.http.get(this.doi.auth.urlContext()+'/themes/themes.json', { params: params }).pipe(
				map((response: any) => {
					let list = response.list as any[];
					let themes = new Array<DoiTheme>();
					for (let entry of list) {
						let theme = new DoiTheme(entry.name, entry.label, entry.description, entry.properties);
						let themeTarget = theme.properties.get('target') || 'WebUI';
						if (appTarget == themeTarget)
							themes.push(theme);
					}
					return themes;
				})
			)
		);

		this.themes.themeChange().subscribe((name: string) => {
			this.processService.themeNegative = (this.themes.theme.properties.get('negative') || 'false').toLowerCase() == 'true';
		});

		this.themeUse();
	}

	/**
	 * Fetch and use the selected theme. The theme actually used may be defferent if the named theme doesn't exist
	 * or isn't published.
	 */
	themeUse()
	{
		this.themes.fetch(this.settings.themeName).subscribe(
			(theme: DoiTheme) => {
				this.themes.useTheme(theme, this.settings.themeName != theme.name);
			});
	}

	/**
	 * Invoked when a URL has been navigated to.
	 * Overridden to prevent expansion during logout.
	 */
	urlNavigated(url: string)
	{
		this.lastUrl = url;

		if (!this.logoutPending)
			super.urlNavigated(url);
	}

	/**
	 * Find the navigator node corresponding to the specified URL. Invoked by urlNavigated when a URL has been navigated to.
	 * The default implementation invokes findByUrl on the navigator root.
	 * Override to handle special cases.
	 */
	urlNavNode(url: string): DoiNavNode
	{
		return this.navRoot.findByUrl(url);
	}
}
