import {Component, HostListener} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {DoiService, DoiObject, DoiSelectionCriteria, DoiObjectPanel} from '../../doi/DoiModule';
import {ObjectFile, KlaraObjectPanel, PropertyValue, UnitValueReader, Unit} from '../../klara/KlaraModule';
import {ProcessService} from '../model/ProcessService';
import {ProcessPart, ProcessObject, ProcessChild, ProcessActivity, ProcessActType, ProcessUnit} from '../model/ProcessObject';
import {ProcessObjectGeneralPanel} from './ProcessObjectGeneralPanel';
import {UnitPart, UnitService} from '../../unit/UnitModule';

@Component({
	templateUrl: 'ProcessObjectGeneralDialogPanel.html'
})
export class ProcessObjectGeneralDialogPanel extends ProcessObjectGeneralPanel implements UnitValueReader
{

	/**
	 * ID för de aktiviteter som är expanderade.
	 */
	private expandedActivities = new Set<number>();

	/**
	 * ID för de FE som är expanderade.
	 */
	private expandedUnits = new Set<number>();


	constructor(doi: DoiService, route: ActivatedRoute, processService: ProcessService, unitService: UnitService)
	{
		super(doi, route, processService, unitService);
	}

	bodyLeftVisible(): boolean
	{
		return (this.object.processTypeID == 3 && this.object.description != null) || (this.propertyValues().length > 0) || (this.object.activities != null && this.object.activities.length > 0);
	}

	bodyLeftColClasses(): string
	{
		if (this.bodyRightVisible())
			return 'col-md-7';
		else
			return 'col-12';
	}

	bodyRightVisible(): boolean
	{
		return this.showUnits() || (this.processActTypes != null && this.processActTypes.length > 0);
	}

	bodyRightColClasses(): string
	{
		if (this.bodyLeftVisible())
			return 'col-md-5';
		else
			return 'col-12';
	}

	/********Units *************/

	/**
	 * Returnera
	 */
	isUnitExpanded(unit: Unit): boolean
	{
		return this.expandedUnits.has(unit.unitID);
	}

	openUnit(unitID : number)
	{
		this.appView.openObject('Unit', unitID);
	}

	showUnits(): boolean
	{
		return this.units && this.units.length > 0;
	}

	readChildrenLimit(): number
	{
		return null;
	}

	readProcessActTypesLimit(): number
	{
		return null;
	}

	readUnitPropertyValues(unit: Unit): Observable<PropertyValue[]>
	{
		return this.unitService.readObjectPart(unit.unitID, UnitPart.PropertyValues).pipe(
			switchMap((unit) => {
				return of(unit.propertyValues)
			})
		);
	}

	readUnitNotes(unit: Unit): Observable<string>
	{
		return this.unitService.readObjectPart(unit.unitID, UnitPart.Notes).pipe(
			switchMap((unit) => {
				return of(unit.notes)
			})
		);
	}

	/******************* Activities **********************

	/**
	* Kontrollerar om en aktivitet är expanderad
	**/
	activityExpanded(activity: ProcessActivity): boolean {
		return this.expandedActivities.has(activity.activityID);
	}

	/**
	 * Expand or collapse activity
	 **/
	activityExpandCollapse(activity: ProcessActivity)
	{
		console.log('activityExpanded', activity);
		if (this.expandedActivities.has(activity.activityID))
			this.expandedActivities.delete(activity.activityID);
		else
			this.expandedActivities.add(activity.activityID);
	}

	activityExpandIconClass(activity: ProcessActivity)
	{
		if (this.expandedActivities.has(activity.activityID))
			return this.iconClass("angle-up");
		else
			return this.iconClass("angle-down");
	}

	routerLinkLinkedProcess(activity : ProcessActivity)
	{
		return [ '/process', activity.linkedProcessID ];
	}

	refreshViewObservables(): Observable<any>[]
	{
		let observables = super.refreshViewObservables();

		observables.push(
			this.refreshObjectPart(ProcessPart.Activities),);
		observables.push(
			this.refreshObjectPart(ProcessPart.UnitsNav).pipe(
				tap(process => {
					this.units = new Array<Unit>();
					for (let u of process.units) {
						this.units.push(u.toUnit());
					}
				})
			),
		);

		return observables;
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processActTypeIconClass()
	{
		return this.iconClass(ProcessActType.iconName);
	}

	/**
	 *	Handle printing action
	 */
	activitiesToStayExpanded = [];

	@HostListener('window:beforeprint', ['$event'])
	onBeforePrint(event: Event): void {
		this.activitiesToStayExpanded = [];
		this.object.activities.forEach((activity) => {

			if (this.activityExpanded(activity) === true)
				this.activitiesToStayExpanded.push(activity);
			 else
				this.activityExpandCollapse(activity);
		});
	}

	@HostListener('window:afterprint', ['$event'])
	onAfterPrint(event: Event): void {
		this.object.activities.forEach((activity) => {
			if (this.activitiesToStayExpanded.includes(activity) === false)
				this.activityExpandCollapse(activity);
		});
	}
}
// gX5SvmHcXpePl65Ou85kM04kZCY=
