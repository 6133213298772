import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {environment} from '../../environments/environment';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';
import {KlaraModule} from '../klara/KlaraModule';
import {ProcessService} from './model/ProcessService';
import {ProcessObjectView} from './view/ProcessObjectView';
import {ProcessObjectDialogView} from './view/ProcessObjectDialogView';
import {ProcessObjectActTypesPanel} from './view/ProcessObjectActTypesPanel';
import {ProcessObjectGeneralPanel} from './view/ProcessObjectGeneralPanel';
import {ProcessObjectGeneralDialogPanel} from './view/ProcessObjectGeneralDialogPanel';
import {ProcessObjectUnitsPanel} from './view/ProcessObjectUnitsPanel';
import {ProcessObjectActivitiesPanel, ProcessObjectActivityActTypesDialog} from './view/ProcessObjectActivitiesPanel';
import {ProcessObjectDiagramPanel} from './view/ProcessObjectDiagramPanel';
import {ProcessObjectDetailPanel} from './view/ProcessObjectDetailPanel';
import {ProcessObjectActKindPanel} from './view/ProcessObjectActKindPanel';
import {AuditLogEntryService} from '../auditlogentry/AuditLogEntryModule';
import {AuditLogEntryObjectPanel} from '../auditlogentry/view/AuditLogEntryObjectPanel';

export * from './model/ProcessObject';
export * from './model/ProcessService';

export const processRoutes: Routes = [
	{path: 'process/:id', component: environment.dialog ? ProcessObjectDialogView : ProcessObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: environment.dialog ? ProcessObjectGeneralDialogPanel : ProcessObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'acttypes', component: ProcessObjectActTypesPanel, data: {title: 'Handlingstyper'}},
		{path: 'activities', component: ProcessObjectActivitiesPanel, data: {title: 'Aktiviteter'}},
		{path: 'units', component: ProcessObjectUnitsPanel, data: {title: 'Förvarar'}},
		{path: 'diagram', component: ProcessObjectDiagramPanel, data: {title: 'Diagram'}},
		{path: 'details', component: ProcessObjectDetailPanel, data: {title: 'Detaljer'}},
		{path: 'actkind', component: ProcessObjectActKindPanel, data: {title: 'Handlingsslag'}},
		{path: 'auditlogentry', component: AuditLogEntryObjectPanel, data: {title: 'Granskningslogg'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(processRoutes),
		DoiModule, KlaraModule,
	],
	declarations: [
		ProcessObjectView, ProcessObjectDialogView, ProcessObjectActTypesPanel, ProcessObjectGeneralPanel, ProcessObjectGeneralDialogPanel,
		ProcessObjectUnitsPanel, ProcessObjectActivitiesPanel, ProcessObjectActivityActTypesDialog,
		ProcessObjectDiagramPanel, ProcessObjectDetailPanel, ProcessObjectActKindPanel
	],
	exports: [
	],
	providers: [
		ProcessService
	],

})
export class ProcessModule
{
}

// +Nd1kTXg3jtV1rx66xWIx4RuWNs=
