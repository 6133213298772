import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EMPTY, Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiService} from '../../doi/DoiModule';

import {UnitService} from '../../unit/model/UnitService';
import {UnitPart, UnitObject} from '../../unit/model/UnitObject';
import {KlaraObjectView} from '../../klara/KlaraModule';

@Component({
	templateUrl: 'UnitObjectView.html'
})
export class UnitObjectView extends KlaraObjectView<UnitObject>
{
	name = 'UnitObjectView';

	/**
	 * Template for a new object.
	 */
	objectTemplate: UnitObject;

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, private unitService: UnitService, route: ActivatedRoute)
	{
		super(doi, unitService, route);
	}

	/**
	 * Create an observable that when subscribed starts editing the object and activates the optional subview.
	 * Overridden to fetch property values for edit before switching to the super implementation.
	 * @param subviewName The name of the subview to activate.
	 */
	editStartObservable(subviewName?: string): Observable<string>
	{
		if (!this.object.propertyValues)
			return super.editStartObservable();

		return this.unitService.readPropertyValuesForEdit(this.objectID, this.object).pipe(
			switchMap((unit) => {
				return super.editStartObservable(subviewName);
			})
		);
	}

		/**
	 * Test if an object panel is editable when in edit mode.
	 * Overridden to check panel name and user permissions.
	 */
	objectPanelEditable(panelName: string): boolean
	{
		switch (panelName) {
			case 'general':
				return this.editingAsEditorOrUser();
			default:
				return false;
		}
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.doi.auth.isAuthenticating())
			return EMPTY;

		return forkJoin(
			this.refreshObjectPart(UnitPart.Label).pipe(
				switchMap((unit: UnitObject) => {
					return this.unitService.parentObjectObservable(unit);
				})
			),
			this.probeObjectPart(UnitPart.General),
			this.probeObjectPart(UnitPart.Children),
			this.probeObjectPart(UnitPart.ProcActTypes),
			this.probeObjectPart(UnitPart.Processes),

			super.refreshView()
		);
	}

	routerLinkUnits()
	{
		return ['/units'];
	}

	routerLinkParentParentUnit()
	{
		return this.object.parent && this.object.parent.parentUnitID ? ['/unit', this.object.parent.parentUnitID] : null;
	}

	routerLinkParentUnit()
	{
		return this.object.parentUnitID ? ['/unit', this.object.parentUnitID] : null;
	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Overridden to invoke probedPart with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		switch (panelName) {
			case 'general':					return this.probedPart('General');
			case 'processesandacttypes':	return this.probedPart('ProcActTypes', 'Processes');
			case 'children':				return this.probedPart('Children');
			case 'auditlogentry':			return true;
		}
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Förvaringsenhet'; // I18N
	}


}

// CpSJMubqgsq90Sm6b4jfXxtxKcM=
