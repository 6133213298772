import {NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {DoiDragSource} from './core/DoiDragSource';
import {DoiDropTarget} from './core/DoiDropTarget';
import {DoiEmptyHrefDirective} from './core/DoiEmptyHrefDirective';
import {DoiHiddenDirective} from './core/DoiHiddenDirective';
import {DoiStorageOwner} from './core/DoiStorageOwner';

import {DoiAlertService} from './service/DoiAlertService';
import {DoiAuthGuard} from './service/DoiAuthGuard';
import {DoiAuthInterceptor} from './service/DoiAuthInterceptor';
import {DoiAuthPrincipalService} from './service/DoiAuthPrincipalService';
import {AUTH_API_VERSION, DoiAuthService} from './service/DoiAuthService';
import {DoiLocaleInterceptor} from './service/DoiLocaleInterceptor';
import {DoiLogService} from './service/DoiLogService';
import {DoiStorageService} from './service/DoiStorageService';
import {DoiService} from './service/DoiService';

import {DoiBytesPipe} from './util/DoiBytesPipe';
import {DoiFilterPipe} from './util/DoiFilterPipe';
import {DoiSafeHtmlPipe} from './util/DoiSafeHtmlPipe';
import {DoiSafeUrlPipe} from './util/DoiSafeUrlPipe';

import {DoiObjectEditGuard} from './view/DoiObjectEditGuard';
import {DoiModalDialog, DoiModalDialogFooter, DoiModalDialogHeader} from './view/DoiModalDialog';
import {DoiRedirectGuard, DoiRedirectTarget} from './view/DoiRedirectGuard';

import {DoiAlertComponent} from './widget/DoiAlertComponent';
import {DoiBreadcrumbsComponent, DoiBreadcrumbComponent} from './widget/DoiBreadcrumbsComponent';
import {DoiCheckBoxComponent, DoiCheckFieldComponent} from './widget/DoiCheckBoxComponent';
import {DoiDropdownItemComponent} from './widget/DoiDropdownItemComponent';
import {DoiDropdownMenuComponent} from './widget/DoiDropdownMenuComponent';
import {DoiFieldComponent} from './widget/DoiFieldComponent';
import {DoiFilterComponent} from './widget/DoiFilterComponent';
import {DoiLabelComponent} from './widget/DoiLabelComponent';
import {DoiLookupComponent} from './widget/DoiLookupComponent';
import {DoiObjectBookmarkToolComponent} from './widget/DoiObjectBookmarkToolComponent';
import {DoiObjectBookmarksToolComponent} from './widget/DoiObjectBookmarksToolComponent';
import {DoiObjectEditToolsComponent} from './widget/DoiObjectEditToolsComponent';
import {DoiObjectGridComponent} from './widget/DoiObjectGridComponent';
import {DoiObjectGridItemComponent} from './widget/DoiObjectGridItemComponent';
import {DoiRefreshIndComponent} from './widget/DoiRefreshIndComponent';
import {DoiRowHeaderComponent} from './widget/DoiRowHeaderComponent';
import {DoiSortComponent} from './widget/DoiSortComponent';
import {DoiSwitchComponent} from './widget/DoiSwitchComponent';
import {DoiTableEditToolsComponent} from './widget/DoiTableEditToolsComponent';
import {DoiTextAreaComponent} from './widget/DoiTextAreaComponent';
import {DoiTitleComponent} from './widget/DoiTitleComponent';
import {DoiToolComponent} from './widget/DoiToolComponent';
import {DoiToolbarComponent} from './widget/DoiToolbarComponent';
import {DoiSelectComponent} from './widget/DoiSelectComponent';
import {DoiViewFooterComponent} from './widget/DoiViewFooterComponent';
import {DoiViewModeToolsComponent} from './widget/DoiViewModeToolsComponent';
import {DoiViewTabComponent, DoiViewTabsComponent} from './widget/DoiViewTabComponent';
import {DoiViewTitleComponent} from './widget/DoiViewTitleComponent';

export * from './bookmark/DoiBookmark';
export * from './bookmark/DoiObjectBookmark';
export * from './bookmark/DoiBookmarkList';

export * from './core/DoiAction';
export * from './core/DoiActionTarget';
export * from './core/DoiActionSet';
export * from './core/DoiComparators';
export * from './core/DoiDragSource';
export * from './core/DoiDropTarget';
export * from './core/DoiEmptyHrefDirective';
export * from './core/DoiFocusFirst';
export * from './core/DoiIconMapper';
export * from './core/DoiKeyMapping';
export * from './core/DoiLabeledValue';
export * from './core/DoiStorageOwner';
export * from './core/DoiTableEditor';
export * from './core/DoiValidators';

export * from './service/DoiAlertService';
export * from './service/DoiApiVersion';
export * from './service/DoiAuthGuard';
export * from './service/DoiAuthPrincipalService';
export * from './service/DoiAuthService';
export * from './service/DoiAuthSession';
export * from './service/DoiLogService';
export * from './service/DoiObject';
export * from './service/DoiObjectCache';
export * from './service/DoiService';
export * from './service/DoiBrokerService';
export * from './service/DoiSelectionCriteria';

export * from './tree/DoiTreeNode';
export * from './tree/DoiTreeNodeChildSupplier';

export * from './util/DoiBytesPipe';
export * from './util/DoiFileCache';
export * from './util/DoiRefreshHandler';

export * from './view/DoiAppView';
export * from './view/DoiBinderView';
export * from './view/DoiModalDialog';
export * from './view/DoiObjectEditGuard';
export * from './view/DoiObjectPanel';
export * from './view/DoiObjectView';
export * from './view/DoiRedirectGuard';
export * from './view/DoiSelectionView';
export * from './view/DoiSubView';
export * from './view/DoiTopView';
export * from './view/DoiView';

export * from './widget/DoiFieldComponent';
export * from './widget/DoiSortComponent';

export {DoiValueComponent} from './widget/DoiValueComponent';

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule,
		HttpClientModule,
		RouterModule,
		NgbModule
	],
	declarations: [
		DoiEmptyHrefDirective, DoiHiddenDirective,
//		DoiScrollDirective, DoiScrollRefreshDirective,
		DoiBytesPipe, DoiFilterPipe, DoiSafeHtmlPipe, DoiSafeUrlPipe,
		DoiAlertComponent,
		DoiBreadcrumbsComponent, DoiBreadcrumbComponent,
		DoiDragSource, DoiDropTarget,
		DoiDropdownMenuComponent, DoiDropdownItemComponent,
		DoiCheckBoxComponent, DoiCheckFieldComponent, DoiFieldComponent, DoiFilterComponent, DoiLabelComponent, DoiLookupComponent,
		DoiModalDialog, DoiModalDialogFooter, DoiModalDialogHeader,
		DoiObjectBookmarkToolComponent, DoiObjectBookmarksToolComponent, DoiObjectEditToolsComponent, DoiObjectGridComponent, DoiObjectGridItemComponent,
		DoiRefreshIndComponent,
		DoiRowHeaderComponent,
		DoiSelectComponent, DoiSortComponent, DoiSwitchComponent,
		DoiTableEditToolsComponent,
		DoiTextAreaComponent,
		DoiTitleComponent, DoiToolComponent, DoiToolbarComponent,
		DoiViewFooterComponent, DoiViewModeToolsComponent, DoiViewTabsComponent, DoiViewTabComponent, DoiViewTitleComponent,
	],
	exports: [
		DoiEmptyHrefDirective, DoiHiddenDirective,
//		DoiScrollRefreshDirective,
		DoiBytesPipe, DoiFilterPipe, DoiSafeHtmlPipe, DoiSafeUrlPipe,
		DoiAlertComponent,
		DoiBreadcrumbsComponent, DoiBreadcrumbComponent,
		DoiDragSource, DoiDropTarget,
		DoiDropdownMenuComponent, DoiDropdownItemComponent,
		DoiCheckBoxComponent, DoiCheckFieldComponent, DoiFieldComponent, DoiFilterComponent, DoiLabelComponent, DoiLookupComponent,
		DoiModalDialog, DoiModalDialogFooter, DoiModalDialogHeader,
		DoiObjectBookmarkToolComponent, DoiObjectBookmarksToolComponent, DoiObjectEditToolsComponent, DoiObjectGridComponent, DoiObjectGridItemComponent,
		DoiRefreshIndComponent,
		DoiRowHeaderComponent,
		DoiSelectComponent, DoiSortComponent, DoiSwitchComponent,
		DoiTableEditToolsComponent,
		DoiTextAreaComponent,
		DoiTitleComponent, DoiToolComponent, DoiToolbarComponent,
		DoiViewFooterComponent, DoiViewModeToolsComponent, DoiViewTabsComponent, DoiViewTabComponent, DoiViewTitleComponent,
	],
	providers: [
		DatePipe,
		DoiAlertService,
		DoiAuthGuard, DoiAuthService, DoiAuthPrincipalService, DoiObjectEditGuard, DoiLogService, DoiRedirectGuard, DoiRedirectTarget, DoiStorageService,
		DoiService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DoiAuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DoiLocaleInterceptor,
			multi: true,
		}
	],
})
export class DoiModule
{
}
