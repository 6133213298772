import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';

import {KlaraModule} from '../klara/KlaraModule';
import {SeriesService} from './model/SeriesService';
import {SeriesObjectView} from './view/SeriesObjectView';
import {SeriesObjectGeneralPanel} from './view/SeriesObjectGeneralPanel';
import {SeriesObjectVolumesPanel} from './view/SeriesObjectVolumesPanel';
import {SeriesObjectActTypesPanel} from './view/SeriesObjectActTypesPanel';
import {AuditLogEntryObjectPanel} from '../auditlogentry/view/AuditLogEntryObjectPanel';

export * from './model/SeriesObject';
export * from './model/SeriesService';

export const seriesRoutes: Routes = [
	{path: 'series/:id', component: SeriesObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: SeriesObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'volumes', component: SeriesObjectVolumesPanel, data: {title: 'Volymer'}},
		{path: 'acttypes', component: SeriesObjectActTypesPanel, data: {title: 'Förvarar'}},
		{path: 'auditlogentry', component: AuditLogEntryObjectPanel, data: {title: 'Logg'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(seriesRoutes),
		DoiModule, KlaraModule,
	],
	declarations: [
		SeriesObjectView, SeriesObjectGeneralPanel, SeriesObjectVolumesPanel, SeriesObjectActTypesPanel,
	],
	exports: [
	],
	providers: [
		SeriesService,
	],

})
export class SeriesModule
{
}

// 9KsJl2UFZbeJxBQC6rd1QghPi1Y=
