import { Host, HostBinding } from "@angular/core";
import { DoiView } from "../view/DoiView";
import { DoiAction } from "./DoiAction";
import { DoiActionSet } from "./DoiActionSet";
import { DoiActionTarget } from "./DoiActionTarget";

/**
 * A controller for editing tables and table like elements.
 */
export class DoiTableEditor<HT extends DoiView, IT> implements DoiActionTarget
{
	/**
	 * The host view.
	 */
	view: HT;

	/**
	 * Row header component base name.
	 */
	rowHeaderBaseName = 'rowHeader';

	/**
	 * Actions.
	 */
	actions: DoiActionSet = new DoiActionSet();

	/**
	 * Indicates if all rows are selected.
	 */
	private allSelected: boolean;

	/**
	 * Selected rows.
	 */
	private rowSelected: boolean[] = new Array<boolean>();

	/**
	 * Rows marked for deletion.
	 */
	private rowMarkDeleted: boolean[] = new Array<boolean>();

	/**
	 * Anchored selection.
	 */
	private anchorRowNo: number;
	private anchorExtendRowNo: number;

	/**
	 * Handler that tests if the table is currently being edited.
	 */
	private editingHandler: () => boolean = () => true;

	/**
	 * The row currently being edited.
	 */
	private editingRowNo: number = null;

	/**
	 * The item numbers corresponding to the row numbers, and the item array the numbers are based on.
	 */
	private _itemNos: number[];
	private _itemNosBase: IT[];

	/**
	 * The ordered items and the item array the ordered orray is based on.
	 */
	private _itemsOrdered: IT[];
	private _itemsOrderedBase: IT[];

	/**
	 * Construct a new table editor controller. Subclasses may reference the view variable for easy access to the host view.
	 */
	constructor(view: HT)
	{
		this.view = view;

		this.actions.add(
			new DoiAction(this, 'RowsMoveUp', 'turn-up', 'Flytta upp rader')
			.enabledHandler(() => this.moveRowsUpRange() != null)
			.executeHandler(() => this.moveRowsUp())
		);

		this.actions.add(
			new DoiAction(this, 'RowsMoveDown', 'turn-down', 'Flytta ner rader')
			.enabledHandler(() => this.moveRowsDownRange() != null)
			.executeHandler(() => this.moveRowsDown())
		);

		this.actions.add(
			new DoiAction(this, 'RowsMarkDelete', 'trash', 'Ta bort rad')
			.enabledHandler(() => this.anyRowSelected())
			.executeHandler(() => this.markSelectedRowsDeleted())
		);

		this.actions.add(
			new DoiAction(this, 'RowAdd', 'plus', 'Lägg till rad')
			.enabledHandler(() => true)
			.executeHandler(() => this.addRow())
		);
	}

	/**
	 * Return the specified action. Delegate to the host view if not found locally.
	 */
	action(actionName: string): DoiAction
	{
		let action = this.actions.get(actionName);
		if (action)
			return action;
		else
			return this.view.action(actionName);
	}

	/**
	 * Add an item row.
	 */
	addRow(scrollIntoView = true)
	{
		let items = this.items();
		if (!items)
			return;
		let rowNo = items.length;

		let item = this.createItem();
		items.push(item);
		this._itemNos.push(rowNo);
		this._itemsOrdered.push(item);

		this.updateAllItemSequenceNos();

		this.editRow(rowNo);
	}

	/**
	 * Invoked after moving rows up ur down.
	 */
	afterRowsMoved(firstRowNo: number, lastRowNo: number)
	{
		let items = this.items();
		if (items) {
			for (let rowNo = firstRowNo; rowNo <= lastRowNo; ++rowNo) {
				let itemNo = this.itemNoForRowNo(rowNo);
				if (itemNo != null) {
					let item = items[itemNo];
					if (item) {
						//	Item must be cloned to force Angular change detection.
						item = Object.assign(this.createItem(), item);
						items[itemNo] = item;
					}
				}
			}
			this.updateAllItemSequenceNos();
		}

		//	TODO: Optional.
		this.view.formOnChange(true);
	}

	/**
	 * Test if any row is selected.
	 */
	anyRowSelected(): boolean
	{
		if (this.allSelected)
			return true;
		for (let selected of this.rowSelected) {
			if (selected)
				return true;
		}
		return false;
	}

	/**
	 * Invoked by the host when editing is started, to clear all row selections.
	 */
	clear()
	{
		this.allSelected = false;
		this.rowSelected = new Array<boolean>();
		this.rowMarkDeleted = new Array<boolean>();

		if (this.editingRowNo == null || this.editingRowNo >= this.itemCount())
			this.editRow(0);
	}

	/**
	 * Compare two items for order. Override to compare eg sequence numbers.
	 */
	compareItems(item1: IT, item2: IT): number
	{
		return undefined;
	}

	/**
	 * Return the edited object count.
	 */
	count(): number
	{
		let items = this.items();
		if (items)
			return items.length;
		else
			return 0;
	}

	/**
	 * Create a new item. Must be overridden if row move or add is supported.
	 */
	createItem(): IT
	{
		throw "Create item not supported.";
	}

	/**
	 * Set the handler that tests if the table is currently being edited.
	 */
	edit(editingHandler: () => boolean)
	{
		this.editingHandler = editingHandler;
	}

	/**
	 * Start editing the specified row. Use null to stop editing any row.
	 */
	editRow(rowNo: number)
	{
		if (!this.editing() || this.editingRowNo == rowNo)
			return;

		this.editingRowNo = rowNo;

		if (rowNo != null) {
			this.selectRow(null, false);
			this.selectRow(rowNo, true);
			this.scrollRowIntoView(rowNo);
		}
	}

	/**
	 * Test if the table is currently being edited.
	 */
	editing(): boolean
	{
		return this.editingHandler();
	}

	/**
	 * Test if the table and the specified row is currently being edited.
	 */
	editingRow(rowNo: number): boolean
	{
		return this.editing() && this.editingRowNo === rowNo;
	}

	/**
	 * Translate an icon name to one or more class names separated by spaces. Delegate to the host view.
	 */
	iconClass(iconName: string): string
	{
		return this.view.iconClass(iconName);
	}

	/**
	 * Test if the specified item is marked deletion.
	 * @param item The item.
	 * @return The delete mark.
	 */
	isItemMarkDeleted(item: IT): boolean
	{
		return null;
	}

	/**
	 * Test if the target, usually a view, is displayed on a mobile device. Delegate to the host view.
	 */
	mobileDevice(): boolean
	{
		return this.view.mobileDevice();
	}

	/**
	 * Move selected rows up if allowed. A single continous selection below first row is required.
	 */
	moveRowsUp(): boolean
	{
		let range = this.moveRowsUpRange();
		if (range == null)
			return false;

		this.moveUp(this.rowSelected, range);
		this.moveUp(this.rowMarkDeleted, range);

		let itemNos = this.itemNos();
		if (itemNos) {
			this.moveUp(itemNos, range);
			this._itemsOrdered = null;
		}
		else
			; //TODO invoke moveItemsUp(range);

		this.afterRowsMoved(range.first-1, range.last);

		return true;

	}

	/**
	 * Move selected rows down if allowed. A single continous selection above last row is required.
	 */
	moveRowsDown(): boolean
	{
		let range = this.moveRowsDownRange();
		if (range == null)
			return false;

		this.moveDown(this.rowSelected, range);
		this.moveDown(this.rowMarkDeleted, range);

		let itemNos = this.itemNos();
		if (itemNos) {
			this.moveDown(itemNos, range);
			this._itemsOrdered = null;
		}
		else
			; //TODO invoke moveItemsUp(range);

		this.afterRowsMoved(range.first, range.last+1);

		return true;

	}

	private moveUp(array: any[], range: { first: number, last: number })
	{
		let element = array[range.first-1];
		for (let i = range.first; i <= range.last; ++i) {
			array[i-1] = array[i];
		}
		array[range.last] = element;
	}

	private moveDown(array: any[], range: { first: number, last: number })
	{
		let element = array[range.last+1];
		for (let i = range.last; i >= range.first; --i) {
			array[i+1] = array[i];
		}
		array[range.first] = element;
	}

	/**
	 * Test if moving selected rows up is allowed. A single continous selection below first row is required.
	 * The allowed range is returned, or null.
	 */
	moveRowsUpRange(): { first: number, last: number }
	{
		//	First row must not be selected.
		if (this.rowSelected[0])
			return null;

		//	Find first and last selected, and first unselected after first.
		let firstRowNo = null;
		let lastRowNo = null;
		let unselectedRowNo = null;
		for (let i = 1; i < this.rowSelected.length; ++i) {
			if (this.rowSelected[i]) {
				if (firstRowNo == null)
					firstRowNo = i;
				lastRowNo = i;
			} else {
				if (firstRowNo != null && unselectedRowNo == null)
					unselectedRowNo = i;
			}
		}
		//	Check continous.
		if (firstRowNo == null)
			return null;
		if (unselectedRowNo != null && unselectedRowNo < lastRowNo)
			return null;
		return { first: firstRowNo, last: lastRowNo };
	}

	/**
	 * Test if moving selected rows down is allowed. A single continous selection above last row is required.
	 * The allowed range is returned, or null.
	 */
	moveRowsDownRange(): { first: number, last: number }
	{
		//	Last row must not be selected.
		let n = this.count();
		if (n < 1 || this.rowSelected[n-1])
			return null;

		//	Find first and last selected, and first unselected before last.
		let firstRowNo = null;
		let lastRowNo = null;
		let unselectedRowNo = null;
		for (let i = n-1; i >= 0; --i) {
			if (this.rowSelected[i]) {
				if (lastRowNo == null)
					lastRowNo = i;
				firstRowNo = i;
			} else {
				if (lastRowNo != null && unselectedRowNo == null)
					unselectedRowNo = i;
			}
		}
		//	Check continous.
		if (lastRowNo == null)
			return null;
		if (unselectedRowNo != null && unselectedRowNo > firstRowNo)
			return null;
		return { first: firstRowNo, last: lastRowNo };
	}

	/**
	 * Test if the specified row is the selection anchor.
	 * @param rowNo The 0-based row number.
	 */
	isRowAnchor(rowNo: number): boolean
	{
		return rowNo != null && rowNo === this.anchorRowNo;
	}

	/**
	 * Test if the specified row is marked for deletion.
	 * @param rowNo The 0-based row number.
	 */
	isRowMarkDeleted(rowNo: number): boolean
	{
		return this.rowMarkDeleted[rowNo];
	}

	/**
	 * Test if the specified row is selected.
	 * @param rowNo The 0-based row number, or null for all rows.
	 */
	isRowSelected(rowNo: number): boolean
	{
		if (rowNo != null)
			return this.rowSelected[rowNo];
		else
			return this.allSelected;
	}

	/**
	 * Return the item with the specified 0-based row number, which may be different from the item number if rows has been moved.
	 */
	itemAtRowNo(rowNo: number): IT
	{
		let itemNo = this.itemNoForRowNo(rowNo);
		if (itemNo == null)
			return null;
		return this.items()[itemNo];
	}

	/**
	 * Return the actual array of edited objects. Must be overridden.
	 */
	items(): IT[]
	{
		throw "Override the items method in the view table editor implementation."
	}

	/**
	 * Return the item count.
	 */
	itemCount(): number
	{
		let items = this.items();
		return items ? items.length : 0;
	}

	/**
	 * Return the item number corresponding to the specified 0-based row number, which may be different if rows has been moved.
	 */
	itemNoForRowNo(rowNo: number)
	{
		let itemNos = this.itemNos();
		if (itemNos == null)
			return null;
		return itemNos[rowNo];
	}

	/**
	 * Create and return an item number array indexed by row numbers.
	 */
	itemNos(): number[]
	{
		let items = this.items();
		if (!items) {
			this._itemNos = null;
			this._itemsOrdered = null;
			return null;
		}

		if (!this._itemNos || this._itemNosBase !== items) {
			this._itemNos = Array(items.length).fill(0).map((x,i) => i);
			this._itemNos.sort((i1, i2) => {
				let item1 = items[i1];
				let item2 = items[i2];
				let diff = this.compareItems(item1, item2);
				if (diff != null)
					return diff;
				else
					return i1-i2;
			});
			this._itemNosBase = items;
			this._itemsOrdered = null;
		}

		return this._itemNos;
	}

	/**
	 * Return the items in the order they have after move.
	 * Also fetches item deletion marks if the ordered array is recreated.
	 */
	itemsOrdered(): IT[]
	{
		let itemNos = this.itemNos();
		if (itemNos == null)
			return null;
		let items = this.items();

		if (this._itemsOrdered && this._itemsOrderedBase === items)
			return this._itemsOrdered;

		this._itemsOrdered = Array(items.length).fill(0).map((x,i) => items[itemNos[i]]);
		this._itemsOrderedBase = items;

		for (let i = 0; i < items.length; ++i) {
			let item = this.itemAtRowNo(i);
			let deleteMark = this.isItemMarkDeleted(item);
			if (deleteMark != null)
				this.rowMarkDeleted[i] = deleteMark;
		}

		return this._itemsOrdered;
	}

	/**
	 * Mark the specified item for deletion.
	 * Override to propagate to the actual item.
	 * @param item The item.
	 * @param deleteMark The delete mark.
	 */
	markItemDeleted(item: IT, deleteMark: boolean)
	{
	}

	/**
	 * Set or toggle the delete mark for the specified row. Override to propagate to
	 * @param rowNo The 0-based row number.
	 * @param deleteMark The delete mark, or null to toggle.
	 */
	markRowDeleted(rowNo: number, deleteMark: boolean)
	{
		//	TODO: Optional.
		this.view.formOnChange(true);

		if (deleteMark != null)
			this.rowMarkDeleted[rowNo] = deleteMark;
		else
			this.rowMarkDeleted[rowNo] = !this.rowMarkDeleted[rowNo];
		let item = this.itemAtRowNo(rowNo);
		if (item != null)
			this.markItemDeleted(item, this.rowMarkDeleted[rowNo]);
	}

	/**
	 * Set or toggle the delete mark for all selected rows.
	 * Override to propagate the result to the actual object.
	 * @param deleteMark The delete mark, or null to toggle.
	 * @return true if any row was selected.
	 */
	markSelectedRowsDeleted(deleteMark: boolean = null)
	{
		let n = this.count();

		//	Toggle: If any selected row is deleted, undelete, otherwise delete.
		if (deleteMark == null) {
			let anySelected = false;
			for (let i = 0; i < n; ++i) {
				if (this.rowSelected[i] && this.rowMarkDeleted[i]) {
					deleteMark = false;
					break;
				}
			}
			if (deleteMark == null)
				deleteMark = true;
		}

		//	Apply on all selected rows.
		let any = false;
		for (let i = 0; i < n; ++i) {
			if (this.rowSelected[i]) {
				this.markRowDeleted(i, deleteMark);
				any = true;
			}
		}

		return any;
	}

	/**
	 * Return row classes based on row state.
	 * @param rowNo The 0-based row number, or null for all rows.
	 */
	rowClasses(rowNo: number)
	{
		let classes = '';
		if (this.editing()) {
			if (this.isRowSelected(rowNo))
				classes += ' doi-row-selected';
			if (this.isRowMarkDeleted(rowNo))
				classes += ' doi-row-mark-deleted';
			if (this.editingRow(rowNo))
				classes += ' doi-row-editing';
			else
				classes += ' doi-row-editable';
		}
		return classes.trim();
	}

	/**
	 * Construct an id value for tr elements. Used by addRow to scroll the new row into view.
	 */
	rowId(rowNo: number)
	{
		return this.rowHeaderBaseName+rowNo;
	}

	/**
	 * Set the specified row selected or unselected.
	 * @param rowNo The 0-based row number, or null for all rows.
	 * @param selected The new selected state, or null to toggle.
	 * @param anchor Set the anchor.
	 * @param extend Extend selection.
	 */
	selectRow(rowNo: number, selected: boolean, anchor = true, extend = false)
	{
		let n = this.count();

		if (rowNo != null) {

			//	Row selection.

			if (selected == null) {

				//	Row header toggle selection. Respect anchor and extend.

				if (anchor) {
					if (extend) {
						//	Ctrl+Click: Toggle this, set new anchor, keep anchored selection.
						this.rowSelected[rowNo] = !this.rowSelected[rowNo];
						this.anchorRowNo = rowNo;
						this.anchorExtendRowNo = null;
					} else {
						//	Click: Toggle or select this, set new anchor, clear other selection.
						let selected = !this.rowSelected[rowNo];
						if (this.allSelected && this.itemCount() > 1)
							selected = true;
						else if (this.insideRowRange(this.anchorRowNo, this.anchorExtendRowNo, rowNo))
							selected = true;
						this.rowSelected[rowNo] = selected;
						this.anchorRowNo = rowNo;
						this.anchorExtendRowNo = null;
						for (let i = 0; i < n; ++i) {
							if (i != rowNo)
								this.rowSelected[i] = false;
						}
					}
				} else {
					//	Shift+Click: Selection from anchor.
					if (this.anchorRowNo != null) {
						//	Clear old anchor selection and create a new.
						this.selectRowRange(this.anchorRowNo, this.anchorExtendRowNo, false);
						this.selectRowRange(this.anchorRowNo, rowNo, true);
						this.anchorExtendRowNo = rowNo;
					}
				}

			} else {

				//	Explicit programmatic selection. Ignore anchor and extend.

				this.rowSelected[rowNo] = selected;
			}

			//	Check if all are selected.

			let allSelected: boolean = true;

			for (let i = 0; i < n; ++i) {
				if (!this.rowSelected[i]) {
					allSelected = false;
					break;
				}
			}

			this.allSelected = allSelected;

		} else {

			//	All selection.

			if (selected == null)
				this.allSelected = !this.allSelected;
			else
				this.allSelected = selected;
			for (let i = 0; i < n; ++i) {
				this.rowSelected[i] = this.allSelected;
			}

			this.anchorRowNo = null;
		}
	}

	/**
	 * Scroll the specified row into view.
	 */
	scrollRowIntoView(rowNo: number)
	{
		setTimeout(() => {
			let id = this.rowHeaderBaseName+rowNo;
			let rowElement = document.getElementById(id);
			if (rowElement)
				rowElement.scrollIntoView();
			else
				console.warn('Can\'t scroll row into view. No element with id "'+id+'" found.');
		});
	}

	/**
	 * Test if the specified row is inside a range.
	 * @param rowNoA The 0-based row number that starts or ends the range, or null for false.
	 * @param rowNoB The 0-based row number that starts or ends the range, or null for false.
	 * @param rowNoA The 0-based row number to test, or null for false.
	 */
	private insideRowRange(rowNoA: number, rowNoB: number, rowNo: number): boolean
	{
		if (rowNoA == null || rowNoB == null || rowNo == null)
			return false;
		if (rowNoA < rowNoB)
			return rowNo >= rowNoA && rowNo <= rowNoB;
		else
			return rowNo >= rowNoB && rowNo <= rowNoA;
	}

	/**
	 * Set the specified row selected or unselected.
	 * @param rowNoA The 0-based row number that starts or ends the range, or null to do nothing.
	 * @param rowNoB The 0-based row number that starts or ends the range, or null to do nothing.
	 * @param selected The new selected state.
	 */
	private selectRowRange(rowNoA: number, rowNoB: number, selected: boolean): void
	{
		if (rowNoA == null || rowNoB == null)
			return;
		if (rowNoA < rowNoB) {
			for (let i = rowNoA; i <= rowNoB; ++i) {
				this.rowSelected[i] = selected;
			}
		} else {
			for (let i = rowNoB; i <= rowNoA; ++i) {
				this.rowSelected[i] = selected;
			}
		}
	}

	/**
	 * Return table classes based on state.
	 */
	tableClasses()
	{
		let classes = '';
		if (this.editing()) {
			classes += ' doi-table-editing';
		} else {
			classes += ' doi-table-editable';
		}
		return classes.trim();
	}

	/**
	 * Update all sequence numbers.
	 */
	updateAllItemSequenceNos()
	{
		let items = this.itemsOrdered();
		if (items == null)
			return;
		let rowNo = 0;
		for (let item of items) {
			this.updateItemSequenceNo(item, rowNo++);
		}
	}

	/**
	 * Override to update sequence numbers.
	 */
	updateItemSequenceNo(item: IT, rowNo: number)
	{
	}
}
