import {Component, HostBinding, Input, Output, EventEmitter} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';

import {DoiIconMapper, DoiView} from '../../doi/DoiModule';

import {PropertyValue} from '../model/PropertyValue';
import {Unit} from '../model/Unit';
import {AppInfo, KlaraTopView} from '../KlaraModule';
import {AppBaseView} from '../../AppBaseView';

export interface UnitValueReader
{
	readUnitPropertyValues(unit: Unit): Observable<PropertyValue[]>;
	readUnitNotes(unit: Unit): Observable<string>;
}

/*
 * A unit component.
 */
@Component({
	selector: 'unit',
	template: `
		<div class="unit-header" (click)="expandCollapse()">
			<h4>{{unit.code}} - {{unit.title}}</h4>
			<button class="btn btn-light expand-button" ><i [ngClass]="expandIconClass()"></i></button>
		</div>
		<div *ngIf="expanded">
			<div *ngIf="unit.description || unit.date || unit.propertyValues?.length" class="unit-body group-pane">
				<div>{{unit.date}}</div>
				<h5 *ngIf="unit.description">Beskrivning</h5>
				<div class="unit-description">{{unit.description}}</div>
				<div *ngIf="unit.propertyValues?.length" class="unit-property-values">
					<property-values [view]="view" class="single-column" [propertyValues]="unit.propertyValues" [cellsMD]="12" [cellsLG]="12" [cellsXL]="12"></property-values>
				</div>
			<a class="unit-details-link" *ngIf="checkIfHasFeatureWeb()" href="#" (click)="navigateToUnitDetails()">Visa detaljer</a>
			</div>
		</div>
	`,
	host: { 'class' : 'unit' }
})
export class UnitComponent
{
	/**
	 * The containing view.
	 */
	@Input()
	view: DoiView;

	/**
	 * The unit.
	 */
	@Input()
	unit: Unit;

	@Input()
	unitValueReader: UnitValueReader;

	@HostBinding('class.expanded')
	expanded: boolean;

	expandIconClass()
	{
		if (this.expanded)
			return this.view.iconClass("angle-up");
		else
			return this.view.iconClass("angle-down");
	}

	expandCollapse()
	{
		this.expanded = !this.expanded;

		if (this.expanded) {
			if (!this.unit.propertyValues && this.unitValueReader) {
				this.unitValueReader.readUnitPropertyValues(this.unit).subscribe(
					(propertyValues => {
						this.unit.propertyValues = propertyValues;
					}),
					(error) => console.error(error)
				);
			}
			if (!this.unit.description) {
				this.unitValueReader.readUnitNotes(this.unit).subscribe(
					(notes => {
						this.unit.description = notes;
					}),
					(error) => console.error(error)
				);
			}
		}
	}

	checkIfHasFeatureWeb(): boolean {
		const appView = this.view.appView as AppBaseView;
		return appView.actionKlaraWebAvailable();
	}

	navigateToUnitDetails() {
		if (!this.checkIfHasFeatureWeb())
			return;

		const url = this.view.appView.urlContext() + "/webui/index.html#/unit/" + this.unit.unitID + "/general";
		const w = window.open(url, 'klaraweb');
	}
}