import { Component} from '@angular/core';
import { Observable, forkJoin} from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DoiAction, DoiLabeledValue, DoiModalDialog, DoiModalType, DoiService, DoiObjectPanel, DoiTableEditor } from '../../doi/DoiModule';
import { KlaraObjectPanel } from '../../klara/KlaraModule';

import {ProcessPart, ProcessObject, ProcessActType, ProcessActivity} from '../model/ProcessObject';
import { tap } from 'rxjs/operators';

@Component({ // I18N
	template: `
		<doi-modal-dialog-header [dialog]="this" [title]="title"></doi-modal-dialog-header>
		<div class="modal-body">
			<div class="modal-body-text">
				<p>{{body}}</p>
			</div>
			<div class="modal-body-scroll doi-scroll">
				<div *ngFor="let entry of data">
					<doi-checkbox [(ngModel)]="entry.selected" [ngModelOptions]="{standalone: true}" [edit]="true">{{entry.actType.nameList}}</doi-checkbox>
				</div>
			</div>
		</div>
		<doi-modal-dialog-footer [dialog]="this" [type]="type"></doi-modal-dialog-footer>
	`,
	host: { 'class' : 'doi-modal-dialog' },
	styles: [ `
		.modal-body-scroll {
			height: auto;
			max-height: 10rem;
		}
	` ]
})
export class ProcessObjectActivityActTypesDialog extends DoiModalDialog
{
	data: { selected: boolean; actType: ProcessActType };

	constructor(activeModal: NgbActiveModal)
	{
		super(activeModal);
	}
}

@Component({
	templateUrl: 'ProcessObjectActivitiesPanel.html'
})
export class ProcessObjectActivitiesPanel extends KlaraObjectPanel<ProcessObject>
{
	name = 'activities';

	private activitiesEditor: DoiTableEditor<ProcessObjectActivitiesPanel, ProcessActivity>;

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);

		this.actions.add(
			new DoiAction(this, 'EditActTypes', 'far-pen', 'Koppla handlingstyper')
			.enabledHandler(() => this.editingAsEditor())
			.executeHandler((arg) => this.editActTypes(arg as ProcessActivity))
		);

		this.activitiesEditor = new class extends DoiTableEditor<ProcessObjectActivitiesPanel, ProcessActivity>
		{
			constructor(view: ProcessObjectActivitiesPanel)
			{
				super(view);
			}

			/**
			 * Compare two items for order. Override to compare sequence numbers.
			 */
			compareItems(activity1: ProcessActivity, activity2: ProcessActivity): number
			{
				return activity1.sequence - activity2.sequence;
			}

			/**
			 * Create a new item.
			 */
			createItem(): ProcessActivity
			{
				return new ProcessActivity();
			}

			/**
			 * Return the actual items.
			 */
			items(): ProcessActivity[]
			{
				return this.view.object ? this.view.object.activities : null;
			}

			/**
			 * Test if the specified item is marked deletion.
			 * @param item The item.
			 * @return The delete mark.
			 */
			isItemMarkDeleted(activity: ProcessActivity): boolean
			{
				return activity.objectDelete;
			}

			/**
			 * Mark the specified item for deletion.
			 * Override to propagate to the actual item.
			 * @param item The item.
			 * @param deleteMark The delete mark.
			 */
			markItemDeleted(activity: ProcessActivity, deleteMark: boolean)
			{
				activity.objectDelete = deleteMark;
			}

			/**
			 * Overridden to update sequence numbers.
			 */
			updateItemSequenceNo(activity: ProcessActivity, rowNo: number)
			{
				activity.sequence = rowNo+1;
			}

		}(this);

		this.activitiesEditor.edit(() => this.editingAsEditor());
	}

	/**
	 * Edit connected act types in a dialog.
	 */
	editActTypes(activity: ProcessActivity)
	{
		this.refreshObjectPart(ProcessPart.ActTypes).subscribe(
			(object) => {
				let selectedIDs = new Set<number>();
				if (activity.actTypes) {
					for (let activityActType of activity.actTypes) {
						selectedIDs.add(activityActType.processActTypeID);
					}
				}
				let entries = new Array<{ selected: boolean; actType: ProcessActType }>();
				for (let objectActType of object.actTypes) {
					entries.push({ selected: selectedIDs.has(objectActType.processActTypeID), actType: objectActType });
				}
				this.appView.modalDialog(DoiModalType.OK_CANCEL,
					 "Koppla handlingstyper", "Koppla handlingstyper till "+activity.name,
					 { size: 'lg', backdrop: 'static' },
					 ProcessObjectActivityActTypesDialog, entries).subscribe(
					(result) => {
						if (activity.actTypes)
							activity.actTypes.length = 0;
						else
							activity.actTypes = new Array<ProcessActType>();
						for (let entry of entries) {
							if (entry.selected)
								activity.actTypes.push(entry.actType);
						}
						this.formOnChange(true);
					}
				);
			}
		);
	}

	/**
	 * Start editing the object.
	 */
	editStart()
	{
		super.editStart();
		this.activitiesEditor.clear();
	}

	routerLinkActType(actType : ProcessActType)
	{
		return [ '/processacttype', actType.processActTypeID ];
	}

	routerLinkLinkedProcess(activity : ProcessActivity)
	{
		return [ '/process', activity.linkedProcessID ];
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.Activities)
		);
	}
}
