import {Component} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ProcessActTypePart, ProcessActTypeObject, ProcessActTypeUnitObject, ProcessActTypeSeriesObject} from '../model/ProcessActTypeObject';
import {ProcessActTypeService} from '../model/ProcessActTypeService';


@Component({
	templateUrl: 'ProcessActTypeObjectUnitsPanel.html'
})
export class ProcessActTypeObjectUnitsPanel extends DoiObjectPanel<ProcessActTypeObject>
{
	name = 'units';
	primaryEntities: ProcessActTypeUnitObject[];

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processActTypeUnitIconClass()
	{
		return this.iconClass(ProcessActTypeUnitObject.iconName);
	}

	routerLinkArchiveOrig(unit : ProcessActTypeUnitObject)
	{
		return unit.archiveOrigID ? [ '/archiveorig', unit.archiveOrigID ] : null;
	}

	routerLinkUnit(unit : ProcessActTypeUnitObject)
	{
		return unit.unitID ? [ '/unit', unit.unitID ] : null;
	}

	routerLinkSeries(series: ProcessActTypeSeriesObject)
	{
		return ['/series', series.seriesID];
	}

	routerLinkArchive(series : ProcessActTypeSeriesObject)
	{
		return series.archiveID ? [ '/archive', series.archiveID ] : null;
	}

	openUnit(unit : ProcessActTypeUnitObject)
	{
		this.doi.router.navigate(this.routerLinkUnit(unit));
	}

	openSeries(series : ProcessActTypeSeriesObject)
	{
		this.doi.router.navigate(this.routerLinkSeries(series));
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessActTypePart.Units),
			this.refreshObjectPart(ProcessActTypePart.ProcActTypeSeries));
	}
}
