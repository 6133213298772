import {Component, Input, Output, EventEmitter, forwardRef, HostBinding, ViewChild, ElementRef} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {DoiValueComponent} from './DoiValueComponent';

/**
 * A field component.
 */
@Component({
	selector: 'doi-field',
	host: { 'class' : 'doi-field' },
	template: `
		<div class="form-group" [ngClass]="{'doi-editing':edit, 'doi-not-editing':!edit}">
			<div class="doi-field-header">
				<label *ngIf="labelText()" class="doi-label" for="{{inputId}}" ngbTooltip="{{labelTooltip}}" [tooltipClass]="labelTooltipClass">{{labelText()}}</label>
				<ng-content></ng-content>
			</div>
			<input #fieldInput *ngIf="edit" id="{{inputId}}" class="form-control" name="{{name}}" size="{{size}}" type="{{type}}" [(ngModel)]="inputValue" [readonly]="readonly" [required]="required" [attr.maxLength]="maxLength" (focus)="focusGained($event)" (blur)="focusLost($event)"/>
			<div *ngIf="edit && clearButton" class="clear">
				<a href="" (click)="onClear()"><i *ngIf="edit && clearButton" class="far fa-times"></i></a>
			</div>
			<span *ngIf="!edit" class="doi-field-text" style="{{color ? 'color:'+color+';':''}}" ngbTooltip="{{tooltip}}" placement="top" container="body">
				<span *ngIf="type != 'url'">{{valueText()}}</span>
				<a *ngIf="type == 'url'" [attr.href]="valueText()" target="_blank">{{valueText()}}</a>
			</span>
		</div>
	`,
	styles: [ `
		.doi-field { flex-wrap: nowrap }
		.clear { display: flex; align-items: center; transform: translateX(-1.5em); width: 0; }
		.clear > i { padding: 0.3em; cursor: pointer; color: #666 }
	` ],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DoiFieldComponent),
		multi: true
	}]
})
export class DoiFieldComponent extends DoiValueComponent
{
	/**
	 * The maximum # of characters.
	 */
	@Input() maxLength: number = undefined;

	@Input() inputId: string;

	@Input() clearButton: boolean;

	@Input() labelTooltip: string;
	@Input() labelTooltipClass: string;

	/**
	 * Displayed value text, overriding the formatted value text.
	 */
	@Input() displayText: string;

	/**
	 * Clear button emitter.
	 */
	@Output() clear = new EventEmitter<any>();

	/**
	 * The field input element.
	 */
	@ViewChild('fieldInput')
	fieldInputRef: ElementRef<HTMLInputElement>;

	/**
	 * A safe space character entity.
	 */
	private safeSpace: SafeHtml;

	/**
	 * Construct a new field component.
	 */
	constructor(private datePipe: DatePipe, private sanitizer: DomSanitizer)
	{
		super();

		this.safeSpace = this.sanitizer.bypassSecurityTrustHtml("&nbsp;");
	}

	/**
	 * The input value.
	 */
	get inputValue(): any
	{
		let value = this.value;
		if (value instanceof Date) {
			let ds = value.toISOString();
			switch (this.type) {
				case 'date':
					return ds.split('T')[0];
				default:
					return ds;
			}
		} else {
			switch (this.type) {
				case 'number':
					value = value != null ? parseFloat(String(value)) : null;
			}
			return value;
		}
	}

	set inputValue(value: any)
	{
		if (value) {
			switch (this.type) {
				case 'date':
				case 'datetime-local':
					value = new Date(value);
					break;
				case 'number':
					value = value != null ? parseFloat(String(value)) : null;
					break;
			}
		}
		this.value = value;
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.fieldInputRef) {
			this.fieldInputRef.nativeElement.focus();
			return true;
		}

		return false;
	}

	/**
	 * Return the field input component.
	 */
	fieldInput(): HTMLInputElement
	{
		return this.fieldInputRef ? this.fieldInputRef.nativeElement : null;
	}

	/**
	 * Return the displayed value text.
	 */
	valueText(): any
	{
		if (this.displayText)
			return this.displayText;

		let value = this.value;
		if (value) {
			switch (this.type) {
				case 'date':
					return this.datePipe.transform(value, 'shortDate', undefined, 'sv');
				case 'datetime-local':
					return this.datePipe.transform(value, 'short', undefined, 'sv');
				default:
					return ''+value;
			}
		} else {
			return '';
		}
	}

	onClear()
	{
		this.value = '';
		this.clear.emit();
	}
}
