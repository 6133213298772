import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {HttpClientModule} from '@angular/common/http';
import {ResizableModule} from 'angular-resizable-element';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {environment} from '../environments/environment';

console.log(environment);

registerLocaleData(localeSv);

import {AppService} from './AppService';
import {AppView} from './AppView';

import {DoiModule, DoiService} from './doi/DoiModule';
import {DoiLoginModule} from './doi-login/DoiLoginModule';
import {DoiNavigatorModule} from './doi-navigator/DoiNavigatorModule';
import {DoiSearchModule} from './doi-search/DoiSearchModule';
import {DoiThemeModule} from './doi-theme/DoiThemeModule';

import {StartView} from './start/StartView';
import {LoginView} from './login/LoginView';
import {AccountView} from './login/AccountView';
import {SearchView} from './search/SearchView';
import {SettingsView} from './settings/SettingsView';

const appRoutes: Routes = [
	{path: 'account', component: AccountView},
	{path: 'login', component: LoginView},
	{path: 'search', component: SearchView},
	{path: 'settings', component: SettingsView},
	{path: 'start', component: StartView},
	{path: '', redirectTo: '/start', pathMatch: 'full'},
	{path: '**', component: StartView}
];

import {KlaraModule} from './klara/KlaraModule';
import {ProcessActTypeModule} from './processacttype/ProcessActTypeModule';
import {ProcessModule} from './process/ProcessModule';
import {ProcessStructureModule} from './processstructure/ProcessStructureModule';
import {UnitModule} from './unit/UnitModule';
import {ArchiveOrigModule} from './archiveorig/ArchiveOrigModule';
import {ArchiveModule} from './archive/ArchiveModule';
import {AuditLogEntryModule} from './auditlogentry/AuditLogEntryModule';
import {AuditLogEntryObjectPanel} from './auditlogentry/view/AuditLogEntryObjectPanel';
import {SeriesModule} from './series/SeriesModule';
import {SeriesGroupModule} from './seriesgroup/SeriesGroupModule';
import {SystemInfoModule} from './systeminfo/SystemInfoModule';
import {VolumeModule} from './volume/VolumeModule';

@NgModule({
    imports: [
        BrowserModule, FormsModule, ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        HttpClientModule, ResizableModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        NgbModule,
        DoiModule, DoiLoginModule, DoiNavigatorModule, DoiSearchModule, DoiThemeModule,
        KlaraModule,
        ArchiveOrigModule, ProcessActTypeModule, ProcessModule, ProcessStructureModule, UnitModule,
        ArchiveModule, SeriesModule, SeriesGroupModule, SystemInfoModule, VolumeModule, AuditLogEntryModule,
    ],
    declarations: [
        AppView,
        AccountView, LoginView, SearchView, SettingsView, StartView, AuditLogEntryObjectPanel
    ],
    providers: [
        { provide: DoiService, useClass: AppService },
        { provide: LOCALE_ID, useValue: 'sv' },
        Title,
    ],
    bootstrap: [AppView]
})
export class AppModule {}
