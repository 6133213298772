import {Directive, ViewChild, ElementRef} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {environment} from '../../environments/environment';

import {DoiBookmark, DoiService, DoiAction, DoiTopView} from '../doi/DoiModule';
import {DoiSearchToolComponent} from '../doi-search/DoiSearchModule';
import {AppInfo, KlaraTopView, ObjectFile, DocStoreLink} from '../klara/KlaraModule';
import {SystemInfoObject, SystemInfoService, SystemInfoPart} from '../systeminfo/SystemInfoModule';

import {AppBaseView} from '../AppBaseView';
import {KlaraDialogTopView} from '../klara/KlaraModule';

@Directive()
export abstract class StartBaseView extends KlaraTopView implements KlaraDialogTopView
{
	name = 'StartView';

	visitedList: DoiBookmark[];
	bookmarkList: DoiBookmark[];

	appInfo: AppInfo;

	systemInfoService: SystemInfoService;
	systemInfo: SystemInfoObject;
	references: Array<ObjectFile>;

	@ViewChild('searchTool')
	searchTool: DoiSearchToolComponent;

	development: boolean;

	constructor(doi: DoiService, route: ActivatedRoute, systemInfoService: SystemInfoService)
	{
		super(doi, route);

		this.systemInfoService = systemInfoService;
		this.development = !environment.production;

		this.actions.add(
			new DoiAction(this, 'Search', 'search', 'Sök')
				.executeHandler(() => this.actionSearch()));

		this.formGroup = new FormGroup({
			searchText: new FormControl('', [Validators.required, Validators.minLength(2)])
		});
	}

	ngOnInit()
	{
		super.ngOnInit();

		this.refresh();
	}

	ngOnDestroy()
	{
		super.ngOnDestroy();
	}

	anonymous()
	{
		return !this.doi.auth.isAuthenticated() && !this.doi.auth.isAuthenticating();
	}

	actionSearch()
	{
		this.doi.router.navigate(['/search', {returnUrl: this.appView.lastUrl}]);
	}

	/**
	 * Return the corresponding path in Klara Dialog, or an empty string for the same URL.
	 */
	dialogPath() : string
	{
		return '';
	}

	/**
	 * Return the title.
	 */
	title(): string
	{
		if (this.systemInfo && this.systemInfo.webTitle)
			return this.systemInfo.webTitle;

		let appView = this.appView;
		if (appView)
			return 'Välkommen till '+appView.appTitle();

		return null;
	}

	/**
	 * Return the sub title.
	 */
	subtitle(): string
	{
		if (this.systemInfo && this.systemInfo.webSubTitle)
			return this.systemInfo.webSubTitle;

		return null;
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.systemInfoService.downloadObjectFile(objectFile);
	}

	/**
	 * Focus the first component.
	 */
	focusFirst(): boolean
	{
		if (this.searchTool) {
			this.searchTool.focusFirst();
			return true;
		}

		return super.focusFirst();
	}

	/**
	 * Test if Process is licensed.
	 */
	hasFeatureProcess(): boolean
	{
		return this.appView && this.appView.hasFeatureProcess();
	}

	/**
	 * Invoked by the search tool search action.
	 */
	onSearch(text: string)
	{
		let appView = this.appView as AppBaseView;
		if (appView)
			appView.onSearch(text);
	}

	/**
	 * Refresh the view.
	 */
	refreshView(): Observable<any>
	{
		this.visitedList = this.doi.visitedList().list;
		if (this.doi.auth.isAuthenticated())
			this.bookmarkList = this.doi.bookmarkList(true).list;
		else
			this.bookmarkList = null;

		let appView = this.appView as AppBaseView;
		this.appInfo = appView.appInfo;
		this.systemInfo = appView.systemInfo;

		return forkJoin(this.refreshViewObservables());
	}

	/**
	 * Return the observables to join when refreshing the view.
	 */
	refreshViewObservables(): Observable<any>[]
	{
		return [
			this.systemInfoService.readObjectPart(1, SystemInfoPart.ObjectFiles).pipe(
				tap((object: SystemInfoObject) => {
					//	Exclude JS files, CSS files and empty links.
					this.references = object.objectFiles.filter(f =>
						f.mimeType != 'application/javascript' &&
						f.mimeType != 'text/css' &&
						(f.isFile() || f.filenameLink));
				})
			),
		];
	}


	/**
	 * Test if a search tools should be shown in the application toolbar. Overridden to return false since the view has its own search field.
	 */
	searchToolVisible()
	{
		return false;
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Start'; // I18N
	}
}
