import { Component, Input, Output, EventEmitter, forwardRef, HostBinding, Directive } from '@angular/core';
import {DatePipe} from '@angular/common';
import {FormControl} from '@angular/forms';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

/**
 * Abstract base class for value components.
 */
@Directive()
export abstract class DoiValueComponent implements ControlValueAccessor
{
	/**
	 * The input field type, initially 'text'.
	 */
	@Input() type: string = 'text';
	@Input() name: string;
	@Input() readonly: boolean;
	@Input() required: boolean;
	@Input() edit: boolean;

	/**
	 * Size in characters. Initially 10.
	 */
	@Input() size: number = 10;

	/**
	 * Placeholder text.
	 */
	@Input() placeholder: string = '';

	@Input()
	label: string;

	@Input()
	labelEdit: string;

	_tooltip: string;

	/**
	 * The form control.
	 */
	_formControl: FormControl;

	@Input()
	get formControl(): FormControl
	{
		return this._formControl;
	}

	set formControl(formControl: FormControl)
	{
		this._formControl = formControl;
	}

	@Input()
	get tooltip(): string
	{
		if (this._tooltip)
			return this._tooltip;
		if (this._tooltip == '')
			return null;
		return this.labelEdit || '';
	}
	set tooltip(tooltip: string)
	{
		this._tooltip = tooltip;
	}

	/**
	 * Value change emitter.
	 */
	@Output()
	change = new EventEmitter<any>();

	/**
	 * Focus emitter.
	 */
	@Output("focusGained")
	focusGainedEmitter = new EventEmitter<any>();

	/**
	 * Blur emitter.
	 */
	@Output("focusLost")
	focusLostEmitter = new EventEmitter<any>();

	/**
	 * Indicates if both value and label is null.
	 */
	@HostBinding('class.doi-unlabeled-empty')
	get unlabeledEmpty(): boolean
	{
		return !this.value && !this.label;
	}

	/**
	 * The field value.
	 */
	private _value: any = null;

	/**
	 * The callback function that should be called when the control's value changes in the UI.
	 */
	private onChange = (_: any) => {};

	/**
	 * The callback function that should be called when the control receives a blur event.
	 */
	onTouched = () => {};

	/**
	 * Construct a new value component.
	 */
	constructor()
	{
	}

	/**
	 * Return the label text that should be displayed.
	 */
	labelText(): string
	{
		if (this.edit)
			return this.labelEdit || this.label;

		let text = this.valueText();

		return text && text.trim().length && this.label;
	}

	/**
	 * The component value.
	 */
	get value(): any
	{
		return this._value;
	}

	set value(value: any)
	{
		if (value !== this._value) {
			this._value = value;
			this.valueChanged();
			this.onChange(value);
			this.change.emit(value);
		}
	}

	/**
	 * Invoked when the value has been changed by the user.
	 */
	protected valueChanged()
	{
	}

	/**
	 * Invoked when the focus is gained (blurred).
	 */
	focusGained(event: any)
	{
		this.focusGainedEmitter.emit(event);

		this.onTouched();
	}

	/**
	 * Invoked when the focus is lost (blurred).
	 */
	focusLost(event: any)
	{
		this.focusLostEmitter.emit(event);

		this.onTouched();
	}

	/**
	 * Invoked by the forms API to write to the view
	 */
	writeValue(value: any)
	{
		this._value = value;
	}

	/**
	 * Register a callback function that should be called when the control's value changes in the UI.
	 */
	registerOnChange(fn: (_: any) => void): void
	{
		this.onChange = fn;
	}

	/**
	 * Register a callback function that should be called when the control receives a blur event.
	 */
	registerOnTouched(fn: () => void): void
	{
		this.onTouched = fn;
	}

	/**
	 * Return the displayed value text.
	 */
	abstract valueText(): string;
}
