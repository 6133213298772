import {DoiAppView, DoiObject, DoiObjectView} from '../../doi/DoiModule';

import {AppBaseView} from '../../AppBaseView';
import { ParamMap } from '@angular/router';

/*
 * Abstract base class for object views.
 */
export abstract class KlaraObjectView<T extends DoiObject> extends DoiObjectView<T>
{
	/**
	 * Indicates that the view was opened from Klara Dialog and that the browser should switch back after editing is completed.
	 */
	fromDialog: boolean;

	/**
	 * Reference to the application view.
	 */
	get appView(): AppBaseView { return super.appView as AppBaseView };
	set appView(appView: AppBaseView) { super.appView = appView };

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return this.environment.dialog;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return !this.environment.dialog;
	}

	/**
	 * Attach this view to the application view.
	 */
	attachAppView(appView: DoiAppView): void
	{
		super.attachAppView(appView);

		if (this.isAppDialog()) {
			let bookmarkAction = this.actions.get('Bookmark');
			if (bookmarkAction)
				bookmarkAction.titleHandler(() => this.bookmarkGet() ? 'Ta bort detta bokmärke' : 'Bokmärk denna sida');
		}
	}

	/**
	 * Test if the object may be bookmarked. Overridden to only test if the object exists. An unauthenticated user
	 * can have local bookmarks.
	 */
	bookmarkAllowed(): boolean
	{
		return !!this.objectID;
	}

	/**
	 * Invoked when editing an object has been cancelled or completed.
	 * Overridden to optionally switch back to Dialog after editing in WebUI.
	 */
	editDone()
	{
		super.editDone();

		if (this.fromDialog) {
			let action = this.appView.action('Dialog');
			if (action)
				action.execute();
		}
	}

	/**
	 * Test if the object is currently being edited and the user has at least the Admin role.
	 * Used to open fields that an Editor is allowed to edit, e g process structure description.
	 */
	editingAsAdmin()
	{
		return this.editingWithRole('Admin');
	}

	/**
	 * Test if the object is currently being edited and the user has at least the Editor role, i e 'Editor', 'StructAdm' or 'Admin'.
	 * Used to open fields that an Editor is allowed to edit, e g process description.
	 */
	editingAsEditor()
	{
		return this.editingWithRole('Editor', 'StructAdm', 'Admin');
	}

	/**
	 * Test if the object is currently being edited and the user has at least the Editor or User role, i e 'Editor', 'User', 'StructAdm' or 'Admin'.
	 * Used to open fields that an Editor or User is allowed to edit, e g process act type description.
	 */
	editingAsEditorOrUser()
	{
		return this.editingWithRole('Editor', 'User', 'StructAdm', 'Admin');
	}

	/**
	 * Test if the object is currently being edited and the user has at least the StructAdm role, i e 'StructAdm' or 'Admin'.
	 * Used to open fields that a StructAdm is allowed to edit, e g process act kind description.
	 */
	editingAsStructAdm()
	{
		return this.editingWithRole('StructAdm', 'Admin');
	}

	/**
	 * Test if the object is currently being edited and the user has at least the User role, i e 'User', 'StructAdm' or 'Admin'.
	 * Used to open fields that a User is allowed to edit, e g unit name.
	 */
	editingAsUser()
	{
		return this.editingWithRole('User', 'StructAdm', 'Admin');
	}

	/**
	 * Test if current user has at least AuditObject.
	 *
	 */
	hasRoleAuditObject()
	{
		return this.doi.auth.hasAnyRole('Audit', 'System', 'Admin', 'AuditObject');
	}

	/**
	 * Return the corresponding path in Klara WebUI, null if not available or an empty string for the same URL.
	 * The default implementation returns ''.
	 */
	klaraPath() : string
	{
		return '';
	}

	/**
	 * Invoked when parameters are received.
	 * Overridden to check for 'fromdialog', indicating that the object was opened from Klara Dialog.
	 */
	processParams(pm: ParamMap): void
	{
		super.processParams(pm);

		if (pm.get('fromdialog')) {
			this.fromDialog = true;
		} else {
			this.fromDialog = false;
		}
	}
}
