import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, UrlSegment, UrlSegmentGroup } from '@angular/router';
import {Observable} from 'rxjs';

import {DoiService} from '../service/DoiService';

/**
 * Maps parent component name to the child path element of the child that should be the redirection target.
 * Injected by parent components and updated when a child is activated, to redirect to the same child when the parent is activated.
 */
@Injectable()
export class DoiRedirectTarget
{
	/**
	 * Map of parent component name to child path element.
	 */
	childNames = new Map<string, string>();
}

/**
 * A navigation guard that redirects from a parent component to an child. Used for activating the same child when other instances of the parent is activated.
 */
@Injectable()
export class DoiRedirectGuard 
{
	constructor(private router: Router, private target: DoiRedirectTarget)
	{
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
	{
		if (route.firstChild)
			return true;

		let component = route.component as any;
		let parentName = component['name'];
		if (!parentName)
			return true;

		let subView = this.target.childNames.get(parentName);
		if (!subView)
			subView = route.data.defaultChild;

		if (!subView)
			return true;

		let tree = new UrlTree();
		tree.root = new UrlSegmentGroup(route.url, {});
		tree.queryParams = route.queryParams;

		tree.root.segments.push(new UrlSegment(subView, {}));

		return tree;
	}
}


