<div class="doi-subview doi-scroll">

	<form #form="ngForm" role="form">
		<div class="row" *ngIf="editingAsEditor()">
			<div class="col-12 col-xl-11">
				<doi-field name="nameShort" label="Kortnamn" maxLength="50" [required]="false"  [(ngModel)]="object.nameShort" [edit]="editingAsEditor()"></doi-field>
			</div>
		</div>
		<div class="row" *ngIf="editingAsEditor() || object?.descriptionShort">
			<div class="col-12 col-xl-11">
				 <doi-textarea name="descriptionShort" labelEdit="Kort beskrivning" maxLength="255" rows="4" autosize="true" [(ngModel)]="object.descriptionShort" [edit]="editingAsEditor()"></doi-textarea>
			</div>
		</div>

		<div class="row">
			<div ngClass="col-12">
				<property-values [view]="this" groupTitle="Hantering" groupTitleOther="Övrig hantering" [propertyValues]="propertyValues()" [cellsLG]="4" [edit]="editingAsEditor()"></property-values>
			</div>
		</div>

		<h3 *ngIf="children?.length">Ingående strukturenheter</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="children" [textSupplier]="processText" [classSupplier]="processClass"></div>

		<h3 *ngIf="processActTypes?.length">Handlingstyper</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="processActTypes" moreView="acttypes" [cellsLG]="6" [cellsXL]="4" [classSupplier]="processActTypeClass"></div>

		<h3 *ngIf="object.objectFiles?.length > 0">Referenser</h3>
		<div *ngIf="object.objectFiles?.length > 0" class="row">
			<div class="col-12"><object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files></div>
		</div>

	</form>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->