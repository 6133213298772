import {Router} from '@angular/router';
import {ResizeEvent} from 'angular-resizable-element';

import {DoiAppView, DoiService} from '../../doi/DoiModule';
import {DoiNavNode} from '../model/DoiNavNode';
import {Directive} from "@angular/core";

@Directive()
export class DoiNavigatorAppView extends DoiAppView
{
	name = 'DoiNavigatorAppView';

	navRoot: DoiNavNode;

	constructor(router: Router, doi: DoiService)
	{
		super(router, doi);
	}

	/**
	 * Return the app container classes.
	 * Overridden to add 'doi-navigator-left' or 'doi-navigator-right'.
	 */
	appContainerClasses(): string
	{
		return [ super.appContainerClasses(), 'doi-navigator-'+(this.navigatorRight() ? 'right' : 'left') ].join(' ');
	}

	/**
	 * Stop propagation unless the target id is found in the list of ids for which propagation is required, such as a dropdown.
	 */
	navigatorClickStopUnless(event: Event, ...propagateIds: string[]): boolean
	{
		let stopPropagation = true;

		if (event && event.target && propagateIds) {
			let targetElement = event.target as Element;
			if (propagateIds.indexOf(targetElement.id) != -1) {
				stopPropagation = false;
			} else {
				let parentElement = targetElement.parentElement;
				if (propagateIds.indexOf(parentElement.id) != -1) {
					stopPropagation = false;
				}
			}
		}

		this.doi.log.fine('navigatorClickStopUnless', event, propagateIds, stopPropagation ? 'stopped' : 'continue');

		if (stopPropagation) {
			event.stopPropagation();
			event.preventDefault();
		}

		return stopPropagation;
	}

	/**
	 * Invoked when the navigator is clicked. Conditionally stops propagation to prevent an undocked navigator from being hidden.
	 */
	navigatorClick(event: Event, ...propagateIds: string[])
	{
		this.navigatorClickStopUnless(event, ...propagateIds);

		return true;
	}

	/**
	 * Invoked when the navigator pane is clicked. When undocked, it covers the whole width and a click hides the navigator.
	 */
	navigatorPaneClick(event: Event, ...propagateIds: string[])
	{
		//	If propagation is not stopped, due to click in one of the "propagate" ids, an undocked navigator should remain open.

		if (!this.navigatorClickStopUnless(event, ...propagateIds))
			return true;

		this.navigatorCloseUndocked();

		return true;
	}

	/**
	 * Close the navigator if it is undocked. Should be invoked by actions that are triggered by clicks in the navigator.
	 */
	navigatorCloseUndocked()
	{
		if (!this.settings.navigatorDocked) {
			this.settings.navigator = false;
			this.saveSettings();
		}
	}

	/**
	 * Return the navigator container classes.
	 */
	navigatorContainerClasses(): string
	{
		if (this.settings.navigatorDocked) {
			//	Docked.
			return 'doi-navigator-docked';
		} else {
			//	Undocked.
			if (this.settings.navigator)
				return 'doi-navigator-undocked doi-navigator-popout';
			else
				return 'doi-navigator-undocked doi-navigator-popin';
		}
	}

	/**
	 * Return the navigator pane classes.
	 */
	navigatorPaneClasses(): string
	{
		if (this.settings.navigatorDocked) {
			//	Docked.
			return null;
		} else {
			//	Undocked.
			if (this.settings.navigator)
				return 'doi-navigator-popout';
			else
				return 'doi-navigator-popin';
		}
	}

	/**
	 * Test if the navigator should be on the right side instead of the left.
	 * The default implementation return false for left.
	 */
	navigatorRight(): boolean
	{
		return false;
	}

	/**
	 * Test if the navigator is visible, according to settings.
	 */
	navigatorVisible(): boolean
	{
		return this.settings.navigator;
	}

	/**
	 * Event handler for select events on nodes.
	 */
	navNodeSelected(node: DoiNavNode)
	{
		if (node.pathElement) {
			let subviewName = node.pathElement.subviewName;
			if (!subviewName && this.activeView)
				if (this.activeView.name == node.pathElement.objectType+'ObjectView' && this.activeView.activeSubView)
					subviewName = this.activeView.activeSubView.name;
			this.openObject(node.pathElement.objectType, node.pathElement.objectID, subviewName);
		}

		this.navigatorCloseUndocked();
	}

	/**
	 * Return the edges used for resizing, which is normally 'right'. Used by ResizableModule (angular-resizable-element).
	 * Invokes navigatorRight to test if the edge should be in the left instead.
	 */
	navResizeEdges(): object
	{
		return this.navigatorRight() ? {left: true} : {right: true};
	}

	navResizing(event: ResizeEvent)
	{
		this.navResize(event);
	}

	/**
	 * Invoked when resizing ends. Used by ResizableModule (angular-resizable-element).
	 */
	navResizeEnd(event: ResizeEvent)
	{
		let nw = this.navResize(event);
		this.settings.navigatorWidth = nw;
		this.saveSettings();
	}

	/**
	 * Invoked by navResizeEnd to calculate set the new width, by delegation to navWidth.
	 */
	navResize(event: ResizeEvent): number
	{
		let nw = Math.round(event.rectangle.width);
		this.navWidth(nw);
		return nw;
	}

	/**
	 * Invoked by navResize to set the new width on the element with ID 'navPane'.
	 */
	navWidth(width: number)
	{
		document.getElementById('navPane').style.flex = '0 0 '+width+'px';
	}

	/**
	 * Invoked when a URL has been navigated to.
	 * Overridden to expand the corresponding navigator node.
	 */
	urlNavigated(url: string)
	{
		this.log('urlNavigated', url);

		this.lastUrl = url;

		if (this.navigatorVisible() && this.navRoot) {
			//	Remove query part.
			let p = url.indexOf('?');
			if (p >= 0)
				url = url.substring(0, p);
			p = url.indexOf(';');
			if (p >= 0)
				url = url.substring(0, p);
			//	Find node. If "/start" the node is the root, otherwise find a node by URL path.
			let node = this.urlNavNode(url);
			//	Expand and ensure visible.
			if (node) {
				node.expand();
				let e = document.getElementById(node.id);
				if (e)
					e.scrollIntoView();
			}
		}
	}

	/**
	 * Find the navigator node corresponding to the specified URL. Invoked by urlNavigated when a URL has been navigated to.
	 * The default implementation invokes findByUrl on the navigator root.
	 * Overridde to handle special cases.
	 */
	urlNavNode(url: string): DoiNavNode
	{
		return this.navRoot.findByUrl(url);
	}
}
