<div class="doi-view doi-object-view archiveorig-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start">
			<li doi-breadcrumb text="Struktur" [link]="routerLinkArchiveOrigs()"></li>
			<li doi-breadcrumb *ngIf="object?.parent" [text]="object?.parent?.objectTitle()" [link]="routerLinkParentArchiveOrig()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title>Arkivbildare {{object.isNew() && !object.objectID ? '(ny)' : object.objectTitle()}}</doi-title> <!-- I18N -->
	</doi-view-title>

	<ul doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="details" [iconName]="probedIconName('details')">Detaljer</li>
		<li doi-view-tab name="children"[iconName]="probedIconName('children')">Ingående</li>
		<li *ngIf="!object?.forGrouping" doi-view-tab name="archives" [iconName]="probedIconName('archives')">Arkiv</li>
		<li *ngIf="hasRoleAuditObject()" doi-view-tab name="auditlogentry" [iconName]="probedIconName('auditlogentry')">Logg</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- U+W/VDxCBVrLzq0lrzJq7lr9ykA= -->
