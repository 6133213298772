import {DoiObject, DoiObjectPanel} from '../../doi/DoiModule';

import {AppBaseView} from '../../AppBaseView';
import { KlaraObjectView } from './KlaraObjectView';

/*
 * Abstract base class for object panels.
 */
export abstract class KlaraObjectPanel<T extends DoiObject> extends DoiObjectPanel<T>
{
	/**
	 * Reference to the application view.
	 */
	get appView(): AppBaseView { return super.appView as AppBaseView };
	set appView(appView: AppBaseView) { super.appView = appView };

	/**
	 * The parent object view.
	 */
	get parentObjectView(): KlaraObjectView<T> { return super.parentObjectView as KlaraObjectView<T>; };

	/**
	 * Test if the object is currently being edited and the user has at least the Admin role.
	 * Used to open fields that an Editor is allowed to edit, e g process structure description.
	 */
	editingAsAdmin()
	{
		let view = this.parentObjectView;
		return view ? view.editingAsAdmin() : false;
	}

	/**
	 * Test if the object is currently being edited and the user has at least the Editor role, i e 'Editor', 'StructAdm' or 'Admin'.
	 * Used to open fields that an Editor is allowed to edit, e g process description.
	 */
	editingAsEditor()
	{
		let view = this.parentObjectView;
		return view ? view.editingAsEditor() : false;
	}

	/**
	 * Test if the object is currently being edited and the user has at least the Editor or User role, i e 'Editor', 'User', 'StructAdm' or 'Admin'.
	 * Used to open fields that an Editor or User is allowed to edit, e g process act type description.
	 */
	editingAsEditorOrUser()
	{
		let view = this.parentObjectView;
		return view ? view.editingAsEditorOrUser() : false;
	}

	/**
	 * Test if the object is currently being edited and the user has at least the StructAdm role, i e 'StructAdm' or 'Admin'.
	 * Used to open fields that a StructAdm is allowed to edit, e g process act kind description.
	 */
	editingAsStructAdm()
	{
		let view = this.parentObjectView;
		return view ? view.editingAsStructAdm() : false;
	}

	/**
	 * Test if the object is currently being edited and the user has at least the User role, i e 'User', 'StructAdm' or 'Admin'.
	 * Used to open fields that a User is allowed to edit, e g unit name.
	 */
	editingAsUser()
	{
		let view = this.parentObjectView;
		return view ? view.editingAsUser() : false;
	}

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return this.environment.dialog;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return !this.environment.dialog;
	}
}
