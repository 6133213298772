<div class="doi-subview doi-scroll">

	<form #form="ngForm" role="form">
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-textarea name="Name" label="Namn" labelEdit="Namn" size="60"  maxLength="100" rows="2"[(ngModel)]="object.nameML" [edit]="editingAsUser()"></doi-textarea>
			</div>
		</div>
		<div class="row" *ngIf="editing() || object?.description">
			<div class="col-12 col-xl-11">
				<doi-textarea name = "Beskrivning" label="Beskrivning" labelEdit="Beskrivning" size="60" [(ngModel)]="object.description" [edit]="editingAsUser()"></doi-textarea>
			</div>
		</div>
		<property-values [view]="this" [propertyValues]="propertyValues()" [edit]="editingAsUser()"></property-values>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="Tidsperiod" label="Tidsperiod" [(ngModel)]="object.validPeriod" ></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="date" name="GiltigFrom" label="Giltig fr o m" [(ngModel)]="object.dateBegin" ></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="date" name="GiltigTom" label="Giltig t o m" [(ngModel)]="object.dateEnd" ></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-field name="AltNames" label="Publika namn" size="60" [(ngModel)]="object.altNameList"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<doi-checkfield name="Sekretess" label="Sekretess" [(ngModel)]="object.confidential"></doi-checkfield>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="SekretessTid" label="Tidsperiod, sekretess" labelEdit="Tidsperiod, sekretess" size="60" [displayText]="ctuText()" [(ngModel)]="object.confidentialTimeUnit"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="Gallringsfrist" label="Gallringsfrist" labelEdit="Gallringsfrist" size="60" [displayText]="cpuText()" [(ngModel)]="object.cullPeriodTimeUnit"></doi-field>
			</div>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
