<div class="doi-view doi-object-view process-object-view" [ngClass]="object?.processTypeName" [ngClass]="object?.processTypeName + ' ' + (!object?.active ? 'object-inactive' : '')">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start" [homeIcon]="false" [tailImplied]="false" #focusFirst>
			<li *ngIf="object?.parent?.parent" doi-breadcrumb [text]="object?.parent?.parent?.objectName()" [link]="routerLinkParentProcess()"></li>
			<li *ngIf="object?.parent" doi-breadcrumb [text]="object?.parent?.objectName()" [link]="routerLinkParentProcess()"></li>
			<li doi-breadcrumb [text]="object?.objectName()"></li>
		</ol>
		<ul doi-toolbar>
		</ul>
	</doi-view-title>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- 5PWD65q2c0Mu+qy1LaO48A5KlJQ= -->
