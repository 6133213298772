import { Input } from '@angular/core';
import { DoiDragSource, DoiObjectRef, DoiTreeNode } from '../../doi/DoiModule';

/**
 * A navigators node.
 */
export class DoiNavNode extends DoiTreeNode<DoiNavNode, DoiObjectRef>
{
	/**
	 * The target URL.
	 */
	url: string;

	/**
	 * Invoked on drag start. Checks if draggable.
	 */
	private _dragStartHandler: (event: DragEvent) => string;

	/**
	 * Invoked on drag leave.
	 */
	private _dragLeaveHandler: (event: DragEvent) => void;

	/**
	 * Invoked on drag over. Checks if droppable and may adjust drop order.
	 */
	private _dragOverHandler: (event: DragEvent, value: any, dropOrder: number) => number;

	/**
	 * Invoked on drop.
	 */
	private _dropHandler: (event: DragEvent, value: any, dropOrder: number) => void;

	/**
	 * Construct a new node.
	 */
	constructor(label?: string, url?: string)
	{
		super(label);
		this.url = url;
		this.parent = null;
	}

	/**
	 * Invoked on drag start. Delegates to the drag handler.
	 */
	dragStart = (event: DragEvent): string =>
	{
		if (this._dragStartHandler)
			return this._dragStartHandler(event);
		else
			return null;
	}

	/**
	 * Invoked on drag leave.  Delegates to the drag leave handler.
	 */
	dragLeave = (event: DragEvent): void =>
	{
		if (this._dragLeaveHandler)
			this._dragLeaveHandler(event);
	}

	/**
	 * Invoked on drag over. Delegates to the drag over handler.
	 */
	dragOver = (event: DragEvent, value: any, dropOrder: number): number =>
	{
		if (this._dragOverHandler)
			return this._dragOverHandler(event, value, dropOrder);
		else
			return 0;
	}

	/**
	 * Invoked on drop. Delegates to the drop handler.
	 */
	drop = (event: DragEvent, value: any, dropOrder: number): void =>
	{
		if (this._dragOverHandler)
			this._dropHandler(event, value, dropOrder);
	}

	/**
	 * Test if this node has the same label, url and object reference as the specified node.
	 */
	equals(node: DoiNavNode): boolean
	{
		if (this.url != node.url)
			return false;
		return super.equals(node);
	}

	/**
	 * Test if the specified object references has the same type and ID.
	 */
	equalsPathElement(element1: DoiObjectRef, element2: DoiObjectRef): boolean
	{
		return DoiObjectRef.equal(element1, element2);
	}

	/**
	 * Find a node by the URL, recursively.
	 */
	findByUrl(url: string): DoiNavNode
	{
		if (!url)
			return null;

		if (this.url) {
			if (this.url == url)
				return this;
			let p1 = this.url.indexOf('(');
			let p2 = url.indexOf('(');
			if (p1 != -1 || p2 != -1) {
				let u1 = (p1 != -1) ? this.url.substring(0, p1) : this.url;
				let u2 = (p2 != -1) ? url.substring(0, p2): url;
				if (u1 == u2)
					return this;
			}
		}

		if (this.children) {
			for (let child of this.children) {
				let node = child.findByUrl(url);
				if (node)
					return node;
			}
		}

		return null;
	}

	/**
	 * Find an immediate child by the URL.
	 */
	findChildByUrl(url: string): DoiNavNode
	{
		if (this.children) {
			for (let child of this.children) {
				if (child.url == url)
					return child;
			}
		}

		return null;
	}

	/**
	 * Return the node class. If a class has been explicitly set, it is returned. Otherwise, if the node has an
	 * object reference,  'doi-nav-node-Xxx' is returned, where Xxx is the object type.
	 */
	nodeClass(): string
	{
		let nc = super.nodeClass();
		if (nc)
			return nc;
		let pe = this.getPathElement();
		if (pe)
			return 'doi-nav-node-'+pe.objectType+(pe.subviewName ? '-'+pe.subviewName : '');
		return undefined;
	}

	/**
	 * Set the function that is invoked on drag start.
	 */
	setDragStartHandler(dragStartHandler: (event: DragEvent) => string): DoiNavNode
	{
		this._dragStartHandler = dragStartHandler;
		return this;
	}

	/**
	 * Set the function that is invoked on drag leave.
	 */
	setDragLeaveHandler(dragLeaveHandler: (event: DragEvent) => void): DoiNavNode
	{
		this._dragLeaveHandler = dragLeaveHandler;
		return this;
	}

	/**
	 * Set the function that is invoked on drag over.
	 */
	setDragOverHandler(dragOverHandler: (event: DragEvent, value: any, dropOrder: number) => number): DoiNavNode
	{
		this._dragOverHandler = dragOverHandler;
		return this;
	}

	/**
	 * Set the function that is invoked on drag start, drag over and drop.
	 */
	setDropHandler(dropHandler: (event: DragEvent, value: any, dropOrder: number) => void): DoiNavNode
	{
		this._dropHandler = dropHandler;
		return this;
	}

	/**
	 * Associate this node with an object and optionally an URL.
	 */
	setObjectRef(objectRef: DoiObjectRef, url?:string): DoiNavNode
	{
		this.setPathElement(objectRef);
		if (url !== undefined)
			this.url = url;
		return this;
	}
}
