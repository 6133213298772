import {HostListener, Directive, Input, HostBinding} from '@angular/core';

/**
 * Applied to components that can be the source of drag and drop.
 */
@Directive({
    selector: '[doi-drag-source]',
	host: { 'class' : 'doi-drag-source' },
})
export class DoiDragSource
{
	/**
	 * Invoked on drag start. Checks if draggable and returns a drag value if draggable. Dragged object uses object references, e g "Page/51".
	 */
	@Input()
	dragStart: (event: DragEvent) => string;
	/**
	 * Invoked on drag end.
	 */
	@Input()
	dragEnd: (event: DragEvent) => void;

	/**
	 * Indicates if the component is currently being dragged. Bound to the class 'doi-drag-source-active'.
	 */
	dragging: boolean;

	@HostBinding('class.doi-drag-source-active')
	private get dragSourceClass(): boolean
	{
		return this.dragging;
	}

	/**
	 * Invoked on drag.
	 */
    @HostListener('drag', ['$event'])
	onDrag(event: DragEvent)
	{
	}

	/**
	 * Invoked on drag end.
	 */
    @HostListener('dragend', ['$event'])
	onDragEnd(event: DragEvent)
	{
		if (this.dragEnd)
			this.dragEnd(event);

		this.dragging = false;
	}

	/**
	 * Invoked on drag start.
	 */
    @HostListener('dragstart', ['$event'])
	onDragStart(event: DragEvent)
	{
		var value: string;
		if (this.dragStart)
			value = this.dragStart(event);
		else
			value = null;

		if (value == null) {
			event.preventDefault();
			return false;
		}

		if (value != null)
			event.dataTransfer.setData('text/plain', value);

		this.dragging = true;

		return true;
	}

	/**
	 * Invoked on drop.
	 */
    @HostListener('drop', ['$event'])
	onDrop(event: DragEvent)
	{
		event.preventDefault();
	}
}
