import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {ArchiveOrigObject} from '../../archiveorig/ArchiveOrigModule';
import {PropertyValue, ObjectFile, DocStoreLink, PublishLevel} from '../../klara/KlaraModule';

/**
 *	Object part names for the Archive entity object.
 */
export const ArchivePart =
{
	Label : 'Label',
	General : 'General',
	History : 'History',
	LabelText : 'LabelText',
	Notes : 'Notes',
	ObjectFiles : 'ObjectFiles',
	Depots : 'Depots',
	PropertyValues : "PropertyValues",
	Series : 'Series',
	Extents : 'Extents',
	Subjects: 'Subjects',
	Acts: 'Acts',
	ObjectIntegrations: "ObjectIntegrations",

}

/**
 *	The Archive entity object.
 */
export class ArchiveObject extends DoiObject
{
	static readonly iconName = 'warehouse-full';

	static readonly condText = ['Okänt','Nej','Delvis','Ja'];

	/**
	 *	Attribute values.
	 */
	name: string;
	archiveInstID: number;
	archiveInstName: string;
	archiveOrigID: number;
	archiveOrigName: string
	archiveTypeID: number;
	archiveTypeName: string;
	accessRestrict: number;
	accessRestrictText: string;
	altFormAvail: number;
	altFormAvailCondText: string;
	bulkDateBegin: string;
	bulkDateEnd: string;
	countryCode: string;
	dateBegin: string;
	dateEnd: string;
	history: string;
	labelText: string;
	latestNote: string;
	notes: string;
	onLocation: number;
	onLocationCondText: string;
	openFrom: Date;
	openTo: Date;
	processOriented: boolean;
	publish: PublishLevel;
	refCode: string;
	regDate: Date;
	sequence: number;
	signumPrint: number;
	signumTreeStrict: boolean;

	depots: Array<Depot>;
	objectFiles: Array<ObjectFile>;
	propertyValues: Array<PropertyValue>;
	series: Array<ArchiveSeries>;
	extents: Array<Extent>;
	subjects: Array<Subject>;
	acts: Array<Act>;
	docStoreLinks: Array<DocStoreLink>;

	/**
	 *	Parent.
	 */
	archiveOrig: ArchiveOrigObject;

	/**
	 * Construct a new Archive object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ArchiveObject.iconName;
	}

	/**
	 * The symbolic object type name, i e 'Archive'.
	 */
	get objectType(): string
	{
		return 'Archive';
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return notes.
	 */
	objectText(): string
	{
		return this.notes;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.name;
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		if (this.archiveOrig) {
			let path = this.archiveOrig.objectRefPath();
			if (path)
				path.push(this.objectRef());
			return path;
		}

		return null;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): ArchiveObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, ArchivePart.Label);
				//	Additional search result entry information.
				if (data.Archive_Notes)
					this.notes = data.Archive_Notes;
				break;
			case ArchivePart.Label:
				this.objectID = data.Archive_ArchiveID;
				this.name = data.Archive_Name;
				this.processOriented = data.Archive_ProcessOriented;
				this.archiveTypeID = data.Archive_ArchiveTypeID;
				this.archiveTypeName = data.ArchiveType_Name;
				this.archiveOrigName = data.ArchiveOrig_Name;
				this.archiveOrigID = data.Archive_ArchiveOrigID;
				this.signumTreeStrict = data.ArchiveType_SignumTreeStrict;
				break;
			case ArchivePart.General:
				this.parseData(data, ArchivePart.Label);
				this.accessRestrict = data.Archive_AccessRestrict;
				this.accessRestrictText = this.accessRestrict != null ? ArchiveObject.condText[this.accessRestrict] : null;
				this.altFormAvail = data.Archive_AltFormAvail;
				this.altFormAvailCondText = this.altFormAvail != null ? ArchiveObject.condText[this.altFormAvail] : null;
				this.archiveInstName = data.ArchiveInst_Name;
				this.bulkDateBegin = data.Archive_BulkDateBegin;
				this.bulkDateEnd = data.Archive_BulkDateEnd;
				this.countryCode = data.Archive_CountryCode;
				this.dateBegin = data.Archive_DateBegin;
				this.dateEnd = data.Archive_DateEnd;
				this.latestNote = data.Archive_LatestNote;
				this.onLocation = data.Archive_OnLocation;
				this.onLocationCondText = this.onLocation != null ? ArchiveObject.condText[this.onLocation] : null;
				this.openFrom = data.Archive_OpenFrom ? new Date(data.Archive_OpenFrom) : null;
				this.openTo = data.Archive_OpenTo ? new Date(data.Archive_OpenTo) : null;
				this.publish = data.Archive_Publish;
				this.refCode = data.Archive_RefCode;
				this.regDate = data.Archive_RegDate ? new Date(data.Archive_RegDate) : null;
				this.sequence = data.Archive_Sequence;
				this.signumPrint = data.Archive_SignumPrint;
				break;
			case ArchivePart.History:
				this.history = data.Archive_History;
				break;
			case ArchivePart.LabelText:
				this.labelText = data.Archive_LabelText;
				break;
			case ArchivePart.Notes:
				this.notes = data.Archive_Notes;
				break;
			case ArchivePart.Depots:
				this.depots = new Array<Depot>();
				if (data.Archive_Depots) {
					for (let e of data.Archive_Depots) {
						let depot = new Depot();
						depot.parseData(e);
						this.depots.push(depot);
					}
				}
				break;
			case ArchivePart.PropertyValues:
				this.propertyValues = new Array<PropertyValue>();
				if (data.Archive_PropertyValues) {
					for (let e of data.Archive_PropertyValues) {
						this.propertyValues.push(new PropertyValue().parseData(e));
					}
				}
				break;
			case ArchivePart.Series:
				this.series = new Array<ArchiveSeries>();
				if (data.Archive_Series) {
					for (let e of data.Archive_Series) {
						this.series.push(new ArchiveSeries().parseData(e));
					}
				}
				break;
			case ArchivePart.Extents:
				this.extents = new Array<Extent>();
				if (data.Archive_Extents) {
					for (let e of data.Archive_Extents) {
						let extent = new Extent();
						extent.parseData(e);
						this.extents.push(extent);
					}
				}
				break;
			case ArchivePart.Subjects:
				this.subjects = new Array<Subject>();
				if (data.Archive_Subjects) {
					for (let e of data.Archive_Subjects) {
						let subject = new Subject();
						subject.parseData(e);
						this.subjects.push(subject);
					}
				}
				break;
			case ArchivePart.Acts:
				this.acts = new Array<Act>();
				if (data.Archive_Acts) {
					for (let e of data.Archive_Acts) {
						let act = new Act();
						act.parseData(e);
						this.acts.push(act);
					}
				}
				break;
			case ArchivePart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.Archive_ObjectFiles) {
					for (let e of data.Archive_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;

		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case ArchivePart.General:
				return {
					Archive_ObjectDelete: this.objectDelete,
					Archive_Name: this.name,
					Archive_AccessRestrict: this.accessRestrict,
					Archive_AltFormAvail: this.altFormAvail,
					Archive_BulkDateBegin: this.bulkDateBegin,
					Archive_BulkDateEnd: this.bulkDateEnd,
					Archive_CountryCode: this.countryCode,
					Archive_DateBegin: this.dateBegin,
					Archive_DateEnd: this.dateEnd,
					Archive_LatestNote: this.latestNote,
					Archive_OnLocation: this.onLocation,
					Archive_OpenFrom: this.openFrom ? this.openFrom.toISOString() : null,
					Archive_OpenTo: this.openTo ? this.openTo.toISOString() : null,
					Archive_ProcessOriented: this.processOriented,
					Archive_Publish: this.publish,
					Archive_RefCode: this.refCode,
					Archive_RegDate: this.regDate ? this.regDate.toISOString() : null,
					Archive_Sequence: this.sequence,
					Archive_SignumPrint: this.signumPrint,
				}
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.archiveOrig && this.archiveOrig.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}

}

/**
 * A Depot, corresponding to a detail object from Archive part Series.
 */
export class ArchiveSeries
{
	static readonly iconName = 'inventory';

	seriesID: number;
	title: string;
	signum: string;
	location: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ArchiveSeries
	{
		this.seriesID = data.Series_SeriesID;
		this.title = data.Series_Title;
		this.signum = data.Series_SignumFmt;
		this.location = data.Series_Location;

		return this;
	}
}

/**
 * A Act, corresponding to a detail object from Archive part Acts.
 */
export class Act
{
	static readonly iconName = 'circle';

	actID: number;
	actTypeName: string;
	actTypeCode: string;
	notes: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Act
	{
		this.actID = data.Act_ActID;
		this.actTypeName = data.ActType_Name;
		this.actTypeCode = data.ActType_Code;
		this.notes = data.Act_Notes;

		return this;
	}
}

/**
 * A Depot, corresponding to a detail object from Archive part Depots.
 */
export class Depot
{
	static readonly iconName = 'circle';

	depotID: number;
	name: string;
	code: string;
	notes: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Depot
	{
		this.depotID = data.DepotArchive_DepotID;
		this.name = data.Depot_Name;
		this.code = data.Depot_Code;
		this.notes = data.DepotArchive_Notes;

		return this;
	}
}

/**
 * An Subject, corresponding to a detail object from Archive part Subjects.
 */
export class Subject
{
	static readonly iconName = 'circle';

	subjectID: number;
	subjectTypeID: number;
	subjectTypeName: string;
	text: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Subject
	{
		this.subjectID = data.Subject_SubjectID;
		this.subjectTypeID = data.SubjectType_SubjectTypeID;
		this.subjectTypeName = data.SubjectType_Name;
		this.text = data.Subject_Text;

		return this;
	}
}

/**
 * An Extent, corresponding to a detail object from Archive part Extents.
 */
export class Extent
{
	static readonly iconName = 'circle';

	extentID: number;
	extentUnitID: number;
	extentUnitName: string;
	value: number;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Extent
	{
		this.extentID = data.Extent_ExtentID;
		this.extentUnitID = data.Extent_ExtentUnitID;
		this.extentUnitName = data.ExtentUnit_Name;
		this.value = data.Extent_Value;

		return this;
	}
}

// SGxNP19qDiwniOMbFjZE7/YfUio=
