import {DoiObject} from '../../doi/DoiModule';

/**
 *	The AuditLogEntry entity object.
 */
export class AuditLogEntryValue
{
	name: string;
	attribute: string;
	change: string;
	valueOld: any;
	valueNew: any;
	lookupSchemaName: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): AuditLogEntryValue
	{
		this.change = data.change;
		this.name = data.Name;
		this.attribute = data.Attribute;
		this.valueOld = data.ValueOld;
		this.valueNew = data.ValueNew;
		this.lookupSchemaName = data.LookupSchemaName;
		return this;
	}
}

// Fn7klSmXDHk+HL8B5+SAAyjbn6k=
