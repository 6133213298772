<div class="doi-view doi-object-view processacttype-object-view" [ngClass]="!object?.active ? 'object-inactive' : ''">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start" [tailImplied]="true">
			<li doi-breadcrumb link="/archiveorigs" text="Struktur"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchiveOrig()" [text]="object?.process?.processStructure?.archiveOrig?.objectTitle()" [link]="routerLinkArchiveOrig()"></li>
			<li doi-breadcrumb *ngIf="routerLinkProcessStructure()" [text]="object?.process?.processStructure?.objectTitle()" [link]="routerLinkProcessStructure()"></li>
			<li doi-breadcrumb *ngIf="routerLinkProcess() && object?.process?.parent" [text]="object?.process?.parent?.objectName()" [link]="routerLinkProcess()"></li>
			<li doi-breadcrumb *ngIf="routerLinkProcess()" [text]="object?.process?.objectName()" [link]="routerLinkProcess()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title><span class="title-object-type">Handlingstyp</span><span class="title-object-name">{{object.isNew() && !object.objectID ? '(ny)' : object.objectTitle()}}</span></doi-title> <!-- I18N -->
	</doi-view-title>

	<ul doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="objectPanelIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="detail" [iconName]="objectPanelIconName('detail')">Detaljer</li>
		<li doi-view-tab name="units"[iconName]="objectPanelIconName('units')">Förvaras i</li>
		<li *ngIf="hasRoleAuditObject()" doi-view-tab name="auditlogentry"[iconName]="objectPanelIconName('auditlogentry')">Logg</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- 3zPW60A5uroUy+EN6l7G7UNNqbQ= -->
