import { DoiObject } from "../../doi/DoiModule";

/**
 *	An entity object with date range attributes.
 */
export interface DateRangeObject extends DoiObject
{
	/**
	 *	Attribute values.
	 */
	date: string;
	dateBegin: string;
	dateEnd: string;
}
