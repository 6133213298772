import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule}   from '@angular/forms';

import {DoiModule} from '../doi/DoiModule';

import {DateRangeComponent} from './view/DateRangeComponent';
import {DescriptionComponent} from './view/DescriptionComponent';
import {DocStoreLinkComponent} from './view/DocStoreLinkComponent';
import {DocStoreLinksComponent} from './view/DocStoreLinksComponent';
import {ObjectFileComponent} from './view/ObjectFileComponent';
import {ObjectFilesComponent} from './view/ObjectFilesComponent';
import {PropertyValueComponent} from './view/PropertyValueComponent';
import {PropertyValueHBComponent} from './view/PropertyValueHBComponent';
import {PropertyValueRevertComponent} from './view/PropertyValueRevertComponent';
import {PropertyValuesComponent} from './view/PropertyValuesComponent';
import {UnitComponent} from './view/UnitComponent';

export * from './model/AppInfo';
export * from './model/DateRangeObject';
export * from './model/DocStoreLink';
export * from './model/Feature';
export * from './model/KlaraBrokerService';
export * from './model/ObjectFile';
export * from './model/PropertyValue';
export * from './model/PropertyValueGroup';
export * from './model/PropertyValueList';
export * from './model/PublishLevel';
export * from './model/RoleName';
export * from './model/Unit';
export * from './view/DateRangeComponent';
export * from './view/KlaraDialogTopView';
export * from './view/KlaraObjectPanel';
export * from './view/KlaraObjectView';
export * from './view/KlaraTopView';
export * from './view/UnitComponent';

@NgModule({
	imports: [
		BrowserModule, FormsModule, DoiModule
	],
	declarations: [
		DescriptionComponent, DocStoreLinkComponent, DocStoreLinksComponent,
		ObjectFileComponent, ObjectFilesComponent,
		PropertyValueComponent, PropertyValueHBComponent, PropertyValueRevertComponent, PropertyValuesComponent,
		UnitComponent, DateRangeComponent,
	],
	exports: [
		DescriptionComponent, DocStoreLinkComponent, DocStoreLinksComponent,
		ObjectFileComponent, ObjectFilesComponent,
		PropertyValueComponent, PropertyValueHBComponent, PropertyValuesComponent,
		UnitComponent, DateRangeComponent,
	],
	providers: [
	],

})
export class KlaraModule
{
}

// /au4cZflo5cj302pZV1qLVc8Iso=
