import {HttpParams, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, of, EMPTY} from 'rxjs';
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";

import {DoiObjectCache} from './DoiObjectCache';
import {DoiService} from './DoiService';
import {DoiSelectionCriteria} from '../service/DoiSelectionCriteria';
import {DoiObject} from './DoiObject';
import { DoiApiVersion } from './DoiApiVersion';

/**
 * A service that manages entity object of a specified type.
 */
export abstract class DoiBrokerService<T extends DoiObject>
{
	/**
	 * The DOI service.
	 */
	doi: DoiService;

	/**
	 * The symbolic name.
	 */
	name: string;

	/**
	 * The default API version.
	 */
	apiVersion: DoiApiVersion;

	/**
	 * Map of API version suffixes to API version information objects.
	 */
	apiVersionMap = new Map<any, DoiApiVersion>();

	cache: DoiObjectCache<T>;

	constructor(doi: DoiService, name: string, context?: string)
	{
		this.doi = doi;
		this.name = name;

		this.addApiVersion(new DoiApiVersion('', context || name), true);

		this.cache = doi.objectCache(name);
	}

	/**
	 * Add an API version and optionally set it as the default.
	 * @param defaultVersion Use the specified version as the default.
	 */
	addApiVersion(apiVersion: DoiApiVersion, defaultVersion = false)
	{
		this.apiVersionMap.set(apiVersion.version, apiVersion);

		if (defaultVersion)
			this.apiVersion = apiVersion;
	}

	/**
	 * Return the service context for an API version.
	 * @param version The version suffix. Often an empty string for first version, representing version 1.
	 */
	apiContext(version: any = this.apiVersion.version)
	{
		const apiVersion : DoiApiVersion = this.apiVersionMap.get(version);
		if (apiVersion)
			return apiVersion.context;
		else
			return this.apiVersion.context;
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return undefined;
	}

	/**
	 * Return the base url for this service. Constructed from the base API context (e g 'apis') and service context (e g 'project').
	 * The base API context is fetched from the DOI Auth service URL context, which is usually either 'apip' or 'apis' depending on
	 * if the user is logged in. An API version suffix may be appended to the base API context, e g '2' for 'apis2'.
	 */
	url(version: any = this.apiVersion.version)
	{
		return this.doi.auth.urlContext(version) + '/' + this.apiContext(version);
	}

	/**
	 * Return the public base url for this service. Constructed from the base API context (e g 'apip') and service context (e g 'project').
	 * The public base API context is fetched from the DOI Auth service URL context, which is usually 'apip'.
	 * An API version suffix may be appended to the base API context, e g '2' for 'apip2'.
	 */
	urlPub(version: any = this.apiVersion.version)
	{
		return this.doi.auth.urlContextPub(version)  + '/' + this.apiContext(version);
	}

	/**
	 * Handle an HTTP error. Delegates to the DOI service.
	 */
	handleError(error: HttpErrorResponse): Observable<any>
	{
		return this.doi.handleError(error);
	}

	/**
	 * Return a cached object or create a new object for refresh.
	 */
	initObject(objectID: number): T
	{
		if (objectID) {
			let cachedObject = this.cache.get(objectID);
			if (cachedObject)
				return cachedObject;
		}

		return this.newObject(objectID);
	}

	/**
	 * Create a new object.
	 */
	abstract newObject(objectID: number): T;

	/**
	 * Return the path element for an object ID.
	 */
	pathObjectID(objectID: number): string
	{
		return objectID ? objectID.toString() : this.apiVersion.pathPaceholderID;
	}

	/**
	 * Parse a response containing an array of response object. A response object can be null, due to count limit. In this case a null object is added to the result array.
	 */
	parseObjects(response: any, partName: string): T[]
	{
		let list = new Array<T>();
		for (let r of response) {
			if (r) {
				let object = this.newObject(null);
				object.parseData(r, partName);
				this.objectReceived(partName, object);
				list.push(object);
			} else {
				list.push(null);
			}
		}
		return list;
	}

	/**
	 * Parse a response containing a JSON object.
	 */
	parseObject(response: any, objectID: number, partName: string, object?: T): T
	{
		if (!object)
			object = this.newObject(objectID);
		object.parseData(response, partName);
		object.hasParts.add(partName);
		this.objectReceived(partName, object);
		return object;
	}

	/**
	 * Parse a response containing a probe flag.
	 */
	parseObjectProbe(response: any, objectID: number, partName: string, object?: T): T
	{
		if (!object)
			object = this.newObject(objectID);
		object.partProbes.set(partName, response[partName]);
		return object;
	}

	/**
	 * Test if the user is allowed to create new objects. The default implementation returns false. Override to
	 * e g check role memberships.
	 */
	permitNew(): boolean
	{
		return false;
	}

	/**
	 * Invoked after an object has been received from the server and parsed.
	 * The default implementation does nothing.
	 */
	objectReceived(partName: string, object: T): T
	{
		return object;
	}

	/**
	 * Probe an object part.
	 */
	probeObjectPart(objectID: number, partName: string, object?: T, options?: any): Observable<T>
	{
		if (!objectID)
			return EMPTY;

		let showError = true;

		if (options) {
			if (options.showError === false) {
				showError = false;
				options.showError = undefined;
			}

		} else {
			options = undefined;
		}

		let observable = this.doi.http.get(this.url() + '/probe/' + objectID + '/' + partName + '/'+this.name+partName+'Probe.json', options).pipe(
			tap(response =>
				this.doi.log.fine('probeObjectPart: '+objectID+'/'+partName, response)
			),
			map(response =>
				this.parseObjectProbe(response, objectID, partName, object)),
			tap((object: T) => {
				if (objectID)
					this.cache.put(objectID, object);
				}
			)
		);

		if (showError) {
			observable = observable.pipe(catchError((error: HttpErrorResponse) => this.handleError(error)))
		}

		return observable;
	}

	/**
	 * Read a selection of objects. If the criteria sets a count limit, the result array will have an extra null entry if the limit was exceeded.
	 */
	readObjectSelection(params?: HttpParams, cr?: DoiSelectionCriteria): Observable<T[]>
	{
		if (cr) {
			return this.doi.http.post(this.url() + '/selection/'+this.name+'Selection.json', cr, { params: params }).pipe(
				map(response =>
					this.parseObjects(response, null)),
				catchError((error: HttpErrorResponse) =>
					this.handleError(error))
			);
		} else {
			return this.doi.http.get(this.url() + '/selection/'+this.name+'Selection.json', { params: params }).pipe(
				map(response =>
					this.parseObjects(response, null)),
				catchError((error: HttpErrorResponse) =>
					this.handleError(error))
			);
		}
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(objectID: number, partName: string, object?: T, options?: any): Observable<T>
	{
		let showError = true;

		if (options) {
			if (options.showError === false) {
				showError = false;
				options.showError = undefined;
			}

		} else {
			options = undefined;
		}

		let observable = this.doi.http.get(this.url() + '/object/' + this.pathObjectID(objectID) + '/' + partName + '/'+this.name+partName+'.json', options).pipe(
			tap(response =>
				this.doi.log.fine('readObjectPart: '+objectID+'/'+partName, response)
			),
			map(response =>
				this.parseObject(response, objectID, partName, object)),
			tap((object: T) => {
				if (objectID)
					this.cache.put(objectID, object);
				}
			)
		);

		if (showError) {
			observable = observable.pipe(catchError((error: HttpErrorResponse) => this.handleError(error)))
		}

		return observable;
	}

	/**
	 * Delete an object.
	 */
	deleteObject(objectID: number, object: T): Observable<number>
	{
		return this.doi.http.delete(this.url() + '/object/' + this.pathObjectID(objectID) + '/'+this.name+'.json').pipe(
			map((response: any) => {
				return response.ID;
			}),
			catchError((error: HttpErrorResponse) =>
				this.handleError(error))
		);
	}

	/**
	 * Write a new or existing object.
	 */
	writeObject(objectID: number, object: T, cleanParts: any): Observable<number>
	{
		let objectParts = object.buildDataParts();

		let dirtyParts = objectID ? this.changes(objectParts, cleanParts) : objectParts;
		if (dirtyParts == null)
			dirtyParts = {};

		return this.doi.http.post(this.url() + '/object/' + this.pathObjectID(objectID) + '/'+this.name+'.json', dirtyParts).pipe(
			map((response: any) => {
				return response.objectID || response.ID;
			}),
			catchError((error: HttpErrorResponse) =>
				this.handleError(error))
		);
	}

	/**
	 * Compare two values recursively and return only changes. Empty strings are replaced with null and Sets are converted to arrays.
	 */
	changedValue(dirtyValue: any, cleanValue: any): { anyChanges: boolean, value: any }
	{
		let value = dirtyValue;

		if (dirtyValue instanceof Array) {
			let changedArray = null;
			if (!cleanValue || dirtyValue.length != cleanValue.length)
				changedArray = new Array();
			for (let key in dirtyValue) {
				let dirtyValueEntry = dirtyValue[key];
				let cleanValueEntry = cleanValue && cleanValue[key];
				if (dirtyValueEntry) {
					let change = this.changedValue(dirtyValueEntry, cleanValueEntry);
					if (change.anyChanges) {
						if (!changedArray)
							changedArray = new Array();
						if (changedArray)
							changedArray.push(change.value);
					}
				}
			}
			if (changedArray)
				return { anyChanges: true, value: changedArray };
			else
				return { anyChanges: false, value: cleanValue };
		} else if (dirtyValue instanceof Set) {
			let dirtyEntries = [...dirtyValue];
			let cleanSet = cleanValue as Set<any>;
			if (!cleanSet || dirtyEntries.length != cleanSet.size)
				return { anyChanges: true, value: dirtyEntries };
			if (!dirtyEntries.every((v) => cleanSet.has(v)))
				return { anyChanges: true, value: dirtyEntries };
			else
				return { anyChanges: false, value: [...cleanValue] };
		} else if (dirtyValue instanceof Object) {
			let changedObject = this.changes(dirtyValue, cleanValue);
			if (changedObject)
				return { anyChanges: true, value: changedObject };
			else
				return { anyChanges: false, value: cleanValue };
		} else {
			if (typeof value == 'string') {
				let s = value.trim();
				if (s.length == 0)
					s = null;
				dirtyValue = s;
			}
			if (cleanValue !== dirtyValue) {
				return { anyChanges: true, value: dirtyValue };
			} else {
				return { anyChanges: false, value: dirtyValue };
			}
		}
	}

	/**
	 * Compare the contents of two objects recursively and return only changes. Empty strings are replaced with null.
	 * Invoked with data objects built from object values using buildDataPart.
	 */
	changes(dirtyData: any, cleanData: any): any
	{
		let changedData: {[attr: string]: any} = {};
		let anyChanges = false;

		let forceData: {[attr: string]: any} = {};

		for (let attr in dirtyData) {

			if (this.changesIgnoreAttribute(attr))
				continue;

			let dirtyValue = dirtyData[attr];
			let cleanValue = cleanData && cleanData[attr];

			let changedValue = this.changedValue(dirtyValue, cleanValue);
			if (changedValue.anyChanges) {
				changedData[attr] = changedValue.value;
				anyChanges = true;
			}

			if (!changedValue.anyChanges) {
				if (this.changesForceAttribute(attr)) {
					forceData[attr] = cleanValue;
					anyChanges = true;
				}
			}

		}

		if (anyChanges) {
			Object.assign(changedData, forceData);
		}

		return anyChanges ? changedData : null;
	}

	/**
	 * Check if an attribute should be included in changed data even if unchanged, provided some other attribute in the same data object
	 * is changed. Normally used to force inclusion of key attributes.
	 * The default implementation returns false. Override to handle key attributes and possibly other attributes.
	 */
	changesForceAttribute(attr : string)
	{
		let bwc = this.changesKeyAttribute(attr);
		if (bwc != null)
			return bwc;
		return false;
	}

	/**
	 * Check if an attribute should be ignored when checking for changes.
	 * The default implementation returns true for attributes ending with '$';
	 */
	changesIgnoreAttribute(attr : string)
	{
		return attr.lastIndexOf('$', 0) == 0;
	}

	/**
	 * For BWC. May be overridden by old applications. Invoked by changesForceAttribute.
	 */
	changesKeyAttribute(attr : string)
	{
		return null;
	}
}
