import {PropertyValue} from './PropertyValue';

/**
 * A filtered list of property values. Handles an object level list and optionally a parent list. Combines the lists based on filtering rules.
 * A fixed rule is set with the constructor: Filtered on "handling instructions", i e includes only property values with the handlInst attribute.
 * A dynamic rule is specified when building/fetching the combined list: An edited list ignores parents and includes properties without values.
 */
export class PropertyValueList
{
	/**
	 * An empty array.
	 */
	static readonly	empty = new Array<PropertyValue>();

	/**
	 * Indicates if this list is always filtered on "handling instructions".
	 */
	handlInst: boolean;

	/**
	 * Result after combining and filtering.
	 */
	resultPropertyValues : PropertyValue[] = PropertyValueList.empty;

	/**
	 * Source property values and filtering rules.
	 */
	objectPropertyValues : PropertyValue[];
	parentPropertyValues : PropertyValue[];
	editing: boolean;
	editingAll: boolean;

	/**
	 * A filtered list of property values.
	 * @param handlInst Indicates if this list is always filtered on "handling instructions".
	 */
	constructor(handlInst: boolean)
	{
		this.handlInst = handlInst;
	}

	/**
	 * Build and return a list of property values combining one or two sources with filtering.
	 * @param objectPropertyValues The object property values.
	 * @param parentPropertyValues The parent object property values, or null.
	 * @param editing Indicates if the object is beeing edited. Used to include nulls and exclude restricted.
	 * @param editingAll Indicates if restricted properties should be included, not just the ones flagged for editor.
	 */
	propertyValues(objectPropertyValues: PropertyValue[], parentPropertyValues: PropertyValue[], editing?: boolean, editingAll?: boolean) : PropertyValue[]
	{
		//	In edit mode, parent values are ignored.

		if (editing)
			parentPropertyValues = null;

		//	Check if a filtered list can be reused. Identity comparision is OK for the arrays since they are always recreated when fetched from server.

		if (this.resultPropertyValues &&
			this.objectPropertyValues === objectPropertyValues &&
			this.parentPropertyValues === parentPropertyValues &&
			this.editing == editing && this.editingAll == editingAll) {
			return this.resultPropertyValues;
		}

		//	console.log('Rebuild propertyValues');

		//	Create a new filtered list.

		this.resultPropertyValues = PropertyValueList.empty;

		if (objectPropertyValues != null || parentPropertyValues != null) {

			this.resultPropertyValues = new Array<PropertyValue>();
			let addedPropTypeIDs = new Set<number>();

			if (objectPropertyValues != null) {
				for (let t of objectPropertyValues) {
					if (this.handlInst == null || this.handlInst == t.handlInst) {
						addedPropTypeIDs.add(t.propTypeID);
						//	Skip if the property is restricted (not flagged for Editor) and the role disallowes editing all.
						if (editing && !editingAll && !t.editor)
							continue;
						//	Skip if not editing and there is no value.
						if (!editing && !t.value)
							continue;
						this.resultPropertyValues.push(t);
					}
				}
			}

			if (parentPropertyValues != null) {
				for (let t of parentPropertyValues) {
					if (this.handlInst == null || this.handlInst == t.handlInst) {
						if (!addedPropTypeIDs.has(t.propTypeID) && (editing || t.value))
							this.resultPropertyValues.push(t);
					}
				}
			}

			//	Ensure that all distinct group titles use the same group sequence.
			let groupTitleSequences = new Map<String, number>();
			for (let pv of this.resultPropertyValues) {
				if (pv.groupSequence) {
					let gs = groupTitleSequences.get(pv.groupTitle);
					if (gs)
						pv.groupSequence = gs;
					else
						groupTitleSequences.set(pv.groupTitle, pv.groupSequence);
				}
			}

			PropertyValue.sort(this.resultPropertyValues);

		} else {

			this.resultPropertyValues = PropertyValueList.empty;

		}

		this.objectPropertyValues = objectPropertyValues;
		this.parentPropertyValues = parentPropertyValues;
		this.editing = editing;
		this.editingAll = editingAll;

		return this.resultPropertyValues;
	}
}