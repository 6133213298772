<div class="doi-subview">
	<div *ngIf="auditLogEntries" class="doi-scroll-expandable-container">
		<table>
			<thead>
				<tr>
					<th class="doi-wrap">Loggad</th>
					<th *ngIf="hasFullAccess" class="doi-wrap">Användare</th>
					<th class="doi-wrap">Operation</th>
					<th *ngIf="hasFullAccess" class="doi-wrap">Övrig information</th>
				</tr>
			</thead>
		<tbody class="outer-table-body">
			<ng-container *ngFor="let entry of auditLogEntries; let i = index">
				<tr (click)="toggleRow(i)" class="pointer-cursor" [ngClass]="{'selected-row': expandedRows[i]}">
					<td>
						<i class="doi-nav-node-exp fas"
						[ngClass]="{'fa-caret-right': !expandedRows[i], 'fa-caret-down': expandedRows[i]}">
						</i>
                    {{ entry.loggedWhen|date:'short':'':'sv' }}
					</td>
					<td *ngIf="hasFullAccess">{{entry.loggedByName}}</td>
					<td>
						{{
							entry.operation == "Write" ? "Ändring"
							: entry.operation == "Create" ? "Ny"
							: entry.operation == "Okänt"
						}}
					</td>
					<td *ngIf="hasFullAccess">{{entry.message? entry.message : '(behörighet saknas)'}}</td>
				</tr>
            <tr *ngIf="expandedRows[i]" class="selected-row-expanded-content">
                <td>
					<table class="inner-table-toggable">
                        <thead>
                            <tr>
                                <th>
									<span aria-hidden="true" class="doi-tool-icon fas fa-check hidden-icon"></span>
								</th>
                                <th>Egenskap</th>
                                <th>Tidigare värde</th>
                                <th>Värde</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let entryDetails of entry.valueTable">
                                <th scope="row">
									<span *ngIf="entryDetails.change == 'Delete'" aria-hidden="true" class="doi-tool-icon fas fa-trash"></span>
									<span *ngIf="entryDetails.change == 'Insert'" aria-hidden="true" class="doi-tool-icon fas fa-plus"></span>
									<span *ngIf="entryDetails.change == 'Edit'" aria-hidden="true" class="doi-tool-icon fas fa-check"></span>
								</th>
                                <td>{{entryDetails.name}}</td>
                                <td>{{entryDetails.valueOld}}</td>
                                <td>{{entryDetails.valueNew}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>
	</div>
</div>