import {Component, Input, Output, EventEmitter} from '@angular/core';

import {DoiObjectView} from '../view/DoiObjectView'

/**
 * Toolbar for editing an object.
 * Dropdown items, selector [doi-dropdown-item], for different new-actions can be provided in the content, or the actionNew property can be set to true for a single new-action.
 * Additional edit tools can be provided in the content with selector [doi-tool].
 */
@Component({
	selector: 'doi-object-edit-tools',
	template: `
		<li doi-tool *ngIf="objectView.canEditNew()" dropdown="true" title="Skapa" [target]="objectView" icon="fas-plus">
			<ul doi-dropdown-menu class="dropdown-menu-right">
				<li *ngIf="actionNew" doi-dropdown-item [target]="objectView" [action]="actionNew"></li>
				<ng-content *ngIf="!actionNew" select="[doi-dropdown-item]"></ng-content>
			</ul>
		</li>
		<li doi-tool *ngIf="objectView.canEdit()" [target]="objectView" action="Edit"></li>
		<li doi-tool *ngIf="objectView.canEditSave()" [target]="objectView" action="EditSave"></li>
		<ng-content select="[doi-tool]"></ng-content>
		<li doi-tool *ngIf="objectView.canEditCancel()" [target]="objectView" action="EditCancel"></li>
  `, // I18N
})
export class DoiObjectEditToolsComponent
{
	/**
	 * Input and output properties.
	 */
	@Input() objectView: DoiObjectView<any>;

	/**
	 * Simple New action name. If set, a singe New action is provided in the Create menu, otherwise dropdown items must be provided in the content.
	 */
	@Input() actionNew: string;

	/**
	 * Construct a new component.
	 */
	constructor()
	{
	}
}
