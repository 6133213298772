<div class="doi-subview">
	<div *ngIf="object?.actTypes && object?.actTypes?.length > 0" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-3">Handlingstyp</th>
					<th class="doi-grow-1 doi-hidden-xs-down">Period</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let acttype of object?.actTypes" (click)="openActtype(acttype)" class="doi-clickable" [ngClass]="processActTypeClass(acttype)">
					<td class="doi-grow-3 doi-wrap"><a [routerLink]="routerLinkActtype(acttype)" class="object-link">{{acttype.nameList}}</a></td>
					<td class="doi-grow-1 doi-hidden-xs-down">{{acttype.validPeriod}}</td>
					<td>&nbsp;</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>