import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {EMPTY, Observable, of} from 'rxjs';

import {DoiIconMapper, DoiLabeledValue, DoiView} from '../../doi/DoiModule';

import {DateRangeObject} from '../model/DateRangeObject';

/*
 * A Date range component.
 */
@Component({
	selector: 'date-range',
	template: `
		<doi-field name="dateBegin" label="Tid från" size="10" class="dateBegin" [(ngModel)]="object.dateBegin" maxLength="10" [edit]=edit (focusLost)="focusLostBE($event)" (change)="valueChanged($event)"></doi-field>
		<doi-field name="dateEnd" label="Tid till" size="10" class="dateEnd" [(ngModel)]="object.dateEnd" maxLength="10" [edit]=edit (focusLost)="focusLostBE($event)" (change)="valueChanged($event)"></doi-field>
		<doi-field name="date" label="Tid" size="20" class="date" [(ngModel)]="object.date" maxLength="30" [edit]=edit (focusLost)="focusLostDate($event)" (change)="valueChanged($event)"></doi-field>
	`,
	host: { 'class' : 'date-range' },
})
export class DateRangeComponent
{
	/**
	 * The containing view.
	 */
	@Input()
	view: DoiView;

	/**
	 * The Date Range object.
	 */
	@Input()
	object: DateRangeObject;

	/**
	 * Indicates if the component is being edited.
	 */
	@Input()
	edit: boolean;

	/**
	 * Value change emitter.
	 */
	@Output()
	change = new EventEmitter<any>();

	/**
	 ** Regexp for valid date format
	 */
	private dateRegExpYear = new RegExp('(\\d{4})\\s*');
	private dateRegExp = new RegExp('(\\d{4})((-\\d{2})?(-\\d{2})?)--');

	/**
	 * Invoked when a field changes. Flags the object and emits a change event.
	 */
	valueChanged(value: any)
	{
		this.change.next(this.object);

		if (this.view)
			this.view.formOnChange(true);
	}

	/**
	 * Invoked when the focus is lost
	 */
	focusLostBE(value: any)
	{
		if ((this.object.date == null || this.object.date == "") || this.isDateMatch(this.object.date)) {

			if (this.isDateMatch(this.object.dateBegin) && this.isDateMatch(this.object.dateEnd)) {
				if (this.object.dateBegin != null && this.object.dateEnd != null) {
					if (this.object.dateBegin == this.object.dateEnd)
						this.object.date = this.object.dateBegin;
					else
						this.object.date = this.object.dateBegin + "--" + this.object.dateEnd;
				}
				else if (this.object.dateBegin != null)
					this.object.date = this.object.dateBegin + "--";
				else if (this.object.dateEnd != null)
					this.object.date = "--" + this.object.dateEnd;
				else
					this.object.date = null;

			}
		}
	}

	/**
	 * Invoked when the focus is lost
	 */
	focusLostDate(value: any)
	{

		if (this.object.date.match(this.dateRegExp)) {
			let s = this.object.date.split('--');
			if (s == null) {
				this.object.dateBegin = this.object.date;
				this.object.dateEnd = this.object.date;
			} else {
				this.object.dateBegin = s[0];
				this.object.dateEnd = s[1];
			}
		} else if (this.object.date.match(this.dateRegExpYear)) {
			this.object.dateBegin = this.object.date;
			this.object.dateEnd = this.object.date;
		}

	}

	/**
	 * Check if date match pattern
	 */
	isDateMatch(value: any): boolean
	{
		if (value.match(this.dateRegExp) || value.match(this.dateRegExpYear))
			return true;
		else
			return false;
	}
}
