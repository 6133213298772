/**
 * Publish levels.
 * Used on requests to indicate the requested level, based on client and/or role.
 * Used on objects to indicate the required requested level for the object to be visible to the user.
 */
export enum PublishLevel
{
	/**
	 * Not published.
	 * As a requested level this will return all objects, even object with No as object level
	 * As an object level this will return the object only if the requested level is null or No.
	 */
	No,

	/**
	 * Published internally, i e for authenticated users.
	 */
	Internal,

	/**
	 * Published externally, i e for unauthenticated users.
	 */
	External
}