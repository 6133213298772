import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import { DoiTableEditor } from '../core/DoiTableEditor';
import { DoiView } from '../view/DoiView';

@Component({
	selector: 'doi-row-header',
	host: { 'class' : 'doi-checkbox' },
	template: `
		<a href="" (click)="onClick($event)">
			<i class="doi-checkbox-box fas fa-check"></i>
		</a>
	`
})
export class DoiRowHeaderComponent
{
	/**
	 * The table editor controller.
	 */
	@Input()
	controller: DoiTableEditor<DoiView, any>;

	/**
	 * The 0-based row number, or null/undefined for the upper left corner "all" header.
	 */
	@Input()
	rowNo: number;

	/**
	 * Space key modifier detection.
	 */
	private spaceKeyTimestamp: number;
	private spaceKeyCtrl: boolean;
	private spaceKeyShift: boolean;

	/**
	 * Test if this row is the selection anchor. Delegates to the controller, isRowSelected.
	 */
	@HostBinding('class.anchor')
	get anchor(): boolean
	{
		if (this.controller)
			return this.controller.isRowAnchor(this.rowNo);
	}

	/**
	 * Test if this row is checked. Delegates to the controller, isRowSelected.
	 */
	@HostBinding('class.selected')
	get checked(): boolean
	{
		if (this.controller)
			return this.controller.isRowSelected(this.rowNo);
	}

	/**
	 * Handle key up events. Checks for Ctrl and Shift. Notes timestamp and modifiers.
	 */
	@HostListener('document:keyup', ['$event'])
	handleKeyupEvent(event: KeyboardEvent)
	{
		this.spaceKeyTimestamp = null;

		if (event.key == ' ') {
			//	Capture time and modifiers.
			if (event.ctrlKey || event.shiftKey) {
				this.spaceKeyTimestamp = event.timeStamp;
				this.spaceKeyCtrl = event.ctrlKey;
				this.spaceKeyShift = event.shiftKey;
			}
		}
	}

	/**
	 * Invoked on click. Delegates to the controller, setRowSelected.
	 */
	onClick(event: Event): void
	{
		event.preventDefault();
		event.stopPropagation();
		document.getSelection().removeAllRanges();

		//	Check event modifiers.

		let ctrlKey = false;
		let shiftKey = false;

		if (event instanceof MouseEvent) {
			ctrlKey = event.ctrlKey;
			shiftKey = event.shiftKey;
		}

		//	If no event modifiers, check for preceding space key.

		if (!ctrlKey && !shiftKey) {
			if (this.spaceKeyTimestamp != null && (event.timeStamp - this.spaceKeyTimestamp < 100)) {
				ctrlKey = this.spaceKeyCtrl;
				shiftKey = this.spaceKeyShift;
			}
		}

		let anchor = false;
		let extend = false;

		if (shiftKey) {
			// console.log("Extend many from anchor, to anchor selection state");
			extend = true;
		} else if (ctrlKey) {
			// console.log("Extend single, set anchor");
			anchor = true;
			extend = true;
		} else {
			// console.log("Toggle single, set anchor, clear other");
			anchor = true;
		}

		if (this.controller)
			return this.controller.selectRow(this.rowNo, null, anchor, extend);
	}

	/**
	 * Return the component form name to use. Fetches a base name from the controller, rowHeaderBaseName, and appends the row number.
	 */
	name()
	{
		if (this.controller)
			return this.controller.rowHeaderBaseName+(this.rowNo != null ? this.rowNo : '');
	}
}
