<div class="doi-subview">

	<form #form="ngForm" class="h-100" role="form">
		<div *ngIf="editingAsEditor() || (object?.activities && object?.activities?.length > 0)" class="h-100 doi-scroll-container">
			<table class="table table-striped table-hover" [ngClass]="activitiesEditor.tableClasses()">
				<thead>
					<tr *ngIf="editingAsEditor()">
						<th colspan="5" class="doi-table-toolbar">
							<ul doi-toolbar>
								<doi-table-edit-tools [controller]="activitiesEditor"/>
							</ul>
						</th>
					</tr>
					<tr>
						<th class="doi-table-row-header" *ngIf="editingAsEditor()"><doi-row-header [controller]="activitiesEditor"/></th>
						<th class="doi-grow-2">Aktivitet</th>
						<th class="doi-grow-3">Beskrivning</th>
						<th class="doi-grow-3 doi-hidden-xs-down ps-3">Handlingstyper</th>
						<th class="doi-grow-3 doi-hidden-xs-down">Hänvisning till</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let activity of activitiesEditor.itemsOrdered(); let ai = index" [ngClass]="activitiesEditor.rowClasses(ai)" [id]="activitiesEditor.rowId(ai)" (click)="activitiesEditor.editRow(ai)">
						<td *ngIf="editingAsEditor()" class="doi-table-row-header"><doi-row-header [controller]="activitiesEditor" [rowNo]="ai"/></td>
						<td class="doi-grow-2 doi-wrap">
							<doi-textarea name="nameML{{ai}}" maxLength="100" label="" [autosize]="true" [required]="true" [(ngModel)]="activity.nameML" [edit]="activitiesEditor.editingRow(ai) && editingAsEditor()" [ngClass]="{delete:activity.objectDelete}" [readonly]="activity.objectDelete"></doi-textarea>
						</td>
						<td class="doi-grow-3 doi-line-breaks pb-3">
							<doi-textarea name="notes{{ai}}" label="" [autosize]="true" [rows]="10" [(ngModel)]="activity.notes" [edit]="activitiesEditor.editingRow(ai) && editingAsEditor()" [ngClass]="{delete:activity.objectDelete}" [readonly]="activity.objectDelete"></doi-textarea>
						</td>
						<td class="doi-grow-3 doi-wrap doi-hidden-xs-down ps-3 align-items-start justify-content-between"><!-- Alt:  flex-column -->
							<ul class="cell-list" *ngIf="!editingAsEditor()">
								<li *ngFor="let actType of activity.actTypes"><a [routerLink]="routerLinkActType(actType)">{{actType.nameList}}</a></li>
							</ul>
							<ul class="cell-list" *ngIf="editingAsEditor()">
								<li *ngFor="let actType of activity.actTypes">{{actType.nameList}}</li>
							</ul>
							<ul doi-toolbar *ngIf="editingAsEditor()">
								<li doi-tool [target]="this" action="EditActTypes" [arg]="activity" [compact]="true"></li>
							</ul>
						</td>
						<td class="doi-grow-3 doi-wrap doi-hidden-xs-down">
							<a [routerLink]="routerLinkLinkedProcess(activity)" *ngIf="activity.linkedProcessID">{{activity.linkedProcessCode}} - {{activity.linkedProcessName}}</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</form>
</div>