import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {ArchiveOrigObject} from '../../archiveorig/ArchiveOrigModule';
import {PropertyValue, ObjectFile, PublishLevel} from '../../klara/KlaraModule';

/**
 *	Object part names for the ProcessStructure entity object.
 */
export const ProcessStructurePart =
{
	Label : 'Label',
	General : 'General',
	Description : 'Description',
	Processes : 'Processes',
	ProcessesTop : 'ProcessesTop',
	ObjectFiles : "ObjectFiles",
	PropertyValues : "PropertyValues",
}

/**
 * A Child, corresponding to a detail object from Project part children.
 */
export class ProcessStructureProcessObject
{
	static readonly iconName = 'rectangle-landscape';

	processID: number;
	code: string;
	name: string;
	nameShort: string;
	nameList: string;
	parentProcessID: number;
	processTypeName: string;
	dateBegin: Date;
	dateEnd: Date;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ProcessStructureProcessObject
	{
		this.processID = data.Process_ProcessID;
		this.code = data.Process_Code;
		this.name = data.Process_Name;
		this.nameShort = data.Process_NameShort;
		this.nameList = data.Process_NameList;
		this.processTypeName = data.Process_processTypeName;
		this.dateBegin = data.Process_DateBegin;
		this.dateEnd = data.Process_DateEnd;
		return this;
	}
}


/**
 *	The ProcessStructure entity object.
 */
export class ProcessStructureObject extends DoiObject
{
	static readonly iconName = 'sitemap';

	/**
	 *	Attribute values.
	 */
	name: string;
	archiveOrigID: number;
	prevProcStructID: number;
	description: string;
	endDate: Date;
	objectUUID: any;
	publish: PublishLevel;
	startDate: Date;
	versionNo: number;
	hasNewVersions: boolean;
	prevName: string;
	prevVerArchOrigID: number;
	prevVersionNo: number;

	/**
	 *	Parent.
	 */
	archiveOrig: ArchiveOrigObject;

	propertyValues: Array<PropertyValue>;
	objectFiles: Array<ObjectFile>;
	processes: Array<ProcessStructureProcessObject>;
	processesTop: Array<ProcessStructureProcessObject>;

	/**
	 * Construct a new ProcessStructure object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessStructureObject.iconName;
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return the description.
	 */
	objectText(): string
	{
		return this.description;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.name;
	}

	/**
	 * The symbolic object type name, i e 'ProcessStructure'.
	 */
	get objectType(): string
	{
		return 'ProcessStructure';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		let path: DoiObjectRef[] = null;

		if (this.archiveOrig) {
			path = this.archiveOrig.objectRefPath();
			path.push(this.objectRef());
			return path;
		}

		path = new Array<DoiObjectRef>();

		path.push(this.objectRef());

		return path;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): ProcessStructureObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, ProcessStructurePart.Label);
				//	Additional search result entry information.
				if (data.ProcessStructure_Description)
					this.description = data.ProcessStructure_Description;
				break;
			case ProcessStructurePart.Label:
				this.objectID = data.ProcessStructure_ProcStructID;
				this.name = data.ProcessStructure_Name;
				this.archiveOrigID = data.ProcessStructure_ArchiveOrigID;
				this.objectUUID = data.ProcessStructure_ObjectUUID;
				this.versionNo = data.ProcessStructure_VersionNo;
				break;
			case ProcessStructurePart.General:
				this.parseData(data, ProcessStructurePart.Label);
				this.endDate = data.ProcessStructure_EndDate ? new Date(data.ProcessStructure_EndDate) : null;
				this.publish = data.ProcessStructure_Publish;
				this.startDate = data.ProcessStructure_StartDate ? new Date(data.ProcessStructure_StartDate) : null;
				this.hasNewVersions = data.ProcessStructure_HasNewVersions;
				this.prevName = data.ProcessStructure_PrevName;
				this.prevVerArchOrigID = data.ProcessStructure_PrevVerArchOrigID;
				this.prevVersionNo = data.ProcessStructure_PrevVersionNo;
				break;
			case ProcessStructurePart.Description:
				this.description = data.ProcessStructure_Description;
				break;
			case ProcessStructurePart.PropertyValues:
				this.propertyValues = new Array<PropertyValue>();
				if (data.ProcessStructure_PropertyValues) {
					for (let e of data.ProcessStructure_PropertyValues) {
						this.propertyValues.push(new PropertyValue().parseData(e));
					}
				}
				break;
			case ProcessStructurePart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.ProcessStructure_ObjectFiles) {
					for (let e of data.ProcessStructure_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;
			case ProcessStructurePart.Processes:
				this.processes = new Array<ProcessStructureProcessObject>();
				if (data.ProcessStructure_Processes) {
					for (let e of data.ProcessStructure_Processes) {
						let process = new ProcessStructureProcessObject();
						process.parseData(e);
						this.processes.push(process);
					}
				}
				break;
			case ProcessStructurePart.ProcessesTop:
				this.processesTop = new Array<ProcessStructureProcessObject>();
				if (data.ProcessStructure_ProcessesTop) {
					for (let e of data.ProcessStructure_ProcessesTop) {
						let process = new ProcessStructureProcessObject();
						process.parseData(e);
						this.processesTop.push(process);
					}
				}
				break;
		}

		return this;
	}
	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case ProcessStructurePart.General:
				return {
					ProcessStructure_ObjectDelete: this.objectDelete,
					ProcessStructure_Name: this.name,
					ProcessStructure_ArchiveOrigID: this.archiveOrigID,
					ProcessStructure_EndDate: this.endDate ? this.endDate.toISOString() : null,
					ProcessStructure_ObjectUUID: this.objectUUID,
					ProcessStructure_Publish: this.publish,
					ProcessStructure_StartDate: this.startDate ? this.startDate.toISOString() : null,
					ProcessStructure_VersionNo: this.versionNo,
					ProcessStructure_HasNewVersions: this.hasNewVersions,
					ProcessStructure_PrevName: this.prevName,
					ProcessStructure_PrevVerArchOrigID: this.prevVerArchOrigID,
					ProcessStructure_PrevVersionNo: this.prevVersionNo,
				}
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.archiveOrig && this.archiveOrig.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}
}

// zod7SUolffq+EzTHmwbfemtBglo=
