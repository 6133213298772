import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService} from '../../doi/DoiModule';
import {KlaraObjectPanel} from '../../klara/KlaraModule';
import {AuditLogEntryService} from '../../auditlogentry/AuditLogEntryModule';
import {AuditLogEntryObject, AuditLogEntryPart} from '../../auditlogentry/model/AuditLogEntryObject';
import {tap} from 'rxjs/operators';


@Component({
    selector: 'auditlogentry-component',
	templateUrl: 'AuditLogEntryObjectPanel.html'
})
export class AuditLogEntryObjectPanel extends KlaraObjectPanel<AuditLogEntryObject>
{
	name = 'auditlogentry';
	auditLogEntries: AuditLogEntryObject[];
	auditLogEntryService: AuditLogEntryService;
	hasFullAccess: boolean;
	expandedRows: { [key: number]: boolean } = {};

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
		this.auditLogEntryService = this.doi.brokerService('AuditLogEntry') as AuditLogEntryService;
	}

	toggleRow(index: number): void
	{
		this.expandedRows[index] = !this.expandedRows[index];

		if (this.expandedRows[index])
		{
			let entry = this.auditLogEntries[index];
			if (!entry.valueTable)
				this.auditLogEntryService.readObjectPart(
					entry.auditLogEntryID,
					AuditLogEntryPart.General,
					entry
				).subscribe();
		}
	}

	readAuditLogEntries(): Observable<AuditLogEntryObject[]>
	{
		return this.auditLogEntryService.readAuditLogEntries(this.object);
    }

	refreshView(): Observable<any>
	{
		this.hasFullAccess = this.doi.auth.hasAnyRole('User', 'Audit', 'Admin', 'System');
		return forkJoin(this.refreshViewObservables());
	}

	refreshViewObservables(): Observable<any>[]
	{
		let observables = new Array<Observable<any>>();

		observables.push(
			this.readAuditLogEntries().pipe(
				tap((auditLogEntries: AuditLogEntryObject[]) => {
					this.auditLogEntries = auditLogEntries;
					this.expandedRows = {};
				}))
		);
		return observables;
	}
}
