import {DoiObject} from '../../doi/DoiModule';
import {AuditLogEntryValue} from './AuditLogEntryValue';

/**
 *	Object part names for the AuditLogEntry entity object.
 */
export const AuditLogEntryPart =
{
	Label : 'Label',
	General : 'General',
}

/**
 *	The AuditLogEntry entity object.
 */
export class AuditLogEntryObject extends DoiObject
{
	static readonly iconName = 'box';

	/**
	 *	Attribute values.
	 */
	 auditLogEntryID: number;
	 logFile: string;
	 logSequence: number;
	 loggedByUserID: number;
	 loggedWhen: string;
	 message: string;
	 objectID: number;
	 objectType: string;
	 operation: string;
	 loggedByUserName: string;
	 loggedByName: string;
	 valueTable: AuditLogEntryValue[];

	/**
	 * Construct a new AuditLogEntry object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return AuditLogEntryObject.iconName;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): AuditLogEntryObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, AuditLogEntryPart.Label);
				//	Additional search result entry information.
				if (data.AuditLogEntry_Title)
					this.logFile = data.AuditLogEntry_Title;
				break;
			case AuditLogEntryPart.Label:
				this.auditLogEntryID = data.AuditLogEntry_AuditLogEntryID;
				this.objectID = data.AuditLogEntry_ObjectID;
				this.logFile = data.AuditLogEntry_LogFile;
				this.logSequence = data.AuditLogEntry_LogSequence;
				this.loggedByUserID = data.AuditLogEntry_LoggedByUserID;
				this.loggedByUserName = data.User_UserName;
				this.loggedByName = data.User_Name;
				this.loggedWhen = data.AuditLogEntry_LoggedWhen;
				this.message = data.AuditLogEntry_Message;
				this.objectType = data.AuditLogEntry_ObjectType;
				this.operation = data.AuditLogEntry_Operation;
				break;
			case AuditLogEntryPart.General:
				this.valueTable = new Array<AuditLogEntryValue>();
				if (data.AuditLogEntry_ValueTable) {
					for (let e of data.AuditLogEntry_ValueTable) {
						if (e.change)
							this.valueTable.push(new AuditLogEntryValue().parseData(e));
					}
				}
				break;
		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case AuditLogEntryPart.Label:
				return {
					AuditLogEntryPart_ObjectDelete: this.objectDelete,
					AuditLogEntryPart_AuditLogEntryID: this.auditLogEntryID,
					AuditLogEntryPart_LogFile: this.logFile,
					AuditLogEntryPart_LogSequence: this.logSequence,
					AuditLogEntryPart_LoggedByUserID: this.loggedByUserID,
					AuditLogEntryPart_LoggedWhen: this.loggedWhen,
					AuditLogEntryPart_Message: this.message,
					AuditLogEntryPart_ObjectID: this.objectID,
					AuditLogEntryPart_ObjectType: this.objectType,
					AuditLogEntryPart_Operation: this.operation,
				}
			case AuditLogEntryPart.General:
				return {
					AuditLogEntryPart_ValueTable: this.valueTable,
				}
		}
	}
}

// Fn7klSmXDHk+HL8B5+SAAyjbn6k=
