import {Component, Input, ViewChild, ElementRef} from '@angular/core';

import {DoiFocusFirst} from '../core/DoiFocusFirst';
import {DoiIconMapper} from '../core/DoiIconMapper';

/**
 * Information about a single tab.
 */
export class DoiViewTab
{
	name: string;
	title: string;
}

/**
 * A view tab entry component for activating a subview. Used on li elements.
 */
@Component({
	selector: '[doi-view-tab]',
	host: { 'class' : 'nav-item' },
	template: `
		<a #viewTabAnchor class="nav-link" [routerLink]="routerLink()" routerLinkActive="active">
			<i *ngIf="iconName" [ngClass]="iconClass()"></i>
			<i *ngIf="iconNameOverlay" class="doi-icon-overlay" [ngClass]="iconClassOverlay()"></i>
			<ng-content></ng-content>
		</a>
	`,
	styles: [
		'i { min-width: 1em; margin-right: 0.5em; }',
		'i.doi-icon-overlay { width: 0; min-width: 0; margin-right: 0; transform: translateX(-1.5em); }',
		'i.doi-icon-overlay::before { display: block; min-height: 1em; min-width: 1em; background-color: rgb(255, 255, 255, 0.5); }'
	]
})
export class DoiViewTabComponent implements DoiFocusFirst
{
	/**
	 * The symbolic name, e g 'general'.
	 */
	@Input() name: string;

	/**
	 * The tab title name, e g 'General'.
	 */
	@Input() title: string;

	/**
	 * The tab icon name.
	 */
	@Input() iconName: string;

	/**
	 * The tab overlay icon name.
	 */
	@Input() iconNameOverlay: string;

	/**
	 * The outlet name, e g 'tab'.
	 */
	@Input() outlet: string;

	/**
	 * The view tab anchor element.
	 */
	@ViewChild('viewTabAnchor')
	anchorRef: ElementRef<HTMLAnchorElement>;

	/**
	 * The tab container.
	 */
	tabs: DoiViewTabsComponent;

	/**
	 * Construct a new tab component inside the specified tab container.
	 */
	constructor(tabs: DoiViewTabsComponent)
	{
		this.tabs = tabs;
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.anchorRef) {
			this.anchorRef.nativeElement.focus();
			return true;
		}

		return false;
	}

	/**
	 * Construct an icon class for the icon.
	 */
	iconClass(): string
	{
		if (this.iconName && this.tabs)
			return this.tabs.iconClass(this.iconName);
		else
			return null;
	}

	/**
	 * Construct an icon class for the overlay icon.
	 */
	iconClassOverlay(): string
	{
		if (this.iconNameOverlay && this.tabs)
			return this.tabs.iconClass(this.iconNameOverlay);
		else
			return null;
	}

	/**
	 * Build the router link array.
	 */
	routerLink(): any[]
	{
		if (this.outlet) {
			let outlets: any = {};
			outlets[this.outlet] = [this.name];
			return [{outlets: outlets}];
		} else {
			return [this.name];
		}
	}
}

/**
 * A view tabs component containing tab entries for activating subviews. Used on ul elements.
 */
@Component({
	selector: '[doi-view-tabs]',
	host: { 'class' : 'doi-view-tabs nav nav-tabs d-print-none', 'role': 'navigation' },
	template: `
		<ng-content></ng-content>
	`
})
export class DoiViewTabsComponent
{
	/**
	 * The icon mapper, usually the containing view.
	 */
	@Input()
	iconMapper: DoiIconMapper;

	/**
	 * Construct an icon class for the specified icon name.
	 */
	iconClass(iconName: string): string
	{
		if (iconName && this.iconMapper)
			return this.iconMapper.iconClass(iconName);
		else
			return null;
	}

}
