import {DoiObject} from '../../doi/DoiModule';

/**
 * A search result entry, with object type, ID, weight, categories, etc.
 */
export class DoiSearchResultEntry
{
	/**
	 * The object type.
	 */
	objectType: string;

	/**
	 * The object ID.
	 */
	objectID: number;

	/**
	 * The weight value.
	 */
	weight: number;

	/**
	 * The category IDs.
	 */
	catIDs: number[];

	/**
	 * Application defined properties.
	 */
	props: any;

	/**
	 * Indicates if the entry is included in the current category selection.
	 */
	included: boolean = true;

	/**
	 * The model object that the entity refers to. Set when result entries are fetched.
	 */
	object: DoiObject;

	/**
	 * The object type text. Set when the entry text are built by the search page.
	 */
	typeText: string = '';

	/**
	 * The header or title text. Set when the entry text are built by the search page.
	 */
	headerText: string = '';

	/**
	 * The body text. Set when the entry text are built by the search page.
	 */
	bodyText: string = '';

	/**
	 * The icon name. Set when the entry text are built by the search page.
	 */
	iconName: string = null;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): DoiSearchResultEntry
	{
		let cats = data.cats || data.Cats;

		if (!cats)
			throw new Error("No Cats array in search result entry.");

		this.objectType = data.objectType || data.Type;
		this.objectID = data.objectID || data.ID || 0;
		this.weight = data.weight || data.Weight || 0;

		this.catIDs = new Array<number>();
		for (let catID of cats) {
			this.catIDs.push(catID);
		}

		this.props = data.Props || {};

		return this;
	}
}
