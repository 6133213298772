import {Component} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';

import {ActivatedRoute} from '@angular/router';
import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ProcessPart, ProcessObject, ProcessUnit, ProcessSeriesObject} from '../model/ProcessObject';

@Component({
	templateUrl: 'ProcessObjectUnitsPanel.html'
})
export class ProcessObjectUnitsPanel extends DoiObjectPanel<ProcessObject>
{
	name = 'units';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);

	}

	routerLinkArchiveOrig(unit : ProcessUnit)
	{
		return unit.archiveOrigID ? [ '/archiveorig', unit.archiveOrigID ] : null;
	}

	routerLinkUnit(unit : ProcessUnit)
	{
		return [ '/unit', unit.unitID ];
	}

	openUnit(unit : ProcessUnit)
	{
		this.doi.router.navigate(this.routerLinkUnit(unit));
	}

	routerLinkSeries(series: ProcessSeriesObject)
	{
		return ['/series', series.seriesID];
	}

	openSeries(series : ProcessSeriesObject)
	{
		this.doi.router.navigate(this.routerLinkSeries(series));
	}

	routerLinkArchive(series : ProcessSeriesObject)
	{
		return series.archiveID ? [ '/archive', series.archiveID ] : null;
	}


	/**
	 * Return the icon class for the specified object.
	 */
	processUnitIconClass()
	{
		return this.iconClass(ProcessUnit.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.Units),
			this.refreshObjectPart(ProcessPart.ProcessSeries));
	}
}
