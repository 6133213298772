import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {DoiAuthService} from './DoiAuthService';

/**
 * A navigation guard that disallows activation if the user isn't authenticated, and navigates to the login page instead.
 */
@Injectable()
export class DoiAuthGuard 
{
	constructor(private auth: DoiAuthService, private router: Router)
	{
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
	{
		if (this.auth.isAuthenticated() || this.auth.isAuthenticating())
			return true;
			
		if (this.auth.loginPath() && this.auth.loginPath().length > 0)
			this.router.navigate(this.auth.loginPath(), {queryParams: {returnUrl: state.url}});

		return false;
	}
}


