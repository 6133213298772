import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

import {DoiNavNode} from '../model/DoiNavNode';

/**
 * A navigator node component.
 */
@Component({
	selector: '[doi-nav-node]',
	host: { 'class' : 'doi-nav-node' },
	template: `
		<span class="doi-nav-node-row" doi-drop-target [dragOver]="node?.dragOver" [drop]="node?.drop">
			<a *ngIf="!isLeaf()" class="doi-nav-node-link doi-nav-node-link-icon" href="" [title]="node.getTooltip()" (click)="onClickExpand()">
				<i class="doi-nav-node-exp" [ngClass]="expClass()"></i>
			</a>
			<span *ngIf="isLeaf()">
				<i class="doi-nav-node-exp fas"></i>
			</span>
			<a doi-drag-source [dragStart]="node?.dragStart"
				class="doi-nav-node-link doi-nav-node-link-label" href="" [routerLink]="routerLink()" [title]="node.getTooltip()" (click)="onClickSelect()">
				<i class="doi-nav-node-icon" [ngClass]="iconClass()"></i>
				<span class="doi-nav-node-label">{{node?.label}}</span>
			</a>
		</span>
		<ul *ngIf="node && node.expanded && node.children">
			<li doi-nav-node *ngFor="let child of node.children" [node]="child" (select)="onSelect($event)"></li>
		</ul>
	`,
	styles: [ `
		.doi-nav-node-row { display: flex; flex-wrap: nowrap; align-items: center; }
		.doi-nav-node { white-space: nowrap; }
	` ]
})
export class DoiNavNodeComponent
{
//		<span>
//			<i class="doi-nav-node-icon"[ngClass]="iconClass()"></i><i class="doi-nav-node-icon"[ngClass]="iconClass()" style="transform: translate(-1em,-0); width:0"></i>
//		</span>
	@Output() select = new EventEmitter<DoiNavNode>();

	@HostBinding('attr.id')
	id: string;

	@HostBinding('class.doi-nav-expanded')
	get expanded()
	{
		return this.node && this.node.expanded;
	}

	@HostBinding('class.doi-nav-expand-pending')
	get expandPending()
	{
		return this.node && this.node.expandPending;
	}

	@HostBinding('class.doi-nav-expanded-always')
	get expandedAlways()
	{
		return this.node && this.node.expandedAlways;
	}

	private _node: DoiNavNode;
	private static _nextid = 0;

	/**
	 * Construct a new navigator node component.
	 */
	constructor()
	{
		this.id = 'doinnc'+DoiNavNodeComponent._nextid++;
	}

	/**
	 * The node model.
	 */
	@Input()
	get node(): DoiNavNode
	{
		return this._node;
	}

	set node(node: DoiNavNode)
	{
		this._node = node;
		node.id = this.id;
	}

	/**
	 * Return the class or classes for the expansion icon.
	 */
	expClass(): string
	{
		if (!this.node || this.node.expandedAlways)
			return null;
		if (this.node.expanded)
			return 'fas fa-caret-down';
		else
			return 'fas fa-caret-right';
	}

	/**
	 * Return the class or classes for the node icon.
	 */
	iconClass(): string
	{
		if (!this.node)
			return null;
		return this.node.getIconClass();
	}

	/**
	 * Test if leaf.
	 */
	isLeaf(): boolean
	{
		return this.node ? this.node.isLeaf() : true;
	}

	/**
	 * Return the router link to use.
	 */
	routerLink()
	{
		if (this.node && this.node.url)
			return this.node.url;
		else
			return null;
	}

	/**
	 * The node class.
	 */
	@HostBinding('class')
	get nodeClass(): string
	{
		if (!this.node)
			return '';

		let nc = this.node.nodeClass();
		return (nc ? nc : '')
			+(this.node.isLeaf() ? ' doi-nav-node-leaf' : '')
			+(this.node.expanded ? ' doi-nav-node-expanded' : '')
			+(this.node.selected ? ' doi-nav-node-selected' : '');
	}

	onClickExpand()
	{
		if (this.node.expanded)
			this.node.collapse(this.node.selected);
		else
			this.node.expand(this.node.selected);
	}

	onClickSelect()
	{
		this.node.expand(true);
		this.select.emit(this.node);
	}

	onSelect(node: DoiNavNode)
	{
		this.select.emit(node);
	}
}
