import { HostBinding, HostListener, Directive, EventEmitter, Input, Output } from '@angular/core';
import { DoiDragSource } from './DoiDragSource';

/**
 * Applied to components that can be the target of drag and drop.
 */
@Directive({
    selector: '[doi-drop-target]',
	host: { 'class' : 'doi-drop-target' },
})
export class DoiDropTarget
{
	/**
	 * Invoked on drag over. Checks if droppable and may adiust drop order.
	 */
	@Input()
	dragOver: (event: DragEvent, value: string, dropOrder: number) => number;

	/**
	 * Invoked on drag leave.
	 */
	@Input()
	dragLeave: (event: DragEvent) => void;

	/**
	 * Invoked on drop.
	 */
	@Input()
	drop: (event: DragEvent, value: string, dropOrder: number) => void;

	/**
	 * Indicates if something is currently being dragged over this component.
	 * Bound to the class 'doi-drop-target-active'.
	 * -1 is bound to class 'doi-drop-target-before' and 1 is bound to 'doi-drop-target-after'
	 */
	dropOrder: number = null;

	@HostBinding('class.doi-drop-target-active')
	private get dropTargetClass(): boolean
	{
		return this.dropOrder != null;
	}

	@HostBinding('class.doi-drop-target-after')
	private get dropTargetAfterClass(): boolean
	{
		return this.dropOrder > 0;
	}

	@HostBinding('class.doi-drop-target-into')
	private get dropTargetIntoClass(): boolean
	{
		return this.dropOrder > 1;
	}

	@HostBinding('class.doi-drop-target-before')
	private get dropTargetBeforeClass(): boolean
	{
		return this.dropOrder < 0;
	}

	@HostBinding('class.doi-drop-target-disallowed')
	private get dropTargetDisallowedClass(): boolean
	{
		return this.dropOrder == 0;
	}

	/**
	 * Invoked on drag leave.
	 */
    @HostListener('dragleave', ['$event'])
	onDragLeave(event: DragEvent)
	{
		this.dropOrder = null;

		if (this.dragLeave)
			this.dragLeave(event);
	}

	/**
	 * Invoked on drag over.
	 */
    @HostListener('dragover', ['$event'])
	onDragOver(event: DragEvent)
	{
		event.preventDefault();

		let value = event.dataTransfer.getData('text/plain');
		if (value == null)
			return false;

		let offsetX = event.offsetX;
		let offsetY = event.offsetY;

		let target = event.target as Element;
		while (!target.classList.contains('doi-drop-target')) {
			target = target.parentElement;
			if (!target)
				return false;
		}

		let width = target.clientWidth;
		let height = target.clientHeight;

		this.dropOrder = null;

		if (this.dragOver) {
			let dropOrder = 0;
			if (offsetY < height/4)
				dropOrder = -1;
			else if (offsetY > height - height/4)
				dropOrder = 1;
			else
				dropOrder = 2;
			this.dropOrder = this.dragOver(event, value, dropOrder);
		}

		return this.dropOrder ? true : false;
	}

	/**
	 * Invoked on drop.
	 */
    @HostListener('drop', ['$event'])
	onDrop(event: DragEvent)
	{
		event.preventDefault();

		if (!this.dropOrder) {
			this.dropOrder = null;
			return;
		}

		let value = event.dataTransfer.getData('text/plain');
		if (value == null)
			return;

		if (this.drop)
			this.drop(event, value, this.dropOrder);

		this.dropOrder = null;
	}
}
