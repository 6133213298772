import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, EMPTY} from 'rxjs';
import {switchMap} from "rxjs/operators";

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';

import {KlaraBrokerService} from '../../klara/KlaraModule';

import {ProcessActTypePart, ProcessActTypeObject} from './ProcessActTypeObject';
import {ProcessPart, ProcessService, ProcessObject} from '../../process/ProcessModule';

/**
 * Manages ProcessActType objects.
 */
@Injectable()
export class ProcessActTypeService extends KlaraBrokerService<ProcessActTypeObject>
{
	/**
	 * Construct a new ProcessActType service.
	 */
	constructor(doi: DoiService, private processService: ProcessService)
	{
		super(doi, 'ProcessActType')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessActTypeObject.iconName;
	}

	/**
	 * Create a new ProcessActType object.
	 */
	newObject(objectID: number): ProcessActTypeObject
	{
		return new ProcessActTypeObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns an observable that fetches the Process, connects it to the ProcessActType
	 * and then switches to a ProcessService.parentObjectObservable.
	 */
	parentObjectObservable(processActType: ProcessActTypeObject): Observable<any>
	{
		if (!processActType.processID)
			return EMPTY;

		return this.processService.readObjectPart(processActType.processID, ProcessPart.Label, processActType.process).pipe(
			switchMap((parent: ProcessObject) => {
				processActType.process = parent;
				return this.processService.parentObjectObservable(parent);
			})
		);
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: ProcessActTypeObject): Observable<ProcessActTypeObject>
	{
		let options = undefined;
		switch (partName) {
			case ProcessActTypePart.Label:
			case ProcessActTypePart.General:
				options = {
					params: new HttpParams().append('related', 'Process')
				};
				break;
			case ProcessActTypePart.ProcActTypeSeries:
				options = {
					params: new HttpParams().append('related', 'Archive')
				};
				break;
			case ProcessActTypePart.Units:
				options = {
					params: new HttpParams().append('related', 'PropertyValues')
				};
				break;
			case ProcessActTypePart.UnitsNav:
				options = {
					params: new HttpParams().append('related', 'PropertyValues')
				};
				break;
		}

		return super.readObjectPart(id, partName, object, options);
	}

	/**
	 * Read property values for edit.
	 */
	readPropertyValuesForEdit(id: number, object?: ProcessActTypeObject): Observable<ProcessActTypeObject>
	{
		let options = {
			params: new HttpParams().append('related', 'PropertyType')
		};

		return super.readObjectPart(id, ProcessActTypePart.PropertyValues, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<ProcessActTypeObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'ProcessActType_ProcessActTypeID')
			.append('col', 'ProcessActType_NameList')
			.append('col', 'ProcessActType_DescriptionShort')
			.append('col', 'Process_Code')
			.append('col', 'Process_Name')
			.append('col', 'Process_NameShort');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// gUQM0/qSJ4chtS++GkYfp/iDTAw=
