import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import { ArchiveObject } from '../../archive/ArchiveModule';
import { DocStoreLink, ObjectFile, PublishLevel} from '../../klara/KlaraModule';
import { SeriesGroupObject } from '../../seriesgroup/SeriesGroupModule';

/**
 *	Object part names for the Series entity object.
 */
export const SeriesPart =
{
	Label : 'Label',
	General : 'General',
	Culling : 'Culling',
	Notes : 'Notes',
	ObjectFiles : 'ObjectFiles',
	Volumes : 'Volumes',
	ProcessActTypes: 'ProcessActTypes',
	Processes: 'Processes',
	ObjectIntegrations: "ObjectIntegrations",
}

/**
 *	The Series entity object.
 */
export class SeriesObject extends DoiObject
{
	static readonly iconName = 'shelves';

	/**
	 *	Attribute values.
	 */
	archiveID: number;
	seriesGroupID: number;
	cullable: number;
	cullPeriod: number;
	cullPeriodOther: string;
	grouping: boolean;
	internalNotes: string;
	labelText: string;
	location: string;
	notes: string;
	publish: PublishLevel;
	signum: string;
	signumPrint: number;
	title: string;
	signumFmt: string;

	objectFiles: Array<ObjectFile>;
	volumes : Array<SeriesVolume>;
	processActTypes: Array<ProcessActTypeObject>;
	processes: Array<ProcessObject>;
	docStoreLinks: Array<DocStoreLink>;

	/**
	 *	Parent.
	 */
	archive: ArchiveObject;
	seriesGroup: SeriesGroupObject;

	/**
	 * Construct a new Series object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return SeriesObject.iconName;
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return notes.
	 */
	objectText(): string
	{
		return this.notes;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		if (this.signum) {
			if (this.title)
				return this.signum+' - '+this.title;
			else
				return this.signum;
		} else {
			return this.title;
		}
	}

	/**
	 * The symbolic object type name, i e 'Series'.
	 */
	get objectType(): string
	{
		return 'Series';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		if (this.seriesGroup) {
			let path = this.seriesGroup.objectRefPath();
			if (path)
				path.push(this.objectRef());
			return path;
		}

		if (this.archive) {
			let path = this.archive.objectRefPath();
			if (path)
				path.push(this.objectRef());
			return path;
		}

		return null;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): SeriesObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, SeriesPart.Label);
				//	Additional search result entry information.
				if (data.Series_Notes)
					this.notes = data.Series_Notes;
				break;
			case SeriesPart.Label:
				this.objectID = data.Series_SeriesID;
				this.archiveID = data.Series_ArchiveID;
				this.seriesGroupID = data.Series_SeriesGroupID;
				this.signum = data.Series_Signum;
				this.title = data.Series_Title;
				break;
			case SeriesPart.General:
				this.parseData(data, SeriesPart.Label);
				this.grouping = data.Series_Grouping;
				this.internalNotes = data.Series_InternalNotes;
				this.labelText = data.Series_LabelText;
				this.location = data.Series_Location;
				this.publish = data.Series_Publish;
				this.signumPrint = data.Series_SignumPrint;
				this.signumFmt = data.Series_SignumFmt;
				break;
			case SeriesPart.Culling:
				this.cullable = data.Series_Cullable;
				this.cullPeriod = data.Series_CullPeriod;
				this.cullPeriodOther = data.Series_CullPeriodOther;
				break;
			case SeriesPart.Notes:
				this.notes = data.Series_Notes;
				break;
			case SeriesPart.Volumes:
				this.volumes = new Array<SeriesVolume>();
				if (data.Series_Volumes) {
					for (let e of data.Series_Volumes) {
						this.volumes.push(new SeriesVolume().parseData(e));
					}
				}
				break;
			case SeriesPart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.Series_ObjectFiles) {
					for (let e of data.Series_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;
			case SeriesPart.ProcessActTypes:
				this.processActTypes = new Array<ProcessActTypeObject>();
				for (let e of data.Series_ProcActTypeSeries) {
					let object = new ProcessActTypeObject();
					object.parseData(e);
					this.processActTypes.push(object);
				}
				break;
			case SeriesPart.Processes:
				this.processes = new Array<ProcessObject>();
				for (let e of data.Series_ProcessSeries) {
					let object = new ProcessObject();
					object.parseData(e);
					this.processes.push(object);
				}
				break;

		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case SeriesPart.General:
				return {
					Series_ObjectDelete: this.objectDelete,
					Series_Grouping: this.grouping,
					Series_InternalNotes: this.internalNotes,
					Series_LabelText: this.labelText,
					Series_Location: this.location,
					Series_Publish: this.publish,
					Series_Signum: this.signum,
					Series_SignumPrint: this.signumPrint,
					Series_Title: this.title,
					Series_SignumFmt: this.signumFmt,
				}
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.archive && this.archive.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}
}
/**
 * A Child, coresponding to a detail object from volume.
 */
export class SeriesVolume
{
	// TODO: Ikon??
	static readonly iconName = 'circle';

	volumeID: number;
	volumeCode: string;
	title: string;
	sequence: number;
	location: string;
	date: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): SeriesVolume
	{
		this.volumeID = data.Volume_VolumeID;
		this.volumeCode = data.Volume_VolumeCode;
		this.title = data.Volume_Title;
		this.sequence = data.Volume_Sequence;
		this.location = data.Volume_Location;
		this.date = data.Volume_Date;
		return this;
	}
}

export class ProcessActTypeObject
{
	static readonly iconName = 'file-alt';

	processID: number;
	processActTypeID: number;
	processCode: string;
	nameListActKind: string;
	processNameList: string;
	nameList: string;
	sequence: number;
	processTypeName: string;
	processStructureName: string;
	procStructID: number;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcessActTypeObject
	{
		this.processID = data.ProcessActType_ProcessID;
		this.processActTypeID = data.ProcessActType_ProcessActTypeID;
		this.processCode = data.Process_Code;
		this.nameListActKind = data.Process_NameListActKind;
		this.nameList = data.ProcessActType_NameList;
		this.sequence = data.ProcessActType_Sequence;
		this.processTypeName = data.ProcessType_Name;
		this.processNameList = data.Process_NameList;
		this.processStructureName = data.ProcessStructure_Name;
		this.procStructID = data.Process_ProcStructID;

		return this;
	}
}

export class ProcessObject
{
	static readonly iconName = 'rectangle-landscape';

	processID: number;
	code: string;
	nameListActKind: string;
	sequence: number;
	processTypeName: string;
	processStructureName: string;
	procStructID: number;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcessObject
	{
		this.processID = data.Process_ProcessID;
		this.code = data.Process_Code;
		this.nameListActKind = data.Process_NameListActKind;
		this.processTypeName = data.ProcessType_Name;
		this.processStructureName = data.ProcessStructure_Name;
		this.procStructID = data.Process_ProcStructID;

		return this;
	}
}

// +fuBHUSr2UJ5mhdgr1tF2dXR8Cc=
