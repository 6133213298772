import { Component, HostBinding, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { DoiIconMapper } from '../../doi/DoiModule';

import { PropertyValue } from '../model/PropertyValue';
import { Unit } from '../model/Unit';

/*
 * A description component that can be expanded or collapsed.
 */
@Component({
	selector: 'description',
	template: `
		<div class="description-header" (click)="expandCollapse()">
			<h3>{{header}}</h3>
			<button class="btn btn-light expand-button" ><i [ngClass]="expandIconClass()"></i></button>
		</div>
		<div *ngIf="expanded">
			<div *ngIf="description" class="description-body">{{description}}</div>
		</div>
	`,
	host: { 'class': 'description group-pane' }
})
export class DescriptionComponent {
	/**
	 * The header.
	 */
	@Input()
	header: string = 'Beskrivning';

	/**
	 * The description.
	 */
	@Input()
	description: string;

	/**
	 * Optional icon mapper.
	 */
	@Input()
	iconMapper: DoiIconMapper;

	@HostBinding('class.expanded')
	expanded: boolean;

	@HostListener('window:beforeprint', ['$event'])
	onBeforePrint(event: Event)
	{
		this.expandCollapse(true);
	}

	@HostListener('window:afterprint', ['$event'])
	onAfterPrint(event: Event)
	{
		if (this.shouldStayExpanded === false)
			this.expandCollapse();
	}


	expandIconClass()
	{
		if (this.expanded)
			return this.iconMapper.iconClass("angle-up");
		else
			return this.iconMapper.iconClass("angle-down");
	}

	shouldStayExpanded: boolean = false;

	expandCollapse(isModePrint?: boolean, isExpanded?: boolean): void
	{
		if (isModePrint === true) {
			if (this.expanded === true)
				this.shouldStayExpanded = true;
			else
				this.shouldStayExpanded = false;

			this.expanded = true;
		} else
			this.expanded = !this.expanded;
	}
}