import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';

import {DoiModule} from '../doi/DoiModule';
import {KlaraModule} from '../klara/KlaraModule';
import {AuditLogEntryService} from './model/AuditLogEntryService';

export * from './model/AuditLogEntryObject';
export * from './model/AuditLogEntryService';

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule,
		DoiModule,KlaraModule,
	],
	declarations: [
	],
	exports: [
	],
	providers: [
		AuditLogEntryService,
	],

})
export class AuditLogEntryModule
{
}

// 6+lXOEaz0vjnxZ0JgmGEQAZ4xt0=
