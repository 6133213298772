import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiService} from '../../doi/DoiModule';

import {ArchiveOrigService} from '../model/ArchiveOrigService';
import {ArchiveOrigPart, ArchiveOrigObject} from '../model/ArchiveOrigObject';
import {KlaraObjectView} from '../../klara/KlaraModule';

/**
 *
 */
@Component({
	templateUrl: 'ArchiveOrigObjectView.html'
})
export class ArchiveOrigObjectView extends KlaraObjectView<ArchiveOrigObject>
{
	name = 'ArchiveOrigObjectView';

	/**
	 * Template for a new object.
	 */
	objectTemplate: ArchiveOrigObject;

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, private archiveOrigService: ArchiveOrigService, route: ActivatedRoute)
	{
		super(doi, archiveOrigService, route);
	}

	routerLinkParentArchiveOrig()
	{
		return this.object.parentArchiveOrigID ? ['/archiveorig', this.object.parentArchiveOrigID] : null;
	}

	routerLinkArchiveOrigs()
	{
		return ['/archiveorigs'];
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.doi.auth.isAuthenticating())
			return EMPTY;

		return forkJoin(
			this.refreshObjectPart(ArchiveOrigPart.Label).pipe(
				switchMap((archiveOrig: ArchiveOrigObject) => {
					return this.archiveOrigService.parentObjectObservable(archiveOrig);
				})
			),
			this.probeObjectPart(ArchiveOrigPart.General),
			this.probeObjectPart(ArchiveOrigPart.BusinessPlaces),
			this.probeObjectPart(ArchiveOrigPart.BusinessTypes),
			this.probeObjectPart(ArchiveOrigPart.AltNames),
			this.probeObjectPart(ArchiveOrigPart.Archives),
			this.probeObjectPart(ArchiveOrigPart.Children),
			super.refreshView()
		);
	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Overriddene to invoke objectPartHasValues with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		switch (panelName) {
			case 'general': return this.probedPart('General');
			case 'details': return (this.probedPart('BusinessPlaces') || this.probedPart('BusinessTypes') || this.probedPart('AltNames'));
			case 'archives': return this.probedPart('Archives');
			case 'children': return this.probedPart('Children');
			case 'auditlogentry': return true;
		}
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Arkivbildare'; // I18N
	}
}

// +SXdeGr35b4yIS9NjGVfSQole7M=
