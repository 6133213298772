import {Component, Input, Output, EventEmitter} from '@angular/core';

import {DoiObjectView} from '../view/DoiObjectView'
import { DoiTableEditor } from '../core/DoiTableEditor';
import { DoiView } from '../view/DoiView';

/**
 * Toolbar for editing a table. Delagates to a DoiTableEditor controller.
 */
@Component({
	selector: 'doi-table-edit-tools',
	host: { 'class' : 'doi-table-edit-tools' },
	template: `
		<li doi-tool [target]="controller" action="RowsMoveUp" [compact]=true></li>
		<li doi-tool [target]="controller" action="RowsMoveDown" [compact]=true></li>
		<li doi-tool [target]="controller" action="RowsMarkDelete" [compact]=true></li>
		<li doi-tool [target]="controller" action="RowAdd" [compact]=true></li>
		<ng-content select="[doi-tool]"></ng-content>
  `, // I18N
})
export class DoiTableEditToolsComponent
{
	/**
	 * The table editor controller.
	 */
	@Input()
	controller: DoiTableEditor<DoiView, any>;

	/**
	 * Construct a new component.
	 */
	constructor()
	{
	}
}
