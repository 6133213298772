<div class="doi-view doi-object-view processstructure-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start">
			<li doi-breadcrumb link="/archiveorigs" text="Struktur"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchiveOrig()" [text]="object?.archiveOrig?.objectTitle()" [link]="routerLinkArchiveOrig()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title>Klassificeringsstruktur {{object.isNew() && !object.objectID ? '(ny)' : object.objectTitle()}}</doi-title> <!-- I18N -->
	</doi-view-title>

	<ul *ngIf="!environment.dialog" doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="processes" [iconName]="probedIconName('processes')">Strukturenheter</li>
		<li *ngIf="hasRoleAuditObject()" doi-view-tab name="auditlogentry" [iconName]="probedIconName('auditlogentry')">Logg</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- fdwsnp5rYO8dT2PQ/z1u4wwLhfw= -->
