import {Component, Input, Output, ElementRef, EventEmitter, HostBinding, QueryList, ViewChild, ViewChildren} from '@angular/core';

import {DoiFocusFirst} from '../core/DoiFocusFirst';
import {DoiIconMapper} from '../core/DoiIconMapper';
import {DoiObject} from '../service/DoiObject';
import { DoiObjectGridItemComponent } from './DoiObjectGridItemComponent';

/**
 * An object grid component, for containing object components.
 */
@Component({
	selector: '[doi-object-grid]',
	host: { 'class': 'doi-object-grid row', 'role': 'navigation' },
	template: `
		<div doi-object-grid-item [ngClass]="'col-lg-'+cellsLG+' col-xl-'+cellsXL" *ngFor="let object of itemObjects()"
			[link]="link(object)" [iconClass]="iconClass(object)" [title]="title(object)"
			[classSupplier]="itemClassSupplier(object)"
			[contentSupplier]="itemContentSupplier(object)"
			[textSupplier]="itemTextSupplier(object)"
			[cellsLG]="cellsLG"
			[cellsXL]="cellsXL"
			(open)="onClick($event, object)">
		</div>
		<ng-content></ng-content>
  `,
})
export class DoiObjectGridComponent implements DoiFocusFirst
{
	/**
	 * The objects to show. The last entry may be null, indicating a truncated result.
	 */
	@Input()
	objects: DoiObject[];

	/**
	 * The icon mapper, usually the containing view.
	 */
	@Input()
	iconMapper: DoiIconMapper;

	/**
	 * The maximum # of items to show before a "More" item is created. Only used if a more link or more view is available.
	 */
	@Input()
	maxItems: number;

	/**
	 * The router link for the "more" entry.
	 */
	@Input()
	moreLink: any[];

	/**
	 * The subview name for the "more" entry. Used when the more entry should link to a sibling subview.
	 */
	@Input()
	moreView: string;

	/**
	 * The icon name for the "more" entry.
	 */
	@Input()
	moreIcon: string = 'angle-double-right';

	/**
	 * The text for the "more" entry.
	 */
	@Input()
	moreText: string = 'Fler';

	/**
	 * The object item class supplier.
	 */
	@Input()
	classSupplier: (object: DoiObject) => string;

	/**
	 * The object HTML content supplier.
	 */
	@Input()
	contentSupplier: (object: DoiObject) => string;

	/**
	 * The object text supplier.
	 */
	@Input()
	textSupplier: (object: DoiObject) => string;

	/**
	 * Cells in LG mode, propagated to items if objects array is used.
	 */
	@Input() cellsLG: string = '6';

	/**
	 * Cells in XL mode, propagated to items if objects array is used.
	 */
	@Input() cellsXL: string = '4';

	/**
	 * The event emitter for the "more" entry.
	 */
	@Output()
	more: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * The inner breadcrumbs.
	 */
	@ViewChildren(DoiObjectGridItemComponent)
	items: QueryList<DoiObjectGridItemComponent>;

	/**
	 * The truncated item objects and the object array and max it is based on.
	 */
	private _itemObjects: DoiObject[];
	private _objects: DoiObject[];
	private _maxItems: number;

	/**
	 * Construct a new object grid component.
	 */
	constructor()
	{
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.items && this.items.first) {
			this.items.first.focusFirst()
				return true;
		}

		return false;
	}

	/**
	 * Return the supplier of body HTML content for the specified object.
	 */
	itemClassSupplier(object: DoiObject): () => string
	{
		if (this.classSupplier && object)
			return () => this.classSupplier(object);
		else
			return () => '';
	}

	/**
	 * Return the supplier of body HTML content for the specified object.
	 */
	itemContentSupplier(object: DoiObject): () => string
	{
		if (this.contentSupplier && object)
			return () => this.contentSupplier(object);
	}

	/**
	 * Return the item objects.
	 */
	itemObjects(): DoiObject[]
	{
		if (!this.maxItems)
			return this.objects;

		if (this.objects === this._objects)
			return this._itemObjects;

		if (!this.objects)
			return null;

		if (this.objects.length > this.maxItems) {
			this._itemObjects = this.objects.slice(0, this.maxItems-1);
			this._itemObjects.push(null);
		} else {
			this._itemObjects = this.objects;
		}

		this._objects = this.objects;
		this._maxItems = this.maxItems;

		return this._itemObjects;
	}

	/**
	 * Return the supplier of body text for the specified object.
	 */
	itemTextSupplier(object: DoiObject): () => string
	{
		if (this.textSupplier && object)
			return () => this.textSupplier(object);
	}

	/**
	 * Construct an icon class for the specified object.
	 */
	iconClass(object : DoiObject)
	{
		let objectTypeClass = object ? 'icon-'+object.objectType : null;

		if (!this.iconMapper)
			return objectTypeClass;

		if (object) {
			return this.iconMapper.iconClass(object.iconName)+' '+objectTypeClass;
		} else if (this.moreIcon) {
			return this.iconMapper.iconClass(this.moreIcon);
		}
	}

	/**
	 * Construct a router link for the specified object or for the more-link.
	 */
	link(object : DoiObject)
	{
		if (object) {
			return object.routerLink();
		} else {
			if (this.moreLink)
				return this.moreLink;
			else if (this.moreView)
				return [ '..', this.moreView ];
			else
				return null;
		}
	}

	/**
	 * Construct a title for the specified object.
	 */
	title(object : DoiObject)
	{
		if (object) {
			return object.objectTitle();
		} else {
			return this.moreText;
		}
	}

	/**
	 * Invoked when an automatically created entry is clicked. Emits an event to "more".
	 */
	onClick(event: Event, object: DoiObject)
	{
		event.stopPropagation();

		if (!object)
			this.more.emit(event);
	}
}
