
/**
 *	A condition in a selection criteria.
 */
export class DoiCondition
{
	variable: string;
	expr: string;

	constructor(variable: string, expr: string)
	{
		this.variable = variable;
		this.expr = expr;
	}
}

/**
 *	A selection criteria.
 */
export class DoiSelectionCriteria
{
	columns: string[];
	sort: string[];
	condition: DoiCondition[];
	countLimit: number;

	/**
	 * Populate this criteria object from a data object received from the server.
	 * @param data The data object received from the server.
	 */
	parse(data: any): DoiSelectionCriteria
	{
		if (!data)
			return this;

		if (data.columns)
			this.select(...data.columns);

		if (data.condition) {
			for (let cond of data.condition) {
				this.and(cond.variable, cond.expr);
			}
		}

		// TODO: Sort
		return this;
	}

	/**
	 * Add one or more selected columns.
	 */
	select(...column: string[]): DoiSelectionCriteria
	{
		if (this.columns == null)
			this.columns = new Array<string>();
		this.columns.push(...column);
		return this;
	}

	/**
	 * Add one or more sort columns. Prefix names with '-' for descending.
	 */
	orderBy(...column: string[]): DoiSelectionCriteria
	{
		if (this.sort == null)
			this.sort = new Array<string>();
		this.sort.push(...column);
		return this;
	}

	/**
	 * Add a condition. Delegates to the "and" method. Exists only for readability in invocation chains
	 */
	where(variable: string, expr: string): DoiSelectionCriteria
	{
		return this.and(variable, expr);
	}

	/**
	 * Add a condition.
	 */
	and(variable: string, expr: string): DoiSelectionCriteria
	{
		if (this.condition == null)
			this.condition = new Array<DoiCondition>();
		this.condition.push(new DoiCondition(variable, expr));
		return this;
	}

	/**
	 * Add a count limit.
	 */
	limit(limit: number): DoiSelectionCriteria
	{
		this.countLimit = limit;
		return this;
	}
}
