import {Component} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {NgbModal, NgbModalRef, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ResizeEvent} from 'angular-resizable-element';
import {from, of, Observable} from "rxjs";
import {catchError, delay, map, startWith, switchMap, tap} from "rxjs/operators";
import {HttpParams} from '@angular/common/http';

import {environment} from '../environments/environment';

import {DoiAction, DoiAppView, DoiAppSettings, DoiModalResult, DoiLabeledValue, DoiLogLevel, DoiObjectView, DoiService, DoiObject, DoiObjectRef} from './doi/DoiModule';
import {DoiSearchService} from './doi-search/DoiSearchModule';
import {DoiThemeService, DoiTheme} from './doi-theme/DoiThemeModule';
import {DoiNavigatorAppView, DoiNavNode} from './doi-navigator/DoiNavigatorModule';

import {AppInfo} from './klara/KlaraModule';

import {ArchiveService} from './archive/ArchiveModule';
import {ArchiveOrigService} from './archiveorig/ArchiveOrigModule';
import {AuditLogEntryService} from './auditlogentry/AuditLogEntryModule';
import {KlaraDialogTopView, KlaraTopView} from './klara/KlaraModule';
import {ProcessActTypeService} from './processacttype/ProcessActTypeModule';
import {ProcessService} from './process/ProcessModule';
import {ProcessStructureService, ProcessStructureSelectable} from './processstructure/ProcessStructureModule';
import {SeriesService} from './series/SeriesModule';
import {SeriesGroupService} from './seriesgroup/SeriesGroupModule';
import {SystemInfoObject, SystemInfoService, SystemInfoPart} from './systeminfo/SystemInfoModule';
import {UnitService} from './unit/UnitModule';
import {VolumeService} from './volume/VolumeModule';

import {ArchiveNodeChildSupplier} from './navigator/ArchiveNodeChildSupplier';
import {ArchiveOrigNodeChildSupplier} from './navigator/ArchiveOrigNodeChildSupplier';
import {SeriesGroupNodeChildSupplier} from './navigator/SeriesGroupNodeChildSupplier';
import {StructureFolderNodeChildSupplier} from './navigator/StructureFolderNodeChildSupplier';
import {StructureFolderDialogNodeChildSupplier} from './navigator/StructureFolderDialogNodeChildSupplier';
import {ProcessNodeChildSupplier} from './navigator/ProcessNodeChildSupplier';
import {ProcessStructureNodeChildSupplier} from './navigator/ProcessStructureNodeChildSupplier';
import {UnitFolderNodeChildSupplier} from './navigator/UnitFolderNodeChildSupplier';
import {UnitNodeChildSupplier} from './navigator/UnitNodeChildSupplier';

import {AppBaseView} from './AppBaseView';

declare let navigationEnd: Function;

@Component({
	selector: 'app',
	templateUrl: 'AppView.html'
})
export class AppView extends AppBaseView
{
	name = 'AppView';

	constructor(
		router: Router,
		doi: DoiService,
		themes: DoiThemeService,
		titleService: Title,
		searchService: DoiSearchService,
		systemInfoService: SystemInfoService,
		processService: ProcessService,
		auditLogEntryService: AuditLogEntryService,
		private archiveOrigService: ArchiveOrigService,
		private archiveService: ArchiveService,
		private processActTypeService: ProcessActTypeService,
		private processStructureService: ProcessStructureService,
		private seriesService: SeriesService,
		private seriesGroupService: SeriesGroupService,
		private unitService: UnitService,
		private volumeService: VolumeService,
	)
	{
		super(router, doi, themes, titleService, searchService, systemInfoService, processService,
			archiveService, archiveOrigService, processActTypeService, processStructureService,
			seriesService, seriesGroupService,
			unitService, volumeService, auditLogEntryService);

		this.actions.add(
			new DoiAction(this, 'Klara', 'fas-desktop', 'Desktop', 'Klara Desktop-applikationen')
				.linkHandler(() => this.actionKlaraAdmin())
				.mobileAvailable(false),
		);
		this.actions.add(
			new DoiAction(this, 'Dialog', 'fas-comments', 'Dialog', 'Klara Dialog')
				.executeHandler(() => this.actionKlaraDialog())
				.enabledHandler(() => this.actionKlaraDialogAvailable()),
		);

	}

	/**
	 * Invoked when both appInfo and systemInfo has been received.
	 * Builds the navigator.
	 */
	infoReceived()
	{
		this.buildNavigator();
	}

	/**
	 * Switch to Klara Dialog.
	 */
	actionKlaraDialog()
	{
		let url = this.actionKlaraDialogLink();
		if (url) {
			let w = window.open(url, 'klaradialog');
		}
	}

	/**
	 * Test if switching to Klara Dialog should be available
	 */
	actionKlaraDialogAvailable(): boolean
	{
		if (!this.appInfo || !this.appInfo.hasFeatureDialog())
			return false;

		var dialogTopView = this.activeView as KlaraDialogTopView;
		let dialogPathAvailable = dialogTopView && dialogTopView.dialogPath;

		return dialogPathAvailable != undefined && dialogTopView .dialogPath() != null;
	}

	/**
	 * Return a link to the corresponding page in KlaraDialog.
	 */
	actionKlaraDialogLink(): string
	{
		if (!this.appInfo || !this.appInfo.hasFeatureDialog())
			return;

		var dialogTopView = this.activeView as KlaraDialogTopView;
		let dialogPathAvailable = dialogTopView && dialogTopView.dialogPath;
		let dialogPath = dialogPathAvailable != undefined ? dialogTopView.dialogPath() : null;

		if (dialogPath != null && this.lastUrl) {
			if (dialogPath == '')
				return this.urlContext()+'/dialog/index.html#'+ this.lastUrl + '?refresh=true';
			else
				return this.urlContext()+'/dialog/index.html#'+ dialogPath + '?refresh=true';
		} else {
			return this.urlContext()+'/dialog/index.html';
		}
	}

	/**
	 * Return the app container classes.
	 */
	appContainerClasses(): string
	{
		return [ super.appContainerClasses(), 'webui' ].join(' ');
	}

	/**
	 * Return the application title suffix.
	 */
	appTitleSuffix(): string
	{
		return 'Arkivsök';
	}

	/**
	 * Invoked when a user login or logout is completed.
	 * Overridden to add or remove the account node.
	 */
	authenticatedStateChanged(loggedIn: boolean)
	{
		super.authenticatedStateChanged(loggedIn);

		if (this.navRoot) {
			let accountNode = this.navRoot.findChildByUrl('/account');
			if (loggedIn) {
				if (!accountNode) {
					this.navRoot.addChild(
						new DoiNavNode('Mitt konto', '/account')
							.setIconName('user')
							.setNodeClass('nav-node-account')
					);
				}
			} else {
				if (accountNode)
					this.navRoot.removeChild(accountNode);
			}
		}
	}

	/**
	 * Build the navigator.
	 */
	buildNavigator()
	{
		this.navRoot.addChild(
			new DoiNavNode('Struktur', '/archiveorigs')
				.setNodeClass('node-structure')
				.setChildNodeSupplier(
					new StructureFolderNodeChildSupplier(this.archiveOrigService,
						new ArchiveOrigNodeChildSupplier(this.archiveOrigService, this.processStructureService, this.archiveService,
							new ProcessStructureNodeChildSupplier(this.processService,
								new ProcessNodeChildSupplier(this.processService, this.processActTypeService)),
							new ArchiveNodeChildSupplier(this.seriesGroupService, this.seriesService,
								new SeriesGroupNodeChildSupplier(this.seriesGroupService, this.seriesService))
						)
					)
				)
				.setSticky(true)
		);

		if (this.hasFeatureProcess()) {
			this.navRoot.addChild(
				new DoiNavNode('Förvaring', '/units')
					.setNodeClass('node-storage')
					.setChildNodeSupplier(
						new UnitFolderNodeChildSupplier(this.unitService,
							new UnitNodeChildSupplier(this.unitService)
						)
					)
				.setSticky(true)
			);
		}

		this.navRoot.addChild(
			new DoiNavNode('Inställningar', '/settings')
				.setIconName('cog')
				.setNodeClass('nav-node-settings')
		);
	}

	/**
	 * Invoked by an object view to indicate that an object is visible.
	 * Overridden to expand the object path.
	 */
	objectVisible(object: DoiObject, subviewName?: string): void
	{
		if (this.navigatorVisible() && this.navRoot) {
			let path = object.objectRefPath();
			let node = null;
			if (object.objectType == 'Unit')
				node = this.navRoot.findChildByUrl('/units');
			else
				node = this.navRoot.findChildByUrl('/archiveorigs');
			if (node) {
				node.expandPath(path);
			}
		}
	}

	/**
	 * Find the navigator node corresponding to the specified URL. Invoked by urlNavigated when a URL has been navigated to.
	 * Overridden to return the navigator root for url '/start'.
	 */
	urlNavNode(url: string): DoiNavNode
	{
		if (url == '/start')
			return this.navRoot;
		else
			return super.urlNavNode(url);
	}
}
